<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px;" class="modal-title mb-0">Sale Invoice</h1>
  <div class="addProductButtons">
    <button style="margin-right: 10px;" type="button" class="btn btn-cancel" (click)="onCancelModal()">Cancel</button>
    <button style="margin-right: 10px;" type="button" class="btn btn-save" (click)="setSaveAsDraft()" *appHasPermission="['create']; name 'sales'" [hidden]="draftId">
      Save as Draft
    </button>
    <button type="button" class="btn btn-save" (click)="postSaleInvoice()"
      *appHasPermission="['create']; name 'sales'">Save</button>
  </div>
</div>
<div class="modal-body">

  <!-- UAE -->

  <form [formGroup]="SaleInvoiceForm">
    <div class="row d-flex justify-content-start mb-2">
      <div class="col-sm-12 d-flex" *ngIf="!showInvoiceGenerateData">
        <div class="d-flex" style="font-size: 14px;">
          <div class="form-check padding-left">
            <input class="" style="margin-left: 0px" id="inlineCheckbox1" type="checkbox" formControlName="cashSale"
              (change)="isCashSaleToggle($event.target.checked)" [disabled]="showInvoiceGenerateData" />
          </div>
          <label class="text-nowrap saleText form-label" style="margin-left:4px">Cash </label>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-between">
      <!-- Transaction Type -->
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="!showInvoiceGenerateData && !isCashSale">
        <label class="form-label">Transaction Type</label>
        <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">

          <mat-select disableOptionCentering aria-label="Default select example" formControlName="credit"
            (selectionChange)="getCredit($event);calculationTotal();" placeholder="Transaction Type" [ngClass]="{
            'is-invalid': (f.credit.invalid && f.credit.touched) && submitted }">
            <mat-option [value]="false">Cash</mat-option>
            <mat-option [value]="true">Credit</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.credit.touched) && f.credit.errors?.required">
          Transaction Type Is Required.
        </mat-error>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="!showInvoiceGenerateData && !isCashSale && !f.credit.value">
        <label class="form-label">Payment Mode</label>
          <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
            <mat-select disableOptionCentering aria-label="Default select example" formControlName="paymentMode"
              placeholder="Select Payment Mode" (selectionChange)="calculationTotal()">
              <mat-option value="CASH">CASH</mat-option>
              <mat-option value="CREDITCARD">CREDIT CARD</mat-option>
              <mat-option value="GIFTCARD">GIFT CARD</mat-option>
            </mat-select>
          </mat-form-field>
      </div>
    </div>

    <div class="row d-flex justify-content-start">
      <!-- Party Name -->
      <div class="col-md-4 " *ngIf="!isCashSale">
        <label class="form-label">Customer Name<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 11px" class=" example-full-width" appearance="outline">
          <input type="text" placeholder="Customer Name" ngxOnlyString aria-label="Select Vendor Name" matInput
            [formControl]="myControl" (keyup)="searchPartyByName($event.target.value)"
            [matAutocomplete]="auto" [ngClass]="{
                    'is-invalid':
                      myControl.invalid && myControl.touched && submitted
                  }">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngIf="this.currentUser.userType !== 'SHOP' && this.currentUser.userType !== 'WAREHOUSE'">
              <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true"
                title="Add new Customer" (click)="openAddPartyModal(addParty)">
                Add new Customer
              </a>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails(option)">
              {{ option.partyName | titlecase }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || myControl.touched) && myControl.errors?.required">
          Customer Is Required.
        </mat-error>
      </div>

      <!-- Gst number -->
      <div class="col-md-4 justify-content-end" *ngIf="!isCashSale">
        <div><mat-label>{{ lang.GSTIN }}</mat-label></div>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" matInput formControlName="gstNo" placeholder="{{lang.GSTIN }}" #input1
            (keypress)="numberOnly($event)" maxlength="15" (keyup)="validateTRN($event.target.value)"
            [ngClass]="{'is-invalid': (f.gstNo.invalid && f.gstNo.touched) && submitted }">
        </mat-form-field>

        <!-- <mat-error class="text-danger" *ngIf="(submitted || f.gstNo.touched) && f.gstNo.errors?.required">
          {{ lang.GSTIN }} is Required.
        </mat-error> -->
        <!-- <mat-error class="text-danger" *ngIf="f.gstNo.invalid && (f.gstNo.dirty && f.gstNo.touched)">
          Please enter the valid {{ lang.GSTIN }} number.
        </mat-error> -->
        <mat-error class="text-danger" *ngIf="f.gstNo.errors?.maxlength">
          {{ lang.GSTIN }} must be of 15-digits.
        </mat-error>
        <mat-error class="text-danger" *ngIf="f.gstNo.errors?.minlength">
          {{ lang.GSTIN }} must be of 15-digits.
        </mat-error>
        <mat-error class="text-danger" *ngIf="((f.gstNo.errors?.pattern) && (f.gstNo.dirty && f.gstNo.touched))">
          {{ lang.GSTIN }} must be valid number.
        </mat-error>
      </div>

      <!-- Tax Invoice No -->
      <div class="col-md-4 justify-content-end">
        <label class="form-label">Tax Invoice #</label>
        <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
          <input type="text" placeholder="Enter Tax Invoice #" matInput formControlName="invoiceNo" maxlength="20"
            [disabled]="showInvoiceGenerateData" [ngClass]="{
            'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched) && f.invoiceNo.errors?.required">
          Tax Invoice Is Required.
        </mat-error>
      </div>
    </div>

    <div class="row d-flex justify-content-start">
      <!-- Invoice date -->
      <div class="col-md-4">
        <label class="form-label">Invoice Date<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
          <input matInput ngModel bsDatepicker placeholder="Invoice Date." [maxDate]="today" [(ngModel)]="todaysDate"
            id="datePicker" bsDatepicker formControlName="invoiceDate" [bsConfig]="{
        containerClass: 'theme-dark-blue',
        dateInputFormat: 'DD MMM YYYY',
        showWeekNumbers:false }" [ngClass]="{
        'is-invalid': (f.invoiceDate.invalid && f.invoiceDate.touched) && submitted }" (click)="openDatepicker()">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>

        <mat-error class="text-danger" *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
          Invoice Date Is Required.
        </mat-error>
      </div>

      <div [ngSwitch]="currentUser.userType" class=" col-xs-12 col-sm-12 col-md-6 col-lg-4"
        *appHasPermission="['delete', 'modify','create', 'read']; name 'warehouse'">
        <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
          <label class="form-label">Warehouse</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse"
              (selectionChange)="onWarehouseSelectionChange($event)">
              <mat-option value="clear">Clear Selection</mat-option>
              <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{warehouse.firstName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
          <label class="form-label">Warehouse</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse"
              (selectionChange)="onWarehouseSelectionChange($event)">
              <mat-option value="clear">Clear Selection</mat-option>
              <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{warehouse.firstName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4" style="display: flex;align-items: center;padding-left: 16px;"
        *ngIf="!isCashSale">
        <mat-checkbox formControlName="sendWhatsappMsg" *ngIf="!isEstimateDraft">
          <span style="font-size: 14px;font-weight: 500;">
            Send WhatsApp Message
          </span>
        </mat-checkbox>
      </div>
    </div>

    <div class="row d-flex justify-content-start">
      <!-- Billing Address -->
      <div class="col-sm-12 col-md-4 col-lg-4">
        <label class="form-label">Billing Address<span class="error-asterisk" *ngIf="!isCashSale">*</span></label>
        <mat-form-field class="example-full-width " style=" font-size: 12px" appearance="outline">
          <textarea matInput rows="5" placeholder="Enter Billing Address" maxlength="150"
            formControlName="billingAddress" disabled [ngClass]="{
            'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
        </mat-form-field>
        <mat-error class="text-danger"
          *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
          Billing Address Is Required.
        </mat-error>
      </div>
      <!-- Shipping Address -->
      <div *ngIf="isShippingAddress" class="col-sm-12 col-md-4 col-lg-4">
        <label class="form-label">Shipping Address</label>
        <mat-form-field class="example-full-width" style=" font-size: 12px" appearance="outline">
          <textarea matInput rows="5" placeholder="Enter Shipping Address" formControlName="shippingAddress"
            [disabled]="showInvoiceGenerateData"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row container d-flex justify-content-start">
      <div class="col-md-4 d-flex" style="font-size: 12px;">
        <div class="form-check padding-left">
          <input class="form-check-input" id="inlineCheckbox1" type="checkbox" [disabled]="showInvoiceGenerateData"
            (change)="showShippingAddress($event.target.checked)" [checked]="isShippingAddress" />
        </div>
        <label style="margin-left:1px!important" class=" form-label">Add Shipping Address</label>

      </div>
    </div>

    <!-- Product Table for Sale Invoice -->
    <div class="table-responsive my-custom-scrollbar mb-3" *ngIf="!showInvoiceGenerateData">
      <table class="table bg-white table-bordered table-stripped">
        <thead class="table inner-model-table-heading">
          <tr class="font_size font-weight-bold">
            <th scope="col" style="width: 7%;">
              #
            </th>
            <th scope="col" style="width: 10%;">
              SKU<span class="text-danger"> *</span>
            </th>
            <th class="text-nowrap" scope="col">
              SKU Description
            </th>
            <th>Unit</th>

            <th>Quantity</th>
            <th scope="col" class="text-nowrap" colspan="2">
              {{ lang.Base_Price_In_Rs }}<span class="text-danger"> *</span>
            </th>

            <th class="text-center" style="width: 14%;" scope="col" colspan="2">
              {{lang.GST}}<span class="text-danger"> *</span>
            </th>

            <th scope="col" class="text-nowrap">
              {{lang.Total_In_Rs}}<span class="text-danger"> *</span>
            </th>
            <th scope="col" class="text-nowrap" style="width:8%;">
              Action<span class="text-danger"> *</span>
            </th>

          </tr>
        </thead>
        <tbody class="mod">
          <!-- The first row for adding new items -->
          <tr>
            <td style="vertical-align: middle;"></td>
            <td class="s23323">
              <mat-form-field class="example-full-width" appearance="outline">
                <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                  aria-label="Select Vendor Name" matInput [formControl]="myControl1" [matAutocomplete]="auto">
                <mat-icon class="m-2" matSuffix>search</mat-icon>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions2 | async" [value]="option.itemName"
                    (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                    <span style="font-size: 12px;"> {{ option.itemName | titlecase }}/<b>{{
                        option.barcode}}</b></span>

                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </td>

            <td>
              <input type="text" matInput placeholder="SKU Description" required />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Unit" required />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Price/Unit" />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Price" />
            </td>
            <td colspan="1">
              <input type="text" matInput required placeholder="%" />
            </td>
            <td colspan="1">
              <input type="text" matInput required placeholder="In AED" style="width: 40px;" />
            </td>
            <td>
              <input type="text" matInput placeholder="Amount" style="max-width: 150px;" required />
            </td>
            <td></td>
          </tr>
          <ng-container formArrayName="subcheckbox"
            *ngFor="let items of formArr.controls;let item of productData;let i=index">
            <tr [formGroupName]="i">
              <td style="vertical-align: middle;">{{ i + 1 }}</td>
              <td>
                {{ item.barcode }}
              </td>
              <td>{{ item.itemName | titlecase}}</td>
              <td class="text-center">
                <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
              </td>
              <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                  (click)="changeQuantity('-',i)">-</a>
                <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                  (change)="changeQuantity('qty', i, $event.target.value)" style="width: 5rem;text-align: center;" />
                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                  (click)="changeQuantity('+',i)">+</a>
              </td>
              <td colspan="1">
                <p class="">
                  AED&nbsp;{{item.basePrice | number:'1.2-2'}}
                </p>
              </td>
              <td colspan="1">
                <p class="">
                  AED&nbsp;{{item.subTotal | number:'1.2-2'}}
                </p>
              </td>
              <td colspan="1">
                <p class="">
                  {{ item.gstRate }}%
                </p>
              </td>
              <td colspan="1" style="max-width: 150px;">
                <p class="">
                  AED&nbsp;{{item.gstAmount | number:'1.2-2'}}
                </p>
              </td>
              <td>
                <p class="" style="max-width: 150px;">
                  AED&nbsp;{{item.total | number:'1.2-2'}}
                </p>
              </td>
              <td>
                <button type="button" class="text-center btnb" style="width: 3rem;"
                  (click)="changeQuantity('rm', i)">
                  <span class="bi-trash icon-color fs-1" style="color:red;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Delete"></span>
                </button>
              </td>
            </tr>
            <tr *ngIf="item.isOffer">
              <td colspan="2">
              </td>
              <td colspan="3" class="text-success" style="text-align: right;">
                {{ item.offerData?.schemaName}}
              </td>
              <td colspan="4" class="text-success" style="text-align: right;">
                AED&nbsp; - {{ (item?.gstAmount - item.offerData?.originalGst) | number:'1.2-2' }}
              </td>
              <td colspan="1" class="text-success" style="text-align: right;">
                AED&nbsp; - {{ (item?.total - item.offerData?.totalDiscountedPrice) | number:'1.2-2' }}
              </td>
              <td></td>
            </tr>
            <tr *ngIf="item.isFreeProduct">
              <td colspan="2">
              </td>
              <td colspan="2" class="text-success" style="text-align: right;">
                {{ item?.schemaName | uppercase }}
              </td>
              <td colspan="1" class="text-success" style="text-align: center;">
                {{ item?.freeProductQuantity}}
              </td>
              <td colspan="4" class="text-success" style="text-align: right;">
                AED&nbsp; - {{ item?.freeProductTotalGst | number:'1.2-2' }}
              </td>
              <td colspan="1" class="text-success" style="text-align:left;">
                AED&nbsp; - {{ item?.freeProductTotalPrice | number:'1.2-2' }}
              </td>
              <td></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end" *ngIf="!showInvoiceGenerateData">
      <div class="col-sm-6"></div>

      <div class="col-sm-4 table-bordered my-custom-scrollbar">
        <table class="table table-bordered  my-custom-scrollbar">
          <tr>
            <td class="thWidth p-2" style="text-align: left;">Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align: right;">
              AED&nbsp;{{ subTotal | number:'1.2-2'}}
            </td>
          </tr>
          <tr *ngIf="amtToMinusFromTotal !== 0 || null || undefined">
            <td class="thWidth text-success p-2" style="text-align: left;">Discounted Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align: right;">
              <p style="text-align: right" class="text-success m-0 p-0">
                (AED&nbsp;{{ - (amtToMinusFromTotal - newGstRate) | number:'1.2-2'}})
              </p>
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align: left;">{{lang.CGST}}</td>
            <td class="p-2" style="width: 50px;" style="text-align: right;">
              AED&nbsp;{{ gstRate | number:'1.2-2'}}
            </td>
          </tr>
          <tr *ngIf="newGstRate !== 0 || null || undefined">
            <td class="thWidth text-success p-2" style="text-align: left;">Discounted {{lang.CGST}}</td>
            <td class="p-2" style="width: 50px;" style="text-align: right;">
              <p class="text-success m-0 p-0" style="text-align: right">
                (AED&nbsp;{{ - newGstRate | number:'1.2-2'}})
              </p>
            </td>
          </tr>
          <tr>
            <td class="thWidth  p-2" style="text-align: left;">Total</td>
            <td class="p-2" style="width: 50px;" style="text-align: right;">
              AED&nbsp;{{ total | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align: left;" *ngIf="amtToMinusFromTotal !== 0 || null || undefined">
              Discounted Total</td>
            <td class="p-2"
              *ngIf="(amtToMinusFromTotal !== 0 || null || undefined && f.counterDiscount.value === (0 || null || undefined || ''))"
              style="width: 50px;" style="text-align: right;">
              AED&nbsp;{{ (total - amtToMinusFromTotal) | number:'1.2-2'}}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end"
      *ngIf="!showInvoiceGenerateData && showCounterDiscountField">
      <div class="col-sm-6"></div>

      <div class="col-sm-4 table-bordered my-custom-scrollbar">
        <div class="d-flex justify-content-end text-success">
          <p class="counter-text p-2" (click)="getCounterDiscountAmount()" *ngIf="!counterOn">Apply Counter Discount</p>
        </div>
        <div class="d-flex justify-content-end text-success">
          <p class="counter-text p-2" (click)="openCouponModal()" *ngIf="!couponApplied">
            <i class="fas fa-tags"></i> Apply Coupon
          </p>
        </div>

        <table class="table table-bordered  my-custom-scrollbar">
          <tr *ngIf="counterOn">
            <td class="thWidth text-success p-2" style="text-align: left;">Counter Discount</td>
            <td class="p-0 text-success" style="text-align: right;">
              <span (click)="removeCounter()" class="text-danger" style="cursor: pointer;">Remove</span>
              <mat-form-field style="font-size: 12px; width: 50%;" class="p-0 m-0" appearance="outline">
                <span matPrefix>AED -</span>
                <input type="text" placeholder="Counter Discount" (change)="calculationTotal()" matInput
                  formControlName="counterDiscount" (keypress)="numberOnly($event)">
              </mat-form-field>

            </td>
          </tr>
          <tr *ngIf="couponApplied">
            <td class="thWidth text-success p-2" style="text-align: left;">Coupon Discount</td>
            <td class="p-0 text-success" style="text-align: right;">
              <ng-container *ngIf="!couponApplied; else appliedCouponBlock" (click)="openCouponModal()">
                Apply Coupon
              </ng-container>
              <ng-template #appliedCouponBlock>
                <span (click)="removeCoupon()" class="text-danger" style="cursor: pointer;">Remove</span>
                <!-- <span (click)="removeCoupon()">{{ appliedCoupon?.coupon_name }}</span> -->
                <span>AED&nbsp;- {{ appliedCoupon?.discount_amount | number:'1.2-2' }}</span>
              </ng-template>
            </td>
          </tr>
          <!-- <tr>
            <td class="thWidth p-2" style="text-align: left;">Final Total</td>
            <td class="p-2" *ngIf="(amtToMinusFromTotal !== 0 || null || undefined && f.counterDiscount.value !== (0 || null || undefined || ''))" style="width: 50px;" style="text-align: right;">
              AED&nbsp;{{ ((total - amtToMinusFromTotal) - f.counterDiscount.value) | number:'1.2-2'}}
            </td>
            <td class="p-2" *ngIf="(amtToMinusFromTotal === 0 || null || undefined && f.counterDiscount.value !== (0 || null || undefined || ''))" style="width: 50px;" style="text-align: right;">
              AED&nbsp;{{ (total - f.counterDiscount.value) | number:'1.2-2'}}
            </td>
          </tr> -->
          <!-- <tr>
            <td class="thWidth p-2" style="text-align: left;">Final Total</td>
            <td class="p-2" *ngIf="billFinalTotal !== null && billFinalTotal !== undefined"
              style="width: 50px; text-align: right;">
              AED&nbsp;{{ billFinalTotal | number:'1.2-2'}}
            </td>
          </tr> -->

          <tr *ngIf="billFlatOffer?.discountAmount !== null && billFlatOffer?.discountAmount !== undefined">
            <td class="thWidth p-2 text-success" style="text-align: left;">Flat Discount -{{billFlatOffer?.schemaName}}
            </td>
            <td class="p-2 text-success" style="width: 50px; text-align: right;">
              AED-&nbsp;{{ billFlatOffer?.discountAmount | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align: left;">Final Total</td>
            <td class="p-2" *ngIf="billFinalTotal !== null && billFinalTotal !== undefined"
              style="width: 50px; text-align: right;">
              AED&nbsp;{{ ((billFinalTotal || 0) - (billFlatOffer?.discountAmount || 0 )) | number:'1.2-2'}}
            </td>
          </tr>
          <tr *ngIf="isCashSale">
            <td class="thWidth text-success p-2" style="text-align: left;">Amount Paid</td>
            <td class="p-0 text-success" style="text-align: right;">
              <mat-form-field style="font-size: 12px; width: 50%;" class="p-0 m-0" appearance="outline">
                <span matPrefix>AED -</span>
                <input type="number" placeholder="Amount Paid by User" (input)="restrictAmountInput($event)" matInput
                  formControlName="amountByUser" maxlength="10">
              </mat-form-field>
              <mat-error *ngIf="SaleInvoiceForm.get('amountByUser').hasError('minimumAmount')">
                Amount must be at least {{ SaleInvoiceForm.get('amountByUser').errors?.minimumAmount.requiredAmount }} AED.
              </mat-error>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Invoice Generate Table for Remote Orders -->
    <div class="table-responsive my-custom-scrollbar d-flex justify-content-start row" style="margin-right: 0.1rem;"
      *ngIf="showInvoiceGenerateData">
      <table class="table bg-white table-bordered table-stripped">
        <thead class="table-secondary">
          <tr class="font_size font-weight-bold">
            <th scope="col" style="width: 7% !important;">
              #
            </th>
            <th scope="col" style="width: 10%">
              SKU<span class="text-danger"> *</span>
            </th>
            <th scope="col" class="text-nowrap">
              SKU Description
            </th>
            <th>Unit</th>
            <th>Quantity</th>
            <th scope="col" class="text-nowrap" colspan="1">
              {{lang.Base_Price_In_Rs}}<span class="text-danger"> *</span>
            </th>

            <th class="text-center" scope="col" colspan="2">
              {{lang.GST}}<span class="text-danger"> *</span>
            </th>

            <th scope="col" class="text-nowrap">
              {{lang.Total_In_Rs}}<span class="text-danger"> *</span>
            </th>
          </tr>
        </thead>
        <tbody class="mod" *ngIf="orderData.length > 0">
          <tr *ngFor="let item of orderData;let i=index">
            <td>{{ i + 1 }}</td>
            <td>{{ item.barcode }}</td>
            <td>
              {{ item.itemName }}
            </td>
            <td colspan="1">
              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
            </td>
            <td colspan="1" class="text-center m-auto">
              <p class="">{{ item.quantity }}</p>
            </td>
            <td colspan="1">
              <p class="">AED&nbsp;{{subTotal | number:'1.2-2'}}
              </p>
            </td>
            <td colspan="1">
              <p class="" style="width: 5rem;">{{ item.gst }}%</p>
            </td>
            <td colspan="1">
              <p class="" style="width: 5rem;">
                AED&nbsp;{{item.gstAmount | number:'1.2-2'}}
              </p>
            </td>

            <td>
              <p class="">
                AED&nbsp;{{item.finalAmount | number:'1.2-2'}}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="table-responsive my-custom-scrollbar d-flex justify-content-end" *ngIf="showInvoiceGenerateData">
      <div class="col-lg-8 col-sm-6"></div>
      <div class="col-lg-4 col-sm-4 table-bordered my-custom-scrollbar" style="width: 30%;">
        <table class="table table-bordered  my-custom-scrollbar">
          <tr>
            <td style="text-align:left!important;">Subtotal</td>
            <td style="text-align:right!important;">
              AED&nbsp;{{subTotal | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td style="text-align:left!important;">{{lang.CGST}}</td>
            <td style="text-align:right!important;">
              AED&nbsp;{{totalGST | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td style="text-align:left!important;">Total</td>
            <td style="text-align:right!important;">
              AED&nbsp;{{total | number:'1.2-2'}}
            </td>
          </tr>
        </table>
      </div>
    </div>


    <!-- as (Save/Print/Close) -->
    <div class="col-12 d-flex  justify-content-end">
      <div class="" *ngIf="!showInvoiceGenerateData">

      </div>

      <a type="button" class="  btn btn-outline-primary" style="margin-left: 10px;"
        *ngIf="showInvoiceGenerateData && !showPrintBtn" (click)="patchGenerateRemoteInovice()">Generate
        Invoice</a>

      <a type="button" class="btn btn-primary" style="margin-left: 10px;" data-bs-toggle="modal"
        data-bs-target="#invoice-modal" *ngIf="showPrintBtn" (click)="getInvoiceByOrderId()">Print</a>

    </div>

    <!-- Add New Party Modal -->
    <ng-template #addParty let-modal>
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 options">
          <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
            aria-label="Close"></a>
        </div>
        <div class="modal-body p-0">
          <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h4 class="mb-1" id="modalExampleDemoLabel" style="font-size: 16px;font-weight:500;font-family: 'Poppins';">
              Add New Customer
            </h4>
          </div>
          <form [formGroup]="PartyForm">
            <div class="p-4 pb-0">
              <div class="form-check form-switch mb-0 lh-1 mb-3">
                <input class="form-check-input" type="checkbox" [checked]="isShowGstFields" id="flexSwitchCheckDefault"
                  (click)="customertoggleShow($event.target.checked)" />
                <label class="lab text-nowrap form-label p-1" style="font-size: 14px;"> {{ isShowGstFields ? 'As
                  Customer' : 'As Vendor' }} </label>
              </div>

              <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <div><mat-label>{{ lang.GSTIN }}</mat-label></div>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matInput formControlName="gstIn" placeholder="{{lang.GSTIN }}" #input1
                      (keypress)="numberOnly($event)" maxlength="15" minlength="15"
                      (input)="validateTRN($event.target.value)"
                      [ngClass]="{'is-invalid': (pf.gstIn.invalid && pf.gstIn.touched) && submittedParty }">
                  </mat-form-field>

                  <!-- <mat-error class="text-danger"
                    *ngIf="((submittedParty || pf.gstIn.touched) && pf.gstIn.errors?.required)">
                    {{ lang.GSTIN }} is Required.
                  </mat-error> -->
                  <mat-error class="text-danger"
                    *ngIf="(pf.gstIn.invalid && pf.gstIn.dirty ) || pf.gstIn.errors?.maxlength || pf.gstIn.errors?.minlength">
                    Please enter {{ lang.GSTIN }} number with 15-digits.
                  </mat-error>
                </div>

              </div>

              <div class="row d-flex justify-content-start">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Customer Name<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Customer Name" formControlName="partyName"
                      ngxOnlyString [ngClass]="{
                      'is-invalid': (pf.partyName.invalid && pf.partyName.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || pf.partyName.touched)">
                    <mat-error *ngIf="(submittedParty || pf.partyName.touched) && pf.partyName.errors?.required">
                      Customer Name Is Required.
                    </mat-error>
                  </mat-error>

                </div>
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Mobile #<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Mobile #" matInput formControlName="mobile" minlength="10"
                      maxLength="10" ngxOnlyNumbers [ngClass]="{
                        'is-invalid': (pf.mobile.invalid && pf.mobile.touched) && submittedParty }">
                  </mat-form-field>

                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.pattern">
                    Enter a valid mobile #.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.required">
                    Mobile # is required.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.maxlength">
                    Mobile # must be of 10-digits.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.minlength">
                    Mobile # must be of 10-digits
                  </mat-error>
                </div>
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="email" placeholder="E-Mail" matInput formControlName="email" [ngClass]="{
                        'is-invalid': (pf.email.invalid && pf.email.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.pattern">
                    Enter a valid E-Mail.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.required">
                    E-Mail is required.
                  </mat-error>
                </div>
              </div>

              <div class="row d-flex justify-content-start">
                <div class="col-lg-4 col-sm-12 col-md-6">
                  <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="billingAddress" maxlength="150" placeholder="Enter your address"
                      [ngClass]="{
                        'is-invalid': (pf.billingAddress.invalid && pf.billingAddress.touched) && submittedParty }"></textarea>
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || pf.billingAddress.touched)">
                    <mat-error
                      *ngIf="(submittedParty || pf.billingAddress.touched) && pf.billingAddress.errors?.required">
                      Billing Address Is Required.
                    </mat-error>
                  </mat-error>
                </div>

                <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                  <label class="form-label">Shipping Address</label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-check form-check-inline mb-2">
                <label class="form-check-label form-label fs--1" for="inlineCheckbox1"><span
                    style="font-size: 14px;">Add Shipping Address</span></label>
                <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                  [checked]="isShippingAddressinModal" (change)="showShippingAddressinModal($event.target.checked)" />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
          <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add
          </button>
        </div>
      </div>
    </ng-template>

    <!-- Print Modal -->
    <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" #closea></a>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <div class="row justify-content-between align-items-center">
                <div class="col-md">
                  <h4 class="mb-2 mb-md-0">Order #{{ invoiceNo }}</h4>
                </div>
                <div class="col-auto">
                  <a class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" (click)="onPrint()"><span
                      class="fas fa-print me-1"> </span>Print</a>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="card mb-3" id="panel">
                <div class="card-body">
                  <h2 class="mb-3 text-center">Tax Invoice</h2>
                  <div class="row justify-content-betweenxt-center mb-3">
                    <div class="col-sm-6 text-sm-start">
                      <h3>To:</h3>
                      <h5>{{ customerName }}</h5>
                      <p class="fs--1 mb-0">{{ billingAddress }}<br /></p>
                    </div>
                    <div class="col text-sm-end mt-3 mt-sm-0">
                      <h3>From:</h3>
                      <h5>{{ legalName }}</h5>
                      <p class="fs--1 mb-0">{{ homeNo }} {{address }}, {{ district }}<br />{{ city }},{{ state |
                        titlecase}}, {{ country }}</p>
                    </div>
                    <div class="col-12">
                    </div>
                  </div>
                  <div class="table-responsive scrollbar mt-4 fs--1">
                    <table class="table table-striped table-bordered">
                      <thead class="light">
                        <tr class="bg-primary text-white dark__bg-1000">
                          <th class="border-0">Products</th>
                          <th class="border-0 text-center">Quantity</th>
                          <th class="border-0 text-end">Rate</th>
                          <th class="border-0 text-end">{{lang.GST_In_Rs}}</th>
                          <th class="border-0 text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let i of remoteProductData">
                          <td class="align-middle">
                            {{ i.itemName | titlecase }}
                          </td>
                          <td class="align-middle text-center">{{ i.quantity }}</td>
                          <td class="align-middle text-end">
                            AED&nbsp;{{i.totalPrice | number:'1.2-2'}}
                          </td>
                          <td class="align-middle text-end">
                            AED&nbsp;{{i.gstAmount | number:'1.2-2'}}
                          </td>
                          <td class="align-middle text-end">
                            AED&nbsp;{{i.finalAmount | number:'1.2-2'}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6"></div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                      <div class="table-responsive scrollbar fs--1">
                        <table class="table table-striped">
                          <thead class="light">
                            <tr class="bg-primary text-white dark__bg-1000">
                              <th class="border-0">Tax Type</th>
                              <th class="border-0 text-center"></th>
                              <th class="border-0 text-center">Tax Amount</th>
                            </tr>
                          </thead>
                          <tbody>

                            <tr>
                            </tr>
                            <tr>
                              <td class="align-middle">{{lang.CGST}}</td>
                              <td></td>
                              <td class="align-middle text-center">
                                AED&nbsp;{{remoteGSTAmount / 2 | number:'1.2-2'}}
                              </td>
                            </tr>
                            <tr>
                              <td class="align-middle">Total</td>
                              <td></td>
                              <td class="align-middle text-center">
                                AED&nbsp;{{remoteTotalBillAmount | number:'1.2-2'}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer bg-light">
                  <p class="fs--1 mb-0"><strong>Notes: </strong>We really appreciate your business and if there’s
                    anything else we can
                    do, please let us know!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
