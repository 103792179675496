import { E, I } from '@angular/cdk/keycodes';
import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PartyService } from 'src/app/core/services/party.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { HasPermissionDirective } from 'src/app/directive/has-permission.directive';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddPartyComponent } from '../Party/add-party/add-party.component';
import { PartyGroupAddComponent } from '../Party/party-group-add/party-group-add.component';
import { ProductAddComponent } from '../Product/product-add/product-add.component';
import { ServiceaddComponent } from '../Product/serviceadd/serviceadd.component';
import { AddOffersComponent } from '../Product/add-offers/add-offers.component';
import { SalesInvoiceComponent } from '../Invoice/sales-invoice/sales-invoice.component';
import { PaymentInComponent } from '../payment-in/payment-in.component';
import { EstimateComponent } from '../Invoice/estimate/estimate.component';
import { SaleOrderComponent } from '../sale-order/sale-order.component';
import { SalesReturnComponent } from '../Invoice/sales-return/sales-return.component';
import { PurchaseInvoiceComponent } from '../Invoice/purchase-invoice/purchase-invoice.component';
import { PaymentOutComponent } from '../payment-out/payment-out.component';
import { PurchaseOrderComponent } from '../purchase-order/purchase-order.component';
import { PuchaseReturnComponent } from '../Invoice/puchase-return/puchase-return.component';
import { AddAppointmentComponent } from '../new-shop-schedule/appointment-page/add-appointment/add-appointment.component';
import { AddTimeScheduleComponent } from '../new-shop-schedule/time-schedule-page/add-time-schedule/add-time-schedule.component';
import { ExpenseAddComponent } from '../expense/expense-add.component';
import { AddCampaignComponent } from '../campaign/add-campaign/add-campaign.component';
import { AdminAddRoleFormComponent } from '../admin-new-module/admin-add-role-form/admin-add-role-form.component';
import { AddRoleBasedUsersComponent } from '../add-role-based-users/add-role-based-users.component';
import { AddLeadComponent } from '../crmmodule/add-lead/add-lead.component';
import { NewStructureComponent } from '../payroll/new-structure/new-structure.component';
import { AddTimeComponent } from '../timesheet/add-time/add-time.component';
import { AddPromotionComponent } from '../promotions/components/add-promotion/add-promotion/add-promotion.component';
import { AssetFormcompoennetComponent } from '../assets-module/asset-formcompoennet/asset-formcompoennet.component';
import { AssetNonFormcompoennetComponent } from '../assets-module/asset-non-formcompoennet/asset-non-formcompoennet.component';
import { AddTemplateComponent } from '../campaign/draft-page/components/add-template/add-template.component';
import { AddWarehouseComponent } from '../warehouse/components/warehouse-components/add-warehouse/add-warehouse.component';
import { SwitchUserComponent } from '../switch-user/switch-user.component';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { CouponModalComponent } from '../promotions/components/coupon-modal/coupon-modal.component';
import { AddCashComponent } from '../bank-module/add-cash/add-cash.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {  

  isFluid = JSON.parse(localStorage.getItem('isFluid'));
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public permissionArray = JSON.parse(localStorage.getItem('Permission'));
  public showTimeInterval: any;
  public interval: any;
  public difference: any;
  public notificationReadArray: any = [];
  public notificationUnreadArray: any = [];
  public isData: boolean = false;
  public type = 'Bidding';
  public imageLoaded: boolean = false;
  public imageSrc: any = '';
  public permissionData: any;
  public isShow: boolean = false;
  public currentPageNo: number = 0;
  public pageSize: number = 100;
  @ViewChild(HasPermissionDirective) directive = null;
  searchResults: any[];
  searchTerm: string = '';
  categories: string[] = [
    'Dashboard',
    'Contacts',
    'Product',
    'Sale',
    'Purchase',
    'Offers',
    'Bank',
    'Bid',
    'Reports',
    'Shop Schedule',
    'Expense',
    'Campaign',
    'Admin',
    'Employee',
    'Payroll',
    'Time Sheet',
    'CRM',
    'Remote Shopping',
    'Warehouse',
    'Ledger',
    'Contact Us',
    'Tickets',
    'Settings',
    'Help and FAQs',
    'Assets',
    'Books of Account',
    'Organization Structure',
    'Sales by Customer',
    'Sale return history',
    'Sales by item',
    'Item report by Sale',
    'Item report by Purchase',
    'Inventory summary',
    'Inventory evaluation summary',
    'Stock summary',
    'Purchase by item',
    'Vendor balance',
    'Vendor credit details',
    'Payments made',
    'Purchase order details',
    'Payable details',
    'Customer balance',
    'Customer balance summary',
    'Receivable Details',
    'Payments Received',
    'Summary Sales By Time Reports',
    'Categories Summary Report',
    'Employee Summary Report',
  ];
  categorySubcategories: { [key: string]: string[] } = {
    HRMS: ['Admin', 'Employee', 'Payroll', 'Time Sheet'],
    Marketing: ['Campaign', 'CRM'],
    Accounting: ['Expense', 'Ledger', 'Books of Account', 'Assets'],
    Shopping: ['Shop Schedule', 'Remote Shopping']
  };
  // uaeCategories: string[] = [
  //   "Contacts", "Product", "Sale", "Purchase", "Contact Us","Help and FAQs" ];

  filteredCategories: string[] = [];
  categoryRouterLinks: { [key: string]: string } = {
    Dashboard: '/pages/dashboard',
    Contacts: '/pages/party-page',
    Product: '/pages/new-product',
    Sale: '/pages/sale_new',
    Purchase: '/pages/purchase-page',
    Offers: '/pages/marketing/prmotions/prmotion-list',
    Bank: '/pages/Bank-page',
    Bid: '/pages/Bid-page',
    'Shop Schedule': '/pages/shopping/remote-shop-page/remote-order',
    // 'GST Report': '/pages/reportgst',
    Expense: '/pages/accounting/expense_page/expense-list',
    Campaign: '/pages/marketing/campaign-page/active-page',
    Admin: '/pages/HRMS/Admin-page/role_product',
    Employee: '/pages/HRMS/employee-page',
    Payroll: '/pages/HRMS/payroll-page/payroll-list',
    'Time Sheet': '/pages/HRMS/time-sheet/daily',
    CRM: '/pages/marketing/crm/lead-list',
    'Remote Shopping': '/pages/shopping/remote-shop-page/remote-order',
    Ledger: 'pages/accounting/ledger-page/create-ledger',
    'Books of Account': 'pages/accounting/books-of-account/journal',
    Assets: 'pages/accounting/new-assets/aseets-list',
    'Organization Structure': 'pages/organization-structure',
    'Contact Us': '/pages/Contact-page-new',
    Tickets: '/pages/contactlist-page',
    Settings: '/pages/settings',
    Warehouse: '/pages/warehouse/warehouse-list',
    Reports: '/pages/reports/salereport',
    'Help and FAQs': '/pages/help-faq-page',
    'Sales by Customer': '/pages/reports/sale-report/Sales by customer',
    'Sale return history': '/pages/reports/sale-report/Sale return history',
    'Sales by item': '/pages/reports/sale-report/Sales by item',
    'Item report by Sale': '/pages/reports/inventory-report/Item report by Sale',
    'Item report by Purchase': '/pages/reports/inventory-report/Item report by Purchase',
    'Inventory summary': '/pages/reports/inventory-report/Inventory summary',
    'Inventory evaluation summary': '/pages/reports/inventory-report/Inventory evaluation summary',
    'Stock summary': '/pages/reports/inventory-report/Stock summary',
    'Purchase by item': '/pages/reports/purchase-report/Purchase by item',
    'Vendor balance': '/pages/reports/payable-report/Vendor balance',
    'Vendor credit details': '/pages/reports/payable-report/Vendor credit details',
    'Payments made': '/pages/reports/payable-report/Payments made',
    'Purchase order details': '/pages/reports/payable-report/Purchase order details',
    'Payable details': '/pages/reports/payable-report/Payable details',
    'Customer balance': '/pages/reports/receivable-report/Customer balance',
    'Customer balance summary': '/pages/reports/payable-report/Customer balance summary',
    'Receivable Details': '/pages/reports/payable-report/Receivable Details',
    'Payments Received': '/pages/reports/paymentreceived-report/Payments Received',
    'Employee Summary Report': '/pages/reports/extra-reports/Employee Summary Report',
    'Categories Summary Report': '/pages/reports/extra-reports/Categories Summary Report',
    'Summary Sales By Time Reports': '/pages/reports/extra-reports/Summary Sales By Time Reports',
  };

  createNewBtnCategories = {
    "Contacts": ["Contact", "Vendor_Group"],
    "Product": ["Product", "Service"],
    "Sale": ["sale-invoice", "payment-in", "Estimation-Invoice", "sale-order", "sale-return"],
    "Purchase": ["Purchase Invoice", "Payment Out", "Purchase Order", "Purchase Return"],
    "Bank": ['Bank'],
    "HRMS": ["Add Roles", "Add Employee"],
    "Marketing": ["Add Promotions", "Coupons", "Create Campaign", "Create Template", "Add Lead"],
    "Accounting": ["Add Expense", "Current Assets", "Non Current Assets"],
    "Shopping": ["Add Appointment", "Add Time Schedule"],
    "Warehouse": ["Add Warehouse"]
  };

  NavBarData: any;
  featurebefore: any = [
    {
      routerLink: "/pages/dashboard",
      name: "Dashboard",
      featureName: "dashboard",
      icon: "/assets/icons/dashboard-icons/Vector.png",
      iconClass: "bi-pie-chart",
    },
    {
      routerLink: "/pages/party-page",
      name: "Contacts",
      featureName: "contacts",
      icon: "/assets/icons/dashboard-icons/party.png",
      iconClass: "bi-pie-chart",
      permission: { action: 'read', name: 'parties' }  // Custom permission object
    },
    {
      routerLink: "/pages/new-product",
      name: "Product",
      featureName: "product",
      icon: "/assets/icons/dashboard-icons/product.png",
      iconClass: "bi-pie-chart"
    },
    {
      routerLink: "/pages/sale_new",
      name: "Sale",
      featureName: "sales",
      icon: "/assets/icons/dashboard-icons/sale.png",
      iconClass: "bi-pie-chart"
    },
    {
      routerLink: "/pages/purchase-page",
      name: "Purchase",
      featureName: "purchase",
      icon: "/assets/icons/dashboard-icons/purchase.png",
      iconClass: "bi-pie-chart"
    },
    {
      routerLink: "/pages/reportgst",
      name: "GST Report",
      featureName: "gstreport",
      icon: "/assets/icons/dashboard-icons/gst-report.png",
      iconClass: "bi-pie-chart",
    },
    {
      routerLink: "/pages/reports",
      name: "Report",
      featureName: "reports",
      icon: "/assets/icons/dashboard-icons/gst-report.png",
      iconClass: "bi-pie-chart",
    },
    {
      routerLink: "/pages/Bank-page",
      name: "Bank",
      featureName: "bank",
      icon: "/assets/icons/dashboard-icons/bank&cash.png",
      iconClass: "bi bi-wallet",
    },
    {
      routerLink: "/pages/HRMS",
      name: "HRMS",
      featureName: "hrms",
      icon: "/assets/icons/dashboard-icons/employee.png",
      iconClass: "bi-pie-chart",

    },
    {
      routerLink: "/pages/marketing",
      name: "Marketing",
      featureName: "marketing",
      icon: "/assets/icons/dashboard-icons/shop-schedule.png",
      iconClass: "bi-pie-chart",

    },
    {
      routerLink: "/pages/accounting",
      name: "Accounting",
      featureName: "accounting",
      icon: "/assets/icons/dashboard-icons/book_of_account.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    },
    {
      routerLink: "/pages/shopping",
      name: "Shopping",
      featureName: "shopping",
      icon: "/assets/icons/dashboard-icons/promotions.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    },
    {
      routerLink: "/pages/warehouse",
      name: "Warehouse",
      featureName: "warehouse",
      icon: "/assets/icons/dashboard-icons/warehouse.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    },
    {
      routerLink: "/pages/organization-structure",
      name: "Organization Structure",
      featureName: "organizationstructure",
      icon: "/assets/icons/dashboard-icons/organization.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    },
    {
      routerLink: "/pages/Contact-page-new",
      name: "Contact Us",
      featureName: "contactus",
      icon: "/assets/icons/dashboard-icons/contact_us.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    },
    {
      routerLink: "/pages/contactlist-page",
      name: "Tickets",
      featureName: "tickets",
      icon: "/assets/icons/dashboard-icons/tickets.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    },
    {
      routerLink: "/pages/settings",
      name: "Settings",
      featureName: "settings",
      icon: "/assets/icons/dashboard-icons/invoice-setting.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    },
    {
      routerLink: "/pages/auditing",
      name: "Audit",
      featureName: "auditing",
      icon: "/assets/icons/dashboard-icons/help_faq.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    },
    {
      routerLink: "/pages/help-faq-page",
      name: "Help and FAQs",
      featureName: "helpand",
      icon: "/assets/icons/dashboard-icons/help_faq.png",
      iconClass: "bi-pie-chart",
      width: 20,
      height: 20
    }
  ]
  navBarFeatures: any = []
  createNewBtn: { name: string; subcategories: any; }[];
  firstName: any;
  categoriesGetByBE: string[] = [];

  constructor(
    private authService: AuthService,
    public profileService: ProfileService,
    public dialog: MatDialog,
    public router: Router,
    public partyService: PartyService,
    public subscriptionService: SubscriptionService,
    public notificationService: NotificationService,
    private rolePermission: RolesPermissionsService,
    private toastService: ToastNotificationService,
    private saleInvoiceService: SaleInvoiceService,
    private commonService: CommonService
  ) {
    this.getPermissionById();
  }

  ngOnInit(): void {
    //call profile get api
    this.partyService.partyAdded$.subscribe(() => {
      // Fetch the updated list of users
      this.getProfileData();
    });

    this.getPermissionById();
    this.getProfileData();
    this.getNavBarData();
    this.getSubscribedUser();

    if (this.currentUser == null) {
      this.router.navigate(['/auth']);
    } else {
      this.interval = setInterval(() => {
        this.getExpirationTime();
      }, 1000);
    }
    this.getAllNotificationByUserId();
  }

  generateChatUrl(): string {
    // Construct the URL with the token and user ID as parameters
    // return `http://localhost:4200/?userId=${this.currentUser.id}&auth=${this.currentUser.accessToken}`;
    return `https://d22iituvt4fkp3.cloudfront.net?userId=${this.currentUser.id}&auth=${this.currentUser.accessToken}`;
  }

  @ViewChild('searchInput') searchInput!: ElementRef;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === '/') {
      event.preventDefault(); // Prevent the default browser behavior
      this.searchInput.nativeElement.focus();
    }
  }

  filterCategories() {
    const backendCategories = this.navBarFeatures.map(feature => feature.name);
    let finalCategories = this.categories.map(category => {
      const subcategories = this.categorySubcategories[category] || [];
      return category;
    });
    this.categories = finalCategories;
    // For create new btn option
    this.createNewBtn = Object.keys(this.createNewBtnCategories).filter(category => {
      return backendCategories.includes(category);
    }).map(category => {
      return {
        name: category,
        subcategories: this.createNewBtnCategories[category]
      };
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    if (!this.searchInput.nativeElement.contains(event.target)) {
      // If clicked outside then the dropdown is get closed
      this.closeDropdown();
    }
  }

  getNavBarData() {
    if (this.currentUser !== null) {
      this.rolePermission.getPermissions({}, this.currentUser.id, true).then((res) => {
        if(res.success){
          const apiFeatures = res.data.planFeatures.map((feature: any) => feature.featureName);
          this.navBarFeatures = this.featurebefore.filter(navItem => apiFeatures.includes(navItem.featureName));
          this.filterCategories();
          localStorage.setItem("NavBarData", JSON.stringify({
            data: this.navBarFeatures,
          }));
        }
      });
    }
  }

  getPermissionById() {
    // Mapping of backend names to UI category names
    const permissionToCategoryMap: { [key: string]: string } = {
      vendor: "Contacts",
      sales: "Sale",
      purchaseinvoicelist: "Purchase",
      bankdetails: "Bank",
      timesheet: "Time Sheet",
      expenses: "Expense",
      bookofaccounts: "Books of Account",
      remoteshopping: "Remote Shopping",
      shopschedule: "Shop Schedule",
      organizationstructure: "Organization Structure",
      contactUs: "Contact Us",
      helpandfaq: "Help and FAQs",
      product: "Product",
      offers: 'Offers',
      campaign: 'Campaign',
      admin: 'Admin',
      employee: 'Employee',
      payroll: 'Payroll',
      crm: 'CRM',
      warehouse: 'Warehouse',
      ledger: 'Ledger',
      tickets: 'Tickets',
      settings: 'Settings',
      assets: 'Assets',
      dashboard: "Dashboard",
      reports: "Reports",
      sales_by_customer: "Sales by Customer",
      sale_return_history: "Sale return history",
      sales_by_item: "Sales by item",
      stock_summary: "Stock summary",
      inventory_evaluation_summary: "Inventory evaluation summary",
      inventory_summary: "Inventory summary",
      item_report_by_purchase: "Item report by Purchase",
      item_report_by_sale: "Item report by Sale",
      purchase_by_item: "Purchase by item",
      payable_details: "Payable details",
      purchase_order_details: "Purchase order details",
      payments_made: "Payments made",
      Vendor_credit_details: "Vendor credit details",
      Vendor_balance: "Vendor balance",
      Customer_balance_summary: "Customer balance summary",
      Receivable_Details: "Receivable Details",
      Customer_balance: "Customer balance",
      payments_received: "Payments Received",
      Summary_Sales_By_Time_Reports: "Summary Sales By Time Reports",
      Categories_Summary_Report: "Categories Summary Report",
      Employee_Summary_Report: "Employee Summary Report",
    };

    if (this.currentUser !== null) {
      this.rolePermission.getPermissions({}, this.currentUser.id).then((res) => {
        if(res.success){
          this.permissionData = res.data;

          // Filter the categories based on the permissions
          this.categoriesGetByBE = this.categories.filter(category => {
            const backendName = Object.keys(permissionToCategoryMap).find(
              key => permissionToCategoryMap[key] === category
            );
            if(backendName === 'sales_by_customer' || backendName === 'sales_by_item' || backendName === 'sale_return_history' || backendName === 'stock_summary' 
              || backendName === 'inventory_evaluation_summary' || backendName === 'inventory_summary' || backendName === 'item_report_by_purchase' 
              || backendName === 'Vendor_balance' || backendName === 'Vendor_credit_details' || backendName === 'payments_made' 
              || backendName === 'purchase_order_details' || backendName === 'payable_details' || backendName === 'item_report_by_sale' 
              || backendName === 'Customer_balance' || backendName === 'Customer_balance_summary' || backendName === 'Receivable_Details' 
              || backendName === 'Employee_Summary_Report' || backendName === 'Categories_Summary_Report' || backendName === 'Summary_Sales_By_Time_Reports' 
              || backendName === 'purchase_by_item' || backendName === 'payments_received'){
              const permission = this.permissionData.find(
                (perm: any) => perm.permissionName === 'reports'
              );
              return permission && (permission.read || permission.create || permission.modify);
            } else {
              const permission = this.permissionData.find(
                (perm: any) => perm.permissionName === backendName
              );
              return permission && (permission.read || permission.create || permission.modify);
            }
          });

          localStorage.setItem('Permission', JSON.stringify({
              data: res.data,
              id: res.id,
            })
          );
        }
      });
    }
  }

  getSubscribedUser() {
    if (this.currentUser != null && this.currentUser.userType == 'CLIENT') {
      this.subscriptionService.getSubscribedUsersDetails({}, this.currentUser.id).then((res) => {});
    }
  }

  getExpirationTime() {
    let planExpiration = new Date(this.currentUser.planExpiresOn).getTime();
    let currentDate = new Date().getTime();
    this.difference = planExpiration - currentDate;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(this.difference / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor(
      (this.difference % (1000 * 60 * 60)) / (1000 * 60)
    );
    var seconds = Math.floor((this.difference % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    this.showTimeInterval =
      days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
    // If the count down is over, write some text
    if (this.difference < 0) {
      clearInterval(this.interval);
      this.showTimeInterval = 'EXPIRED';
    }
  }

  async logout(): Promise<void> {

    try {
      let totalamount = 0;
      const res: any = await this.saleInvoiceService.getDailyRegisterById({}, this.currentUser.dailysessionId);
      if (res.success && res.data) {
        totalamount = res.data?.totalamount;
  
        if(this.currentUser?.dailysessionId && (totalamount > 0)){
          this.toastService.toastMsg({
            title: 'Warning',
            content: 'You did not close the session yet!',
          });
          this.router.navigateByUrl('/pages/close-register');
        } else {
          this.authService.logout();
          this.router.navigateByUrl('/auth/role-selection');
        }
      }else{
        this.authService.logout();
        this.router.navigateByUrl('/auth/role-selection');
      }
    } catch (error) {
      this.authService.logout();
      this.router.navigateByUrl('/auth/role-selection');
    }
    
   
  }
  /**
   * get notification by userId
   */
  getAllNotificationByUserId() {
    this.notificationService
      .getAllNotification(
        {},
        this.currentUser.id,
        this.pageSize,
        this.currentPageNo + 1
      )
      .then(
        (res) => {
          this.isData = true;
          let notification = res.data.pageData;
          notification.forEach((element) => {
            if (element.read == true) {
              this.notificationReadArray.push(element);
              this.isShow = false;
              this.notificationUnreadArray = [];
            } else if (element.read === null) {
              this.notificationUnreadArray.push(element);
              this.isShow = true;
              this.notificationReadArray = [];
            }
          });
        },
        (err) => {
          if (err.error.expose) {
            this.isData = false;
          } else {
            this.toastService.toastMsg({
              title: 'Error',
              content: 'Something Went To Wrong',
            });
          }
        }
      );
  }
  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res) => {
      this.imageSrc = res.data.basicdetails.profileImage;
      this.firstName = res.data.basicdetails.firstName;
    });
  }
  handleImageLoad() {
    this.imageLoaded = true;
  }
  GoTOPages(type) {
    if (type) {
      this.router.navigate(['/pages/client-bid']);
    }
  }

  decodeURLComponent(url: string): string {
    const encodedUrl = encodeURIComponent(url);
    return decodeURIComponent(encodedUrl);
  }

  onSearch() {
    // this.filteredCategories = this.categories.filter((category) =>
    this.filteredCategories = this.categoriesGetByBE.filter((category) =>
      category.toLowerCase().includes(this.searchTerm.toLowerCase().trim())
    );  
  }

  onEnter() {
    // If user entered a category then this function will get called
    if (this.filteredCategories.length > 0) {
      const firstCategory = this.filteredCategories[0];
      const routerLink = this.categoryRouterLinks[firstCategory];
      if (routerLink) {
        const encodedUrl = encodeURIComponent(routerLink);
        this.router.navigate([decodeURIComponent(encodedUrl)]);
        // this.router.navigateByUrl(routerLink);
      }
    }
    this.closeDropdown(); //after the navigation is done dropdown get closed
  }

  closeDropdown() {
    this.filteredCategories = [];
    this.searchTerm = '';
  }

  openDialog(item: string) {
    let componentName;
    switch (item) {
      case 'Vendor_Group':
        componentName = PartyGroupAddComponent;
        break;
      case 'Contact':
        componentName = AddPartyComponent;
        break;
      case 'Product':
        componentName = ProductAddComponent;
        break;
      case 'Service':
        componentName = ServiceaddComponent;
        break;

      case 'sale-invoice':
        componentName = SalesInvoiceComponent;
      break;
      case 'payment-in':
        componentName = PaymentInComponent;
      break;
      case 'Estimation-Invoice':
        componentName = EstimateComponent;
      break;
      case 'sale-order':
        componentName = SaleOrderComponent;
      break;
      case 'sale-return':
        componentName = SalesReturnComponent;
      break;
        case 'purchase-invoice':
      componentName = PurchaseInvoiceComponent;
      break;
      case 'payment-out':
        componentName = PaymentOutComponent;
      break;
      case 'purchase-order':
        componentName = PurchaseOrderComponent;
      break;
      case 'purchase-return':
        componentName = PuchaseReturnComponent;
      break;
      case 'add-promotion':
        componentName = AddPromotionComponent;
      break;
      case 'bank':
        componentName = AddCashComponent;
        // componentName = AddBankComponent
      break;;
      case 'Appointment':
        componentName = AddAppointmentComponent;
      break;
      case 'Time-Schedule':
        componentName = AddTimeScheduleComponent;
      break;
      case 'Expense':
        componentName = ExpenseAddComponent;
      break;
      case 'Campaign':
        componentName = AddCampaignComponent;
      break;
      case 'Template':
        componentName = AddTemplateComponent;
      break;
      case 'Admin':
        componentName = AdminAddRoleFormComponent;
      break;
      case 'Employee':
        componentName = AddRoleBasedUsersComponent;
      break;
      case 'Lead':
        componentName = AddLeadComponent
      break;;
      case 'New-Structure':
        componentName = NewStructureComponent;
      break;
      case 'Time':
        componentName = AddTimeComponent
      break;;
      case 'add-asset':
        componentName = AssetFormcompoennetComponent;
      break;
      case 'add-non-asset':
        componentName = AssetNonFormcompoennetComponent;
      break;
      case 'Warehouse':
        componentName = AddWarehouseComponent;
      break;
      case 'Coupons':
        componentName = CouponModalComponent;
      break;
    }
    const dialogRef: MatDialogRef<Component> = this.dialog.open(componentName, {
      height: '100vh',
      width: 'auto',
      data: {},
      disableClose: true,
      position: {
        right: '0',
      },
    });
  }

  hasPermissionForAnySubcategory(subcategories: string[]): boolean {
    // Implement the logic to check if the user has permission for any subcategory
    return subcategories.some(subcategory => this.hasPermission(subcategory));
  }

  hasPermission(subcategory: string): boolean {
    // check if the user has permission for the subcategory
    const permissionType = ['create'];
    const permissionName = subcategory;

    return this.checkPermission(permissionType, permissionName);
  }

  checkPermission(permissionType: string[], permissionName: string): boolean {
    let hasPermission = false;
    // if(this.permissionArray !== (undefined || null)){
    if (this.permissionData && this.permissionData) {
      const permissionData = this.permissionData;

      permissionData.forEach(permission => {
        if (permission?.permissionName === permissionName) {
            for (let type of permissionType) {
                if ((permission[type] === true) || (permission[type] === 1)) {
                    hasPermission = true;
                    break;
                }
            }
        }
      });
    }

    return hasPermission;
  }

  openDialogForSwitchingUser(): void {
    const dialogRef: MatDialogRef<SwitchUserComponent> = this.dialog.open(SwitchUserComponent, {
      width: '600px',
      height: '340px',
      data: {},
      disableClose: true,
      position: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

}
