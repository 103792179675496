<div *ngIf="isLoading" class="loader-container">
    <div class="loader">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
  </div>
<div class="container-fluid mb-3 px-0 mx-0">
    <div class="row" id="headingPrintHide">
        <div class="col-md-6 fs--1"
            style="font-weight:500;font-size:20px!important;display: flex;align-items: center;white-space: nowrap;padding-left: 16px;">
            Sale Summary By HSN
        </div>
        <div class="col-md-6"
            style="display: flex; flex-direction: row; justify-content: flex-end;align-items: center;flex-wrap: wrap;">
            <div class="flex-item" style="padding-right: 16px;">
                <input type="search" id="searchInput" class="search-form-control pe-5 mb-2" placeholder="Search"
                    [(ngModel)]="searchKey" (input)="onSearchInputChange($event.target.value)">
            </div>
            <div class="flex-item navOption-btns" style="padding-right: 16px;">
                <button class="btn" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()"
                    type="button"><i class="fas fa-download"></i></button>
            </div>
            <div class="flex-item navOption-btns">
                <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i
                        class="bi-printer fa-1x"></i>
                </button>
            </div>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1 bg-white">
        <div class="row mb-3" style="display: flex; flex-direction: row;align-items: center;">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start;flex-basis:300px;" class="col-lg-3 col-sm-12 col-md-3">
                <label for="Date Range" class="form-label" id="datePrintHide">Date</label>
                <mat-form-field class="example-full-width" appearance="outline" id="datePrintHide">
                    <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                                        [bsConfig]="{ ranges: ranges,
                                        showWeekNumbers:false,
                                        showNextMonth: false,
                                        rangeInputFormat : 'DD MMM YYYY',
                                        containerClass: 'theme-dark-blue',
                                          displayOneMonthRange: true }">
                    <span>
                        <mat-icon class="iconcalender p-1">date_range</mat-icon>
                    </span>
                </mat-form-field>
            </div>

            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start;flex-basis:300px;" class="col-lg-3 col-sm-12 col-md-3">
                <label class="form-label" id="showoptionHide">Select Category</label>
                <mat-form-field class="example-full-width" appearance="outline" id="showoptionHide">
                    <!-- <input matNativeControl matInput formControlName="status" [disabled]="!isDisabledGST" [readonly]="!isDisabledGST"> -->
                    <mat-select matNativeControl placeholder="Category">
                        <mat-option value="ALL FIRM">ALL FIRM</mat-option>
                        <mat-option value="My Company">My Company</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="table-responsive scrollbar mb-4 mt-1 fs--1">
            <table class="table bg-white table-bordered table-stripped" id="excel-table">
                <thead class="" style="background-color: #F0F0F0;">
                    <tr class="text-white">
                        <!-- <th scope="col" class="text-center text-nowrap " style="width: 6%;">#</th> -->
                        <th scope="col" class="text-left text-nowrap">#
                            <button class="btn1 fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color: #ffffff !important;"
                                    class="text-1400  fs-1"></i></button>
                        </th>
                        <th scope="col" class="text-left text-nowrap">HSN
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right tableIcon"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Contains</option>
                                        <option value="1">Exact match</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col text-left text-nowrap" class="text-nowrap" style="text-align:right;">TOTAL VALUE
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right tableIcon"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="1">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-left text-nowrap" style="text-align:right;">TAXABLE VALUE
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right tableIcon"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-left text-nowrap" style="text-align:right;">IGST AMOUNT
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right tableIcon"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th scope="col text-left text-nowrap" class="text-nowrap" style="text-align:right;">CGST AMOUNT
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right tableIcon"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="1">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th scope="col text-left text-nowrap" class="text-nowrap" style="text-align:right;">SGST AMOUNT
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right tableIcon"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="1">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th scope="col text-left text-nowrap" class="text-nowrap" style="text-align:right;">ADD CESS
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i style="margin-left: 10px; color:#FF9A62"
                                    class="text-1400  fs-1 bi-filter-right tableIcon"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="1">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody id="component1">
                    <tr *ngFor="let sale of saleSummarylist; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{sale.hsn}}</td>
                        <td style="text-align: right;">AED{{sale.total_value | number:'1.2-2'}}</td>
                        <td style="text-align: right;">AED{{sale.taxable_value | number:'1.2-2'}}</td>
                        <td style="text-align: right;">AED{{sale.igst | number:'1.2-2'}}</td>
                        <td style="text-align: right;">AED{{sale.cgst | number:'1.2-2'}}</td>
                        <td style="text-align: right;">AED{{sale.sgst | number:'1.2-2'}}</td>
                        <td style="text-align: right;">AED{{sale.additionalCess | number:'1.2-2'}}</td>

                    </tr>
                </tbody>
                <tbody *ngIf="saleSummarylist == 0">
                    <tr class="text-center">
                        <td colspan="10">
                            <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                            <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr class="mt-1" />
        <div class="row" style="font-size: 12px;">
            <div class="col-lg-6">
                <strong class="text-dark">Total Value&nbsp;:&nbsp;<a class="text-success">AED{{totalValuesale | number:'1.2-2'}}</a></strong>
            </div>
            <div class="col-lg-6" style="display: flex; flex-direction:row; justify-content: flex-end;padding-right: 80px;">
                <strong class="text-dark">Total Items&nbsp;:&nbsp;<a
                        class="text-success">{{totalQuititysale}}</a> </strong>
            </div>
        </div>
    </div>
</div>
