import { TitleCasePipe, formatDate } from "@angular/common";
import lang from "src/assets/langTranslation/language_translation"
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges, LOCALE_ID } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ProfileService } from "src/app/core/services/profile.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import { Observable, pipe } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import * as moment from "moment";
import { createFalse } from "typescript";
import { log } from "console";
import { PartyService } from "src/app/core/services/party.service";
import { MatDialog } from '@angular/material/dialog';
import { EditBusinessPartnerModalComponent } from "./edit-business-partner-modal/edit-business-partner-modal.component";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonService } from "src/app/core/services/common.service";

interface IRange {
  value: Date[];
  label: string;
}
@Component({
  selector: "app-profile-settings",
  templateUrl: "./profile-settings.component.html",
  styleUrls: ["./profile-settings.component.scss"],
})
export class ProfileSettingsComponent implements OnInit, AfterViewInit {
  public lang = lang.UAE;
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public signImg: any = '';
  public profilePic: any = '';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  partners: any[] = [];
  cityArr: string[] = [
    'Abu Dhabi',
    'Dubai',
    'Sharjah',
    'Ajman',
    'Umm Al Quwain',
    'Ras Al Khaimah',
    'Fujairah',
  ];

  ranges: IRange[] = [
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ],
      label: "Last 7 Days",
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getMonth() - 1)),
        new Date(),
      ],
      label: "Last Month",
    },
  ];
  today: Date;
  maxDate: Date;
  public typeList = [];
  public value: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public data: any;
  public ProfileForm: FormGroup;
  public TradeForm: FormGroup;
  public addressForm: FormGroup;
  public businessForm: FormGroup;
  public dateRange: FormGroup;
  public updated: boolean = false;
  public submitted: boolean;
  public date = new Date();
  public addressId: string = "";
  public additionalPlaceOfBusiness: FormArray;
  public addressLength;
  public pinDetails: any;
  public gstDetails: any;
  public finalAddress: any;
  public pin: any;
  public businessNature: any = [];
  public compositeType: boolean = false;
  public isShown: boolean = true;        //it must be true in UAE for new registered account all field visibility
  public mobileOtp: any;
  public visible = true;
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public categoryCtrl = new FormControl('',[Validators.required]);
  public filteredCategory: Observable<string[]>;
  public categoryBusiness: any = [];
  public categoryName: any = [];
  public categoryId: any[] = [];
  public opened = false;
  public isDisabledGST: boolean = true;
  public addressArray = [];
  public partenerAray = []
  public businessType: any;
  public constitutionBusinessType: any;
  public legTrad: boolean = false;
  public cinFlag: boolean = false;
  public partnerSubmitted = false
  partnerDetails: any[] = [];
  additionaDetailsData: any[] = [];
  public cosntBusiness = [
    "Proprietorship",
    "Partnership",
    "Private Limited Company",
    "Public Limited Company",
    "Limited Liability Partnership",
    "Hindu Undivided Family"
  ];

  public uaeCosntBusiness = [
    "Sole proprietorship",
    "Civil Company",
    "Limited Liability Company (LLC)",
    "Partnership",
    "Private Share Holding Company",
    "Public Share Holding Company",
    "Branch of Foreign Companies/Representative Office",
    "Branch of GCC companies",
    "Branch of Free zone company",
    "Branch of Dubai based companies",
    "Branch of UAE based companies"
  ];
  @ViewChild("categoryInput") categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("autocompleteTrigger") matACTrigger: MatAutocompleteTrigger;
  serachByCategory: any;
  public ischecked: boolean = false;
  signatureData: string;
  imgNumber: any;
  showUploadInput: boolean = false;
  public imageSrc: any = "";
  profileImage: File;
  signatureImage: File;
  showAdditionalFields = false;
  editedRowIndex: number = -1;  //to store the index of the currently edited row
  additionaList: any[] = [];
  businessPlaceAdd: FormGroup;
  shopId: any;
  isFirstChange: boolean = true;
  isLoading: boolean = false;
  businesscategory: any[] = [];


  constructor(
    public profileService: ProfileService, public partyService: PartyService,
    public fb: FormBuilder, private cdr: ChangeDetectorRef,
    public router: Router, private commonService: CommonService,
    private toastService: ToastNotificationService, private titleCasePipe: TitleCasePipe,
    private dialog: MatDialog
  ) {
    this.today = new Date();
    this.maxDate = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      25
    );
  }

  async ngOnInit() {
    try {
      this.TradeForm = this.fb.group({
      })
        //form for profile data
        this.ProfileForm = this.fb.group({
          firstName: ["", [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
          legalName: [null, [Validators.pattern("^[a-zA-Z ]*$")]],
          businessType: [""],
          email: ["", [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]],
          mobile: [{value: "", disabled: true}],
          gstType: [null],
          gstStatus: [null],
          gstinNo: [null],
          city: [null, [Validators.required]],
          // city: [{ value: 'UAE', disabled: true }],
          pincode: [null],
          district: [null],
          landmark: [""],
          country: ['United Arab Emirates (UAE) (Former Trucial Oman, Trucial States)'],
          homeno: [""],
          address: ["", [Validators.required]],
          accountMethod: ["", [Validators.required]],
          compositeType: [""],
          businessNature: [""],
          panNumber: [null],
          tradeName: [null],
          financialYearFrom: [""],
          bookBeginningFrom: [null],
          cinNumber: [null],
          profileImage: [null],
          signatureImage: [null],
        });    
      this.getType();
      this.getBusinessCategory();
      //calling get data api for profile
      this.getProfileData();

      //form for multiple address
      this.addressForm = this.fb.group({
        addresses: new FormArray([]),
      });

      // For Additional place of Business
      this.businessPlaceAdd = this.fb.group({
        pincode: [null],
        city: [null, Validators.required],
        district: [null],
        country: ['United Arab Emirates (UAE) (Former Trucial Oman, Trucial States)', Validators.required],
        // city: ['', Validators.required],
        homeno: ['', Validators.required],
        address: ['adress'],
       // areaStreet: ['', Validators.required],
        landmark: ['', Validators.required],
      });

      //for partner Form
      this.businessForm = this.fb.group({
        position: [""],
        nameOfPartner: [""],
        licenceAndCertificate: [""],
        documentNumber: [""],
      })
    } catch (error) {
    } 
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cdr.detectChanges();
    });
  }

  getallPartnerList() {
    this.profileService.getBusinessPartners({}, this.currentUser.id).then((res: any) => {
      this.partnerDetails = res.data.filter(data => {
        return (!(data.position === null) && !(data.nameOfPartner === null))
      })
    });
  }

  getAdditionaDetails() {
    this.additionaDetailsData = []
    this.profileService.getAdditionaDetails({}, this.currentUser.id).then((res: any) => {
      this.additionaDetailsData = res.data.filter(data => {
        return (!(data.documentNumber === null) && !(data.licenceAndCertificate === null))
      })
    });
    if (this.additionaDetailsData.length > 0) {
      this.showAdditionalFields = true;
    }
  }

  toggleAdditionalFields() {
    this.showAdditionalFields = !this.showAdditionalFields;
  }

  validatecinNumber(cin) {
    let cinNo = cin.toUpperCase();
    var regex =
      /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;

    if (regex.test(cinNo)) {
    } else {
      this.ProfileForm.controls.cinNumber.setErrors({ incorrect: true });
    }
  }

  validatepanNumber(pan) {
    let panNo = pan.toUpperCase();
    var regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

    if (regex.test(panNo)) {
    } else {
      this.ProfileForm.controls.panNumber.setErrors({ incorrect: true });
    }
  }

  onInputChange(TRN: string) {
    setTimeout(() => {
        this.validateTRN(TRN);
    });
  }

  validateTRN(TRN) {
    if (TRN.length === 15) {
        this.isDisabledGST = false;
    } else {
      this.isDisabledGST = true;
      this.ProfileForm.controls.gstinNo.setErrors({ incorrect: true });
    }
    this.cdr.detectChanges();
  }

  /**
   * get gst type
   * @param gstType
   */

  /**
   * Get Controls of FormArray
   */
  get addressControls() {
    return this.addressForm.get("addresses") as FormArray;
  }

  addAddress(): void {
    try {
      const data = this.addressForm.get("addresses") as FormArray;
      this.cdr.detectChanges();
      if (data) {
        data.push(this.createAddress());
        this.cdr.detectChanges();
      }
    } catch {
    }
  }

  /**
   * Delete array index from FormArray
   * @param index
   */
  deleteAddressForm(index): void {
    const data = this.addressForm.get("addresses") as FormArray;
    data.removeAt(index);
  }


  /**
   * Add index to FormArray
   * @returns
   */
  createAddress(): FormGroup {
    return new FormGroup({
      homeno: new FormControl(),
      pincode: new FormControl(null),
      address: new FormControl(),
      city: new FormControl(),
      // state: new FormControl(),
      country: new FormControl('United Arab Emirates (UAE) (Former Trucial Oman, Trucial States)'),
      district: new FormControl(null),
      landmark: new FormControl(),
    });
  }
  /**
   * Remove index from FormArray
   * @param i
   */
  removeAddress(i: number) {
    (<FormArray>this.addressForm.get("addresses")).removeAt(i);
  }

  /**
   * Get Formcontrol of FormGroup
   */
  get f() {
    return this.ProfileForm.controls;
  }
  get fpart() {
    return this.businessForm.controls;
  }

  toggleShow(checked: boolean) {
    this.isShown = checked;

    if (!this.isShown) {
      this.ProfileForm.get("gstinNo").clearValidators();
      this.ProfileForm.get("gstinNo").updateValueAndValidity();
    } else {
      this.ProfileForm.get("gstinNo").setValidators([]);
      this.ProfileForm.get("gstinNo").updateValueAndValidity();
    }
  }

  getCategoryNamesFromIds(ids: number[]): string[] {
    return ids.map(id => {
      const category = this.categoryBusiness.find(item => item.id === id);
      return category ? category.category : '';
    });
  }

  /**
   * Get user profile data
   */
  async getProfileData() {
    await this.profileService.getUserProfile({}, this.currentUser.id).then((res) => {
      this.data = res.data;
      this.shopId = res.data.basicdetails?.id;
      this.profilePic = res.data.basicdetails.profileImage
      this.constitutionBusinessType = res.data.additional.businessType
      this.signImg = res.data.basicdetails.addSignature
      let otherAddresses = res.data.otherAddresses;
      if (res.data.additional.gstType == "Composite") {
        this.compositeType = true;
      }

      let mobileNumber = res.data.basicdetails.mobile;
      if (mobileNumber && mobileNumber.length === 9) {
        mobileNumber = '0' + mobileNumber;
      }

      if (otherAddresses.length > 0) {
        for (let i = 0; i < otherAddresses.length; i++) {
          this.addressId = otherAddresses[i].id;
          this.addressControls.push(
            new FormGroup({
              address: new FormControl(otherAddresses[i]?.address),
              city: new FormControl(otherAddresses[i]?.city),
              country: new FormControl(otherAddresses[i]?.country),
              homeno: new FormControl(otherAddresses[i]?.homeno),
              pincode: new FormControl(otherAddresses[i]?.pincode ? otherAddresses[i].pincode : null),
              // state: new FormControl(otherAddresses[i].state),
              landmark: new FormControl(otherAddresses[i]?.landmark),
              district: new FormControl(otherAddresses[i]?.district),
              id: new FormControl(otherAddresses[i]?.id),
            })
          );
        }
      }
      if (res.data.additional.isRegistered != null) {
        this.toggleShow(res.data.additional.isRegistered);
      }
      if (res.data.additional.gstinNo != null) {
        
      }
      let businessNatureData = res.data.businessTypes.map((item) => item.id);
      this.businesscategory = res.data.businessCategory.map((item) => item.id);
      this.categoryName = this.getCategoryNamesFromIds(this.businesscategory);
      //sending data from get api to form by patching
      this.ProfileForm.patchValue({
        email: res.data.basicdetails.email,
        businessType: res.data.additional.businessType,
        firstName: res.data.basicdetails.firstName,
        totalPlaces: res.data.additional.totalPlaces,
        legalName: res.data.additional.legalName,
        gstType: res.data.additional.gstType,
        gstStatus: res.data.additional.status,
        gstinNo: res.data.additional.gstinNo
          ? res.data.additional.gstinNo
          : null,
        compositeType: res.data.additional.compositeType,
        fullName: res.data.basicdetails.firstName,
        mobile: mobileNumber,
        tradeName: res.data.additional.tradeName,
        accountMethod: res.data.additional.accountMethod,
        pincode: res.data.primaryAddress.pincode ? res.data.primaryAddress.pincode : null,
        // state: res.data.primaryAddress.state ? res.data.primaryAddress.state : null,
        city: res.data.primaryAddress?.city ? res.data.primaryAddress?.city : null,
        country:  res.data.primaryAddress.country ? res.data.primaryAddress.country : 'UAE' ,
        address: res.data.primaryAddress.address ? res.data.primaryAddress.address : null,
        landmark: res.data.primaryAddress.landmark ? res.data.primaryAddress.landmark : null,
        district: res.data.primaryAddress.district ? res.data.primaryAddress.district : null,
        homeno: res.data.primaryAddress.homeno ? res.data.primaryAddress.homeno : null,
        panNumber: res.data.additional.panNumber ? res.data.additional.panNumber : null,
        financialYearFrom: res.data.additional.financialYearFrom ? moment(res.data.additional.financialYearFrom).format("DD-MMM-YYYY") : "",
        bookBeginningFrom: res.data.additional.bookBeginningFrom ? moment(res.data.additional.bookBeginningFrom).format("DD-MMM-YYYY") : "",
        cinNumber: res.data.additional.cinNumber,
        businessNature: businessNatureData,
        categoryCtrl: this.businesscategory,
      });
    });

    if (this.ProfileForm.value.gstinNo !== null) {
      this.isLoading = true
      this.isShown = true
      this.isDisabledGST = false
      this.isLoading = false
      this.cdr.detectChanges()
    }
    this.getallPartnerList();
    this.getAdditionaDetails()
    if (!this.ProfileForm.valid) {
      this.toastService.toastMsg({
        title:"Warning",
        content: "Profile data is not filled.",
      });
    }
  }

  //get pincode api
  getPincodeDetailsForAdditionalAddress(pin, index) {
    if (pin.length === 6) {
      this.profileService.getUserPincode({}, pin).then((res) => {
        this.pinDetails = res.data;
        this.addressControls.controls[index].patchValue({
          pincode: this.pinDetails.pincode,
          // state: this.pinDetails.stateName,
          district: this.pinDetails.districtName,
        });
        this.addressControls
          .at(index)
          .patchValue(res.data.districtName);
      });
    }
  }


  /**
   * get nature of business list
   */
  public getType() {
    this.profileService.getTypes({}).then((response) => {
      this.typeList = response.data;
    });
  }

  /**
   * Update user profile
   * @returns
   */
  updateUserProfile() {
    // console.log(this.ProfileForm);
    this.submitted = true;
    var registerdProfileData
    registerdProfileData = {
      basicdetails: {
        firstName: this.ProfileForm.controls.firstName.value,
        // addSignature: this.signImg,
        // profileImage: this.profileImage,
      },
      additional: {
        isRegistered: this.isShown,
        legalName: this.ProfileForm.controls.legalName.value
          ? this.ProfileForm.controls.legalName.value
          : null,
        businessType: this.ProfileForm.controls.businessType.value
          ? this.ProfileForm.controls.businessType.value
          : null,
        // gstType: this.ProfileForm.controls.gstType.value,
        // gstStatus: this.ProfileForm.controls.gstStatus.value,
        // ...(this.ProfileForm.controls.compositeType.value == "Composite") && ({ "compositeType": this.ProfileForm.controls.compositeType.value }),
        compositeType: this.ProfileForm.controls.compositeType.value
          ? this.ProfileForm.controls.compositeType.value
          : "Trader",
        gstinNo: this.ProfileForm.controls.gstinNo.value
          ? this.ProfileForm.controls.gstinNo.value.toUpperCase()
          : null,
        accountMethod: this.ProfileForm.controls.accountMethod.value,
        city: this.ProfileForm.controls.city.value ? this.ProfileForm.controls.city.value : null,
        //   "legalName": this.ProfileForm.controls.legalName.value,
        totalPlaces: 0,
        category: [...this.categoryId, ...this.businesscategory],
        tradeName: this.ProfileForm.controls.tradeName.value,
        panNumber: this.ProfileForm.controls.panNumber.value,
        financialYearFrom: this.ProfileForm.controls.financialYearFrom.value,
        bookBeginningFrom: this.ProfileForm.controls.bookBeginningFrom.value,
        cinNumber: this.ProfileForm.controls.cinNumber.value ? this.ProfileForm.controls.cinNumber.value : null,
      },
      address: {
        // state: this.ProfileForm.controls.state.value,
        pincode: this.ProfileForm.controls.pincode.value?this.ProfileForm.controls.pincode.value : null,
        city: this.ProfileForm.controls.city.value ? this.ProfileForm.controls.city.value : null,
        district: null,
        // landmark: this.ProfileForm.controls.landmark.value ? this.ProfileForm.controls.landmark.value : "landmark",
        country: this.ProfileForm.controls.country.value,
        // homeno: "homeno",
        address: this.ProfileForm.controls.address.value,
      },
      businesses: this.ProfileForm.controls.businessNature.value,
      categoryBusiness: [...this.categoryId, ...this.businesscategory],
      // businessCategory: this.ProfileForm.controls.categoryCtrl.value,
    };

    // registerdProfileData = {
    //   basicdetails: {
    //     firstName: this.ProfileForm.controls.firstName.value,
    //     // addSignature: this.signImg,
    //     // profileImage: this.profileImage,
    //   },
    //   additional: {
    //     isRegistered: false,
    //     legalName: this.ProfileForm.controls.legalName.value
    //       ? this.ProfileForm.controls.legalName.value
    //       : null,
    //     businessType: this.ProfileForm.controls.businessType.value
    //       ? this.ProfileForm.controls.businessType.value
    //       : null,
    //     gstType: "Regular",
    //     // gstStatus: this.ProfileForm.controls.gstStatus.value,
    //     // ...(this.ProfileForm.controls.compositeType.value == "Composite") && ({ "compositeType": this.ProfileForm.controls.compositeType.value }),
    //     // compositeType: this.ProfileForm.controls.compositeType.value
    //     // ? this.ProfileForm.controls.compositeType.value
    //     // : "Trader",
    //     gstinNo: this.ProfileForm.controls.gstinNo.value
    //       ? this.ProfileForm.controls.gstinNo.value.toUpperCase()
    //       : null,
    //     accountMethod: this.ProfileForm.controls.accountMethod.value,
    //     state: this.ProfileForm.controls.state.value,
    //     //   "legalName": this.ProfileForm.controls.legalName.value,
    //     totalPlaces: 0,
    //     category: [...this.categoryId],
    //     tradeName: this.ProfileForm.controls.tradeName.value,
    //     // panNumber: this.ProfileForm.controls.panNumber.value,
    //     financialYearFrom: this.ProfileForm.controls.financialYearFrom.value,
    //     // bookBeginningFrom: this.ProfileForm.controls.bookBeginningFrom.value,
    //     // cinNumber: this.ProfileForm.controls.cinNumber.value ? this.ProfileForm.controls.cinNumber.value : null,
    //   },
    //   address: {
    //     state: this.ProfileForm.controls.state.value,
    //     // pincode: this.ProfileForm.controls.pincode.value,
    //     city: this.ProfileForm.controls.district.value ? this.ProfileForm.controls.district.value : "",
    //     district: this.ProfileForm.controls.district.value,
    //     // landmark: this.ProfileForm.controls.landmark.value ? this.ProfileForm.controls.landmark.value : "landmark",
    //     country: this.ProfileForm.controls.country.value,
    //     // homeno: "homeno",
    //     address: this.ProfileForm.controls.address.value,
    //   },
    //   businesses: this.ProfileForm.controls.businessNature.value,
    //   categoryBusiness: [...this.categoryId],
    //   // categoryBusiness: [...this.categoryId, ...this.businesscategory],
    //   // businessCategory: this.ProfileForm.controls.categoryCtrl.value,
    // };

    let finalObject;

    if (!this.isShown) {
      finalObject = registerdProfileData;
    } else {
      finalObject = registerdProfileData;
      // finalObject = profileData;
    }
   console.log("finalObject",finalObject.categoryBusiness)

    if (this.ProfileForm.valid ) {
      this.profileService
        .updateUserprofile(finalObject, this.currentUser.id)
        .then(
          (res) => {
            if (res.success) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Profile Updated Successfully!",
              })
              this.addBusinessPartners()
              this.addDetailsAPI()
              this.router.navigate(["/pages/profile"]);
              this.partyService.notifyPartyAdded();
            }
          },
          (err) => {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
        );
    } else {
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Fill Up Mandatory Details First Before Submit",
      });
    }
  }

  /**
   * Add multiple address
   */
  addMultipleAddress() {
    this.submitted=true;
    const newAddress = this.addressForm.get('addresses').value[this.addressControls.length - 1];
    // Check if the new address is not null and contains valid data
    if (newAddress.address && newAddress.city && newAddress.country && newAddress.homeno && newAddress.landmark ) {
      var addressData = {
        id: newAddress.id || "",
        address: newAddress.address,
        city: newAddress.city,
        country: newAddress.country,
        district: null,
        homeno: newAddress.homeno,
        landmark: newAddress.landmark,
        pincode: newAddress.pincode,
        // state: newAddress.state
      };
      console.log("address form value",addressData)
      this.profileService.postMultipleAddress({ addresses: [addressData] }, this.currentUser.id)
        .then(
          (res) => {
            if (res.success) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Address Added Successfully!",
              })
              //window.location.reload();
              this.addressArray = [];
            }
          },
          (err) => {
            if (err.error.status === 400) {
              this.toastService.toastMsg({
                title: "Error",
                content: "Fill All Additional Address Details.",
              });
            }
          }
        );

    } else if (newAddress.address && newAddress.city && newAddress.country && newAddress.homeno && newAddress.landmark) {
      var addressData = {
        id: newAddress.id || "",
        address: newAddress.address,
        city: newAddress.city,
        country: newAddress.country,
        district: null,
        homeno: newAddress.homeno,
        landmark: newAddress.landmark,
        pincode:newAddress.pincode,
        // state: newAddress.state
      };

      this.profileService.postMultipleAddress({ addresses: [addressData] }, this.currentUser.id)
        .then(
          (res) => {
            if (res.success) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Address Added Successfully!",
              })
              //window.location.reload();
              this.addressArray = [];
            }
          },
          (err) => {
            if (err.error.status === 400) {
              this.toastService.toastMsg({
                title: "Error",
                content: "Fill All Additional Address Details.",
              });
            }
          }
        );

    }
  }

  addDetails() {
    this.partnerSubmitted = true
    if (this.businessForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {
      let partner = {
        "documentNumber": this.businessForm.value?.documentNumber,
        "licenceAndCertificate": this.businessForm.value?.licenceAndCertificate,
      }
      this.additionaDetailsData.push(partner);
      this.additionaList.push(partner);
      this.businessForm.reset();
    }
  }

  addDetailsAPI() {
    if (this.businessForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else if (!(this.businessForm.value?.documentNumber === "" || this.businessForm.value?.documentNumber === null) && !(this.businessForm.value?.licenceAndCertificate === "" || this.businessForm.value?.licenceAndCertificate === null)) {
      let partner = {
        "documentNumber": this.businessForm.value?.documentNumber,
        "licenceAndCertificate": this.businessForm.value?.licenceAndCertificate,
      }
      this.additionaList.push(partner);
      this.profileService.addLicDocument(this.additionaList, this.currentUser.id).then((res) => {
        if (res.success) {
          this.businessForm.reset();
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }

  addPartner(event: KeyboardEvent): void {
    if (event) {
      event.preventDefault(); // Prevent the default behavior of the Enter key
    }
    this.partnerSubmitted = true
    if (this.businessForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Requirted Fields.",
      });
    }
    else {
      let partner = {
        "position": this.businessForm.value?.position,
        "nameOfPartner": this.businessForm.value?.nameOfPartner,
      }
      this.partnerDetails.push(partner);
      this.partners.push(partner);
      this.businessForm.reset();
    }
  }

  openEditModal(i: number, partner: any): void {
    const dialogRef = this.dialog.open(EditBusinessPartnerModalComponent, {
      width: '400px',
      data: { position: partner.position, nameOfPartner: partner.nameOfPartner, businessType: this.ProfileForm.value?.businessType, partnerId: partner.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getallPartnerList()
        // Update the edited partner in the partnerForms array
        let partner = this.businessForm[i].patchValue({
          position: result.position,
          nameOfPartner: result.nameOfPartner,
        });
        this.partnerDetails.push(partner);

      }
    });

  }

  addBusinessPartners() {
    if (this.businessForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Requirted Fields.",
      });
      return false;
    }
    else if (!(this.businessForm.value.position === "" || this.businessForm.value.position === null) && !(this.businessForm.value.nameOfPartner === "" || this.businessForm.value.nameOfPartner === null)) {
      let partner = {
        "position": this.businessForm.value?.position,
        "nameOfPartner": this.businessForm.value?.nameOfPartner,
      }
      this.partners.push(partner);
    }
    this.profileService.addBusinessPartners(this.partners, this.currentUser.id).then((res) => {
      if (res.success) {
        this.partners = []
        this.addDetailsAPI()
        this.businessForm.reset();
        this.updated = true;
      }

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

  deletePartner(partnerId) {
    this.profileService.deletePartner({}, this.currentUser.id, partnerId).then((res) => {
      this.getallPartnerList()
      this.toastService.toastMsg({
        title: "Success",
        content: "Partner Deleted Successfully!",
      })

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })

  }

  isInvalidDate(event) {
    let test = event.target.value;

    if (test == "Invalid date") {
      event.target.value = formatDate(new Date(), "DD/MM/", "en"); // Change it here
    }
  }
  /**
   * update multiple address by addressId
   * @param item
   */
  updateMultipleAddress(item) {
    this.submitted=true
    let data = {
      homeno: item.value.homeno ? item.value.homeno : "",
      landmark: item.value.landmark ? item.value.landmark : "",
      district: null,
      pincode: item.value.pincode ? `${item.value.pincode}` :null,
      address: item.value.address ? item.value.address : "",
      city: item.value.city ? item.value.city : "",
      // state: item.value.state ? item.value.state : "",
      country: item.value.country ? item.value.country : "",
    };
    this.profileService
      .updateMultipleAddress(data, item.value.id)
      .then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Address Updated Successfully!",
          })
          //window.location.reload()
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      });
  }


  /**
   * Delete Addresses by addressId
   * @param item
   */
  deleteAddress(item) {
    this.profileService.deleteAddressById({}, item.value.id).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Address Deleted Successfully!",
        })
        this.router.navigate(["/pages/profile"]);
      }
    });
  }

  // Additional Place Of Business
  addPlaceOfBusiness() {
    if (this.businessPlaceAdd.valid) {
      const addressData = this.businessPlaceAdd.value;
      this.profileService.postMultipleAddress({ addresses: [addressData] }, this.shopId)
        .then(
          (res) => {
            if (res.success) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Address Added Successfully!",
              })
            
              this.businessPlaceAdd.reset();
            }
          },
          (err) => {
            if (err.error.status == 400) {
              this.toastService.toastMsg({
                title: "Error",
                content: "Fill All Additional Address Details.",
              });
            } else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something went wrong",
              });
            }
          }
        );
    } else {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
    }
  }

  /**
   * Update Mobile
   */
  updateMobile() {
    let data = {
      mobile: this.ProfileForm.controls.mobile.value,
    };
    this.profileService
      .updateClientMobileOTP(data, this.currentUser.id)
      .then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "OTP Sent Successfully!",
        })
      });
  }

  /**
   *
   */
  updateMobileNumber() {
    let data = {
      email: this.ProfileForm.controls.email.value,
      mobile: this.ProfileForm.controls.mobile.value,
      mobileotp: this.mobileOtp,
    };
    this.profileService.updateClientMobile(data).then((res) => {
      this.toastService.toastMsg({
        title: "Success",
        content: "Mobile Number Verified Successfully!",
      })
    });
  }

  /**
   * add category name in categortNames array
   * @param event
   */
  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;
    // Add our category
    if ((value || "").trim()) {
      this.typeList.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
    this.categoryCtrl.setValue(value);
  }

  /**
   * remove/delete selected category
   * @param category
   */
  remove(category: any): void {
    let index = this.categoryName.indexOf(category);
    if (index >= 0) {
      this.categoryName.splice(index, 1);
      this.categoryId.splice(index, 1);
    }
  }

  /**
   * called when category selected from dropdown
   * @param event
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    let newValue = event.option.viewValue;
    if (this.categoryName.includes(newValue)) {
      this.categoryName = [
        ...this.categoryName.filter((category) => category !== newValue),
      ];
    } else {
      this.categoryName.push(event.option.viewValue);
    }
    this.categoryInput.nativeElement.value = "";
  }

  /**
   * open category dropdown panel
   * @param trigger
   */
  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    this.categoryInput.nativeElement.focus();
  }

  searchCategoryByCategory(categoryInput) {
    this.profileService.getCategoryBySearch({}, categoryInput).then((res) => {
      this.serachByCategory = res.data;
    });
  }
  /**
   * Get all categories
   */
  getBusinessCategory() {
    this.profileService.getBuisnessCategoryAll({}).then((res) => {
      this.categoryBusiness = res.data.rows;
      this.filteredCategory = this.categoryCtrl.valueChanges.pipe(
        startWith(""),
        map((name) =>
          name ? this.filterNames(name) : this.categoryBusiness.slice()
        )
      );
    });
  }

  /**
   * filter search category names
   * @param name
   * @returns
   */
  private filterNames(name: string): string[] {
    return this.categoryBusiness.filter((list) => {
      list.category.toLowerCase().indexOf(name) === 0
    }
    );
  }

  public array = [];
  getCategoryId(list) {
    this.array.push(list.id);
    this.categoryId = this.array.filter(
      (item, index, self) => self.indexOf(item) === index
    );
    this.getBusinessCategory();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  signatureDragEnter() {
    this.dragging = true;
  }

  signatureDragLeave() {
    this.dragging = false;
  }

  signatureLoad() {
    this.imageLoaded = true;
  }

  signatureDrop(e) {
    e.preventDefault();
    this.dragging = false;
  }

  signatureChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.signImg = reader.result;
      };
      if (file === null) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select Image To Upload.",
        });
      } else {
        formdata.set("uploadSignature", file);
        e.srcElement.value = "";
        this.profileService
          .signatureImgAPI(formdata, this.currentUser.id)
          .then((res) => {
            this.toastService.toastMsg({
              title: "Success",
              content: "Image Uploaded Successfully!",
            })
          
          },
            (err) => {
            })
        this.loaded = true;
      }
    }
  }


  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
   
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
      if (file == null) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select Image To Upload.",
        });
      } else {
        formdata.set("upload", file);
        e.srcElement.value = "";
        this.profileService
          .postProfileImage(formdata, this.currentUser.id)
          .then((res) => {
            this.toastService.toastMsg({
              title: "Success",
              content: "Image Uploaded Successfully!",
            })
            this.partyService.notifyPartyAdded();
          });
        this.loaded = true;
      }
    }
  }
  // }

  deleteProfilePic() {
    // Clear the profilePic value
    this.profilePic = '';
    let formdata = new FormData();
    formdata.set("upload", null); // Sending an empty file or null to indicate deletion
    this.profileService.deleteProfileImg(formdata, this.currentUser.id)
      .then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Image Deleted Successfully!",
        })
        this.partyService.notifyPartyAdded();
        this.profilePic = null; // Clear the profilePic value
        this.loaded = true;
      })
      .catch((error) => {
      });
  }

  deleteSignature() {
    // Clear the profilePic value
    this.signImg = '';
    let formdata = new FormData();
    formdata.set("uploadSignature", null);
    this.profileService
      .deleteSignature(formdata, this.currentUser.id)
      .then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Image Deleted Successfully!",
        })
        this.signImg = null;
        this.loaded = true;
      },
        (err) => {
        })
  }

  openDatePicker() {
    const selectedDate = this.ProfileForm.get('bookBeginningFrom').value;
    // Check if selectedDate is a Date object
    if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
      // If it's a valid Date, continue with your logic
      console.log('Selected Date:', selectedDate);
    } else {
      // If selectedDate is not a valid Date, set a default date or any valid date here
      this.ProfileForm.get('bookBeginningFrom').setValue(new Date());
    }
  }  

  openDatepicker1() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.ProfileForm.get('financialYearFrom').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.ProfileForm.get('financialYearFrom').setValue(new Date());
    }
  }

}
