import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CustomerProfileService } from 'src/app/core/services/customer-profile.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-customer-profile-setting',
  templateUrl: './customer-profile-setting.component.html',
  styleUrls: ['./customer-profile-setting.component.scss']
})
export class CustomerProfileSettingComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public data: any;
  public customerProfileForm: FormGroup;
  public addressForm: FormGroup;
  public updated: boolean = false;
  public submitted: boolean;
  public date = new Date
  public addressId: string = ""
  public additionalPlaceOfBusiness: FormArray;
  public addressLength;
  public pinDetails: any;
  public mobileOtp: any;
  city: string[] = [
    'Abu Dhabi',
    'Dubai',
    'Sharjah',
    'Ajman',
    'Umm Al Quwain',
    'Ras Al Khaimah',
    'Fujairah',
  ];

  // id: any;
  constructor(public profileService: CustomerProfileService,
    public fb: FormBuilder,
    public router: Router,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    //form for profile data
    this.customerProfileForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      lastName: ["", [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      email: ["", [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]],
      mobile: ["",[Validators.pattern("[0-9]{10}")]],
      // state: ["", [Validators.required]],
      city: ["", [Validators.required]],
      pincode: ["", [Validators.required]],
      district: [null],
      landmark: [""],
      country: ["", [Validators.required]],
      homeno: ["", [Validators.required]],
      address: ["", [Validators.required]],

    })

    //form for multiple address
    this.addressForm = this.fb.group({
      addresses: new FormArray([])
    })
    this.getCustomerDetails();
  }

  /**
   * Get Controls of FormArray
   */
  get addressControls() {
    return this.addressForm.get('addresses') as FormArray;
  }

  addAddress(): void {
    const data = this.addressForm.get('addresses') as FormArray;
    data.push(this.createAddress());
  }
  /**
   * Delete array index from FormArray
   * @param index
   */
  deleteAddressForm(index): void {
    const data = this.addressForm.get('addresses') as FormArray;
    data.removeAt(index);
  }

  /**
  * Add index to FormArray
  * @returns
  */
  createAddress(): FormGroup {
    return new FormGroup({
      homeno: new FormControl(),
      pincode: new FormControl(),
      address: new FormControl(),
      city: new FormControl(),
      // state: new FormControl(),
      country: new FormControl(),
      district: new FormControl(),
      landmark: new FormControl(),

    });
  }


  /**
  * Remove index from FormArray
  * @param i
  */
  removeAddress(i) {
    const data = this.addressForm.get('addresses') as FormArray;
    data.removeAt(i);
  }

  /**
  * Get Formcontrol of FormGroup
  */
  get f() {
    return this.customerProfileForm.controls;
  }

  updateCustomerProfile() {
    this.submitted = true;
    if (this.customerProfileForm.invalid) {
      return false;
    }
    else {
      let body = {
        "basicdetails": {
          "firstName": this.customerProfileForm.controls.firstName.value,
          "lastName": this.customerProfileForm.controls.lastName.value,
          "email" : this.customerProfileForm.controls.email.value,
        },
        "additional": {},
        "address": {
          "homeno": this.customerProfileForm.controls.homeno.value,
          "pincode": this.customerProfileForm.controls.pincode.value,
          "address": this.customerProfileForm.controls.address.value,
          "city": this.customerProfileForm.controls.city.value,
          "district": null,
          "landmark": this.customerProfileForm.controls.landmark.value,
          // "state": this.customerProfileForm.controls.state.value,
          "country": this.customerProfileForm.controls.country.value,
        }
      }
      this.profileService.getCustomerEdit(body, this.currentUser.id).then((res) => {
        if (res) {
          this.getCustomerDetails();
          this.toastService.toastMsg({
            title: "Success",
            content: "Customer Profile Updated Successfully!"
          });
          this.router.navigate(['/pages/customer/customer-profile'])
          this.updated = true;
        }
      }, (err) => {
        if(err.error.status === 500){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
      })
    }
  }

  getCustomerDetails() {
    this.profileService.getCustomerProfileByUserId({}, this.currentUser.id).then((res => {
      this.customerProfileForm.patchValue({
        firstName: res.data.basicDetails.firstName,
        lastName: res.data.basicDetails.lastName,
        email: res.data.basicDetails.email,
        mobile: res.data.basicDetails.mobile,

        //primary address
        pincode: res.data.primaryAddress.pincode,
        // state: res.data.primaryAddress.state,
        district: res.data.primaryAddress.district,
        city: res.data.primaryAddress.city,
        homeno: res.data.primaryAddress.homeno,
        address: res.data.primaryAddress.address,
        country: res.data.primaryAddress.country,
        landmark: res.data.primaryAddress.landmark,
      })
      let otherAddresses = res.data.otherAddresses;
      if (otherAddresses.length > 0) {
        for (let i = 0; i < otherAddresses.length; i++) {
          this.addressId = otherAddresses[i].id
          this.addressControls.push(
            new FormGroup({
              address: new FormControl(otherAddresses[i].address),
              city: new FormControl(otherAddresses[i].city),
              country: new FormControl(otherAddresses[i].country),
              homeno: new FormControl(otherAddresses[i].homeno),
              pincode: new FormControl(otherAddresses[i].pincode),
              // state: new FormControl(otherAddresses[i].state),
              landmark: new FormControl(otherAddresses[i].landmark),
              district: new FormControl(otherAddresses[i].district),
              id: new FormControl(otherAddresses[i].id)
            })

          )
        }
      }

    }),(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getPincodeDetails(pincode) {
    this.profileService.getUserPincode({}, pincode).then((res) => {
      this.pinDetails = res;
      this.customerProfileForm.patchValue({
        district: res.data.districtName,
        // state: res.data.stateName,

      })
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  updateMobile() {
    let data = {
      "mobile": this.customerProfileForm.controls.mobile.value,
    }

    this.profileService.updateClientMobileOTP(data, this.currentUser.id).then((res) => {
      // this.toastService.openSnackBar("OTP Sent Successfully!");
      this.toastService.toastMsg({
        title: "Success",
        content: "OTP Sent Successfully!",
      });
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  updateMobileNumber() {
    let data = {
      "email": this.customerProfileForm.controls.email.value,
      "mobile": this.customerProfileForm.controls.mobile.value,
      "mobileotp": this.mobileOtp,
    }
    this.profileService.updateClientMobile(data).then((res) => {
      // this.toastService.openSnackBar("Mobile Number Verified Successfully!")
      this.toastService.toastMsg({
        title: "Success",
        content: "Mobile Number Verified Successfully!",
      });
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  addMultipleAddress() {
    let addressData = this.addressControls.value
    let addressArray = []
    addressData.forEach(e => {
      let data = {
        "address": e.address,
        "city": e.city,
        "country": e.country,
        "district": null,
        "homeno": e.homeno,
        "landmark": e.landmark,
        "pincode": (e.pincode).toString(),
        // "state": e.state
      }
      addressArray.push(data)
    });
    addressData.splice(0, this.addressLength)
    this.profileService.postMultipleAddress({ addresses: addressArray }, this.currentUser.id).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Address Added Successfully!",
        });
        // this.toastService.openSnackBar("Address Added Successfully!")
        window.location.reload()
      }
    }, (err) => {
      if (err.error.status == 400) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        // this.toastService.openErrorSnackBar("Fill All Required Fields")
      } else if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getPincodeDetailsForAdditionalAddress(pin, index) {
    if (pin.length == 6) {
      this.profileService.getUserPincode({}, pin).then((res) => {
        this.pinDetails = res.data
        this.addressControls.controls[index].patchValue({
          pincode: this.pinDetails.pincode,
          // state: this.pinDetails.stateName,
          district: this.pinDetails.districtName
        })
        this.addressControls.at(index).patchValue(res.data.districtName);
      },(err)=>{
        if(err.error.status === 500){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
      })
    }
  }

  updateMultipleAddress(item) {
    let data = {
      "homeno": item.value.homeno ? item.value.homeno : "",
      "landmark": item.value.landmark ? item.value.landmark : "",
      "district": null,
      "pincode": item.value.pincode ? item.value.pincode : "",
      "address": item.value.address ? item.value.address : "",
      "city": item.value.city ? item.value.city : "",
      // "state": item.value.state ? item.value.state : "",
      "country": item.value.country ? item.value.country : ""
    }
    this.profileService.updateMultipleAddress(data, item.value.id).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Address Updated Successfully!",
        });
        // this.toastService.openSnackBar("Address Updated Successfully!")
        //  window.location.reload()
      }
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  deleteAddress(item) {
    this.profileService.deleteAddressById({}, item.value.id).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Address Deleted Successfully!",
        });
        // this.toastService.openSnackBar("Address Deleted Successfully!")
        window.location.reload()
      }
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }


}
