<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px;" class="modal-title mb-0">Sale Order</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px ;" type="button" class="btn btn-cancel"
      (click)="onCancelModal()">Cancel</button>
      <button style="margin-right: 10px;" type="button" *appHasPermission="['create']; name 'saleorder'"
      class="btn btn-save" (click)="setSaveAsDraft()" [hidden]="draftId">
      Save as Draft
    </button>
    <button type="submit" class="btn btn-save" (click)="SaveSaleOrder()" *appHasPermission="['create']; name 'saleorder'">Save</button>

  </div>

</div>

<div class="modal-body">

  <!-- UAE -->

  <form [formGroup]="SaleOrderForm">
    <div class="row">
      <div style="font-size: 14px;" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" style="font-size: 14px">Customer Name<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline"
          style="height: 48px ;margin-bottom: 3px;">
          <input type="text" placeholder="Customer Name" aria-label="Select Contact Name" matInput
            (keyup)="searchPartyByName($event.target.value)" [formControl]="myControl" [matAutocomplete]="auto"
            [ngClass]="{
                'is-invalid': (myControl.invalid && myControl.touched) && submitted }" required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngIf="this.currentUser.userType !== 'SHOP' && this.currentUser.userType !== 'WAREHOUSE'">
              <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Add new Customer"
                (click)="openAddPartyModal(addParty)">
                Add new Customer
              </a>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails(option)">
              {{option.partyName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="
                (submitted || myControl.touched) && myControl.errors?.required
              ">
          Customer Is Required.
        </mat-error>

        <label class="form-label" style="font-size: 14px;">Order Date <span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px;" class="example-full-width" appearance="outline">
          <input matInput required placeholder="Order Date" id="Order Date" [outsideClick]="true" ngModel bsDatepicker
            [maxDate]="today" [(ngModel)]="todaysDate"
            formControlName="orderDate"
            [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }"
            [ngClass]="{
            'is-invalid': (f.orderDate.invalid && f.orderDate.touched) && submitted }"
            (click)="openDatepickerOrderDate()">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>

        <mat-error class="text-danger" *ngIf="(submitted || f.orderDate.touched) && f.orderDate.errors?.required">
          Order Date Is Required.
        </mat-error>
      </div>

      <div style="font-size: 14px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Order #<span
            class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline"
          style="height: 48px;margin-bottom: 3px;">
          <input matInput type="text" placeholder="Order #" (keypress)="numberOnly($event)" formControlName="orderNo"
            required [ngClass]="{
          'is-invalid': (f.orderNo.invalid && f.orderNo.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.orderNo.touched) && f.orderNo.errors?.required">
          Order No Is Required.
        </mat-error>

        <label class="form-label" style="font-size: 14px !important">Due Date <span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline"
          style="height: 48px !important;">
          <input matInput required placeholder="Due Date" id="Due Date" [outsideClick]="true" ngModel bsDatepicker
            formControlName="dueDate" [minDate]="today" [(ngModel)]="todaysDate2"
            [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }"
            [ngClass]="{
            'is-invalid': (f.dueDate.invalid && f.dueDate.touched) && submitted }" (click)="openDatepickerDueDate()">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>

        <mat-error class="text-danger" *ngIf="(submitted || f.dueDate.touched) && f.dueDate.errors?.required">
          Due Date Is Required.
        </mat-error>
      </div>

      <div style="font-size: 14px" class="col-sm-12 col-md-12 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Billing Address<span
            class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <textarea matInput rows="7" placeholder="Billing Address" formControlName="billingAddress" maxlength="150"
            required [ngClass]="{
                'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
        </mat-form-field>
        <mat-error class="text-danger"
          *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
          Billing Address Is Required.
        </mat-error>

      </div>
    </div>

    <div class="row mt-2">
      <div style="font-size: 14px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Mobile #</label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput type="text" (keypress)="numberOnly($event)" placeholder="Mobile #" minlength="10" maxlength="10"
            formControlName="MobileNo">
        </mat-form-field>
      </div>

      <div [ngSwitch]="currentUser.userType" class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *appHasPermission="['delete', 'modify','create', 'read']; name 'warehouse'">
        <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
          <label class="form-label">Warehouse</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse"
              (selectionChange)="onWarehouseSelectionChange($event)">
              <mat-option value="clear">Clear Selection</mat-option>
              <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{warehouse.firstName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
          <label class="form-label">Warehouse</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse"
              (selectionChange)="onWarehouseSelectionChange($event)">
              <mat-option value="clear">Clear Selection</mat-option>
              <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{warehouse.firstName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="table-responsive my-custom-scrollbar">
      <table class="table table-bordered ">
        <thead class="table-secondary">
          <tr class="font_size font-weight-bold">
            <th scope="col" style="width:4%;vertical-align: middle;">
              #
            </th>
            <th scope="col" style="width: 10%;vertical-align: middle;">
              SKU<span class="text-danger"> *</span>
            </th>
            <th style="vertical-align: middle;" class="text-nowrap" scope="col">
              SKU Description
            </th>
            <th style="vertical-align: middle;">Unit</th>

            <th style="vertical-align: middle;">Quantity</th>
            <th style="vertical-align: middle;" scope="col" class="text-nowrap" colspan="2">
              {{ lang.Base_Price_In_Rs }}<span class="text-danger"> *</span>
            </th>

            <th style="vertical-align: middle;" class="text-center" scope="col" colspan="2">
              {{lang.GST}}<span class="text-danger"> *</span>
            </th>

            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
              {{lang.Total_In_Rs}}<span class="text-danger"> *</span>
            </th>
            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
              Action<span class="text-danger"> *</span>
            </th>
          </tr>
        </thead>

        <tbody class="mod">
          <tr>
            <td></td>
            <td>
              <!-- <input type="text" matInput placeholder="SKU" (change)="getGoodsByBarcode()"
                [(ngModel)]="barcode" [ngModelOptions]="{standalone: true}" required /> -->
              <mat-form-field class="example-full-width" appearance="outline">
                <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                  matInput [formControl]="myControl1" [matAutocomplete]="productAuto">
                <mat-icon class="m-2" matSuffix>search</mat-icon>
                <mat-autocomplete #productAuto="matAutocomplete">
                  <mat-option *ngFor="let option of productOptions | async" [value]="option.itemName"
                    (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                    <span style="font-size: 12px;">
                      {{ option.itemName | titlecase }}/<b>{{ option.barcode }}</b>
                    </span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </td>
            <td class="text-nowrap" scope="col">
              <input type="text" matInput placeholder="SKU Description" required />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Unit" required />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Quantity" style="width: 5rem !important;" required />
            </td>

            <td colspan="1">
              <input type="text" matInput placeholder="Price / Unit" />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Price" />
            </td>
            <td colspan="1">
              <input type="text" matInput required placeholder="%" />
            </td>
            <td colspan="1">
              <input type="text" matInput required placeholder="In AED" style="width: 40px;" />
            </td>

            <td>
              <input type="text" style="width: 5rem;" matInput placeholder="Amount" style="max-width: 150px;"
                required />
            </td>
            <td style="width: 3rem;"></td>
          </tr>
          <tr *ngFor="let item of productData;let i=index">
            <td style="vertical-align: middle;">{{ i + 1 }}</td>

            <td>
              {{ item.barcode }}
            </td>
            <td>{{ item.itemName | titlecase}}</td>
            <td class="text-center">
              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
            </td>

            <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('-',i)">-</a>
              <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;"
                required />
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('+',i)">+</a>
            </td>
            <td colspan="1">
              <p class="">
                AED&nbsp;{{ item.basePrice | number:'1.2-2'}}

              </p>
            </td>
            <td colspan="1">
              <p class="">
                AED&nbsp;{{ item.subTotal | number:'1.2-2'}}
              </p>
            </td>
            <td colspan="1">
              <p class="" style="width: 3rem;">
                {{ item.gstRate }}%
              </p>
            </td>
            <td colspan="1" style="max-width: 150px;">
              <p class="">
                AED&nbsp;{{ item.gstAmount | number:'1.2-2'}}
              </p>
            </td>

            <td style="max-width: 150px;">
              <p class="">
                AED&nbsp;{{ item.total | number:'1.2-2'}}
              </p>
            </td>
            <td>
              <button type="button" class="text-center btnb" style="width: 3rem!important;"
                (click)="changeQuantity('rm',i)">
                <span class="bi-trash fs-1" style="color: red;" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end">
      <div class="col-sm-6"></div>
      <div class="col-sm-4 table-bordered my-custom-scrollbar">
        <table class="table table-bordered  my-custom-scrollbar">
          <tr>
            <td class="thWidth p-2" style="text-align:left;">Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align:right;">
              AED&nbsp;{{ subTotal | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left;">{{lang.CGST}}</td>
            <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;" style="text-align:right;">
              AED&nbsp;{{ gstRate | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align:left;">Shipment Charges</td>
            <td class="p-2" style="text-align: right;">
              <mat-form-field style="font-size: 12px; width: 50%;display:flex;align-items: center;" class="p-0 m-0"
                appearance="outline">
                <span matPrefix>AED -</span>
                <input type="text" placeholder="Shipment Charges" matInput formControlName="shipmentCharges"
                  (keypress)="numberOnly($event)">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td class="thWidth  p-2" style="text-align:left;">Total</td>
            <td class="p-2" style="width: 50px;" style="text-align:right;">
              AED&nbsp;{{ ((total + (+SaleOrderForm.get('shipmentCharges').value)) || total) | number:'1.2-2' }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <label class="form-label">Purchase Type<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
          <mat-select placeholder="Purchase Type" formControlName="PurchaseType" (selectionChange)="getCredit($event)"
            [ngClass]="{ 'is-invalid': (f.PurchaseType.invalid && f.PurchaseType.touched) && submitted }">
            <mat-option value="false">Cash</mat-option>
            <mat-option value="true">Credit</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.PurchaseType.touched) && f.PurchaseType.errors?.required">
          Purchase Type Is Required.
        </mat-error>
      </div>

    </div>
    <div class="row mt-2">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <label class="form-label">Description</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput rows="5" placeholder="Description" formControlName="Description"
            maxlength="150"></textarea>
        </mat-form-field>
      </div>
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="{{imageSrc ? '':'dash'}} " style="height: 67% !important;margin-top: 30px;position: relative;">

          <div (click)="cancelImage()" *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold cancel-button"
            data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></div>

          <div *ngIf="imageSrc">
            <img class="m-2" [src]="imageSrc ? imageSrc : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
              class="img-thumbnail text-center" />
          </div>

          <p *ngIf="imageSrc" class="p-2">{{AddReceiptName}}</p>
          <div class="input--file pt-1">
            <span *ngIf="!imageSrc" class="fas fa-camera fs-1 mt-3" style="padding-left: 45%" (click)="startUpload()">
            </span>
            <input name="Select File" type="file" id="uploadFile" (change)="handleInputChange($event)" />
            <div *ngIf="!imageSrc" class="mt-1 pl-0">
              <h2 class="text-bold text-center">Add Receipt</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-template #addParty let-modal>
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
          aria-label="Close"></a>
      </div>
      <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h4 class="mb-1" id="modalExampleDemoLabel" style="font-size: 16px;font-weight:500;font-family: 'Poppins';">
            Add New Customer</h4>
        </div>
        <form [formGroup]="PartyForm">
          <div class="p-4 pb-0">
            <div class="form-check form-switch mb-0 lh-1 mb-3">
              <input class="form-check-input" type="checkbox" style="border:1px solid rgb(201, 199, 199);"
                [checked]="isShowGstFields" id="flexSwitchCheckDefault"
                (click)="customertoggleShow($event.target.checked)" />
              <label class="lab text-nowrap form-label p-1" style="font-size: 14px;"> {{ isShowGstFields ? 'As Customer' : 'As Vendor' }} </label>
            </div>

            <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">

              <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div><mat-label>{{ lang.GSTIN }}<span class="error-asterisk">*</span></mat-label></div>
                <mat-form-field class="example-full-width" appearance="outline">
                  <input type="text" matInput formControlName="gstIn" placeholder="{{lang.GSTIN }}" #input1
                    (keypress)="numberOnly($event)" maxlength="15" minlength="15"
                    (input)="validateTRN($event.target.value)"
                    [ngClass]="{'is-invalid': (pf.gstIn.invalid && pf.gstIn.touched) && submittedParty }" required>
                </mat-form-field>

                <mat-error class="text-danger" *ngIf="(submittedParty && pf.gstIn.errors?.required)">
                  {{ lang.GSTIN }} is Required.
                </mat-error>
                <mat-error class="text-danger" *ngIf="pf.gstIn.invalid && (pf.gstIn.dirty || pf.gstIn.touched)">
                  Please enter the valid {{ lang.GSTIN }} number
                </mat-error>
                <mat-error class="text-danger"
                  *ngIf="(submittedParty || fp.gstIn.touched) && fp.gstIn.errors?.maxlength">
                  {{ lang.GSTIN }} must be of 15-digits
                </mat-error>
                <mat-error class="text-danger" *ngIf="pf.gstIn.errors?.minlength">
                  {{ lang.GSTIN }} must be of 15-digits
                </mat-error>
                <mat-error class="text-danger" *ngIf="pf.gstIn.errors?.pattern">
                  {{ lang.GSTIN }} must be valid number
                </mat-error>
              </div>


            </div>

            <div class="row d-flex justify-content-start">
              <div style="font-size: 12px"  class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <label class="form-label">Customer Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                  <input matNativeControl required matInput placeholder="Customer Name" formControlName="partyName"
                    [ngClass]="{
                  'is-invalid': (fp.partyName.invalid && fp.partyName.touched) && submittedParty }">
                </mat-form-field>
                <!-- <div style="height: 48px !important;margin-bottom: 3px;"> -->
                <mat-error class="text-danger"
                  *ngIf="(submittedParty || fp.partyName.touched) && fp.partyName.errors?.required">
                  Customer Name Is Required.
                </mat-error>
              <!-- </div> -->

              </div>
              <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <label class="form-label">Mobile #<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                  <input type="text" placeholder="Mobile #" matInput formControlName="mobile" minlength="10"
                    maxLength="10" ngxOnlyNumbers [ngClass]="{
                    'is-invalid': (fp.mobile.invalid && fp.mobile.touched) && submittedParty }">
                </mat-form-field>

                <mat-error class="text-danger"
                  *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.pattern">
                  Enter a valid mobile #.
                </mat-error>
                <mat-error class="text-danger"
                  *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.required">
                  Mobile # is required.
                </mat-error>
                <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.maxlength">
                  Mobile # must be 10-digits.
                </mat-error>
                <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.minlength">
                  Mobile # must be of 10-digits.
                </mat-error>
              </div>
              <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                  <input type="email" placeholder="E-Mail" matInput formControlName="email" [ngClass]="{
                    'is-invalid': (fp.email.invalid && fp.email.touched) && submittedParty }">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.pattern">
                  Enter a valid email id.
                </mat-error>
                <mat-error class="text-danger"
                  *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.required">
                  Email id is required.
                </mat-error>
              </div>
            </div>

            <div class="row d-flex justify-content-start">
              <div class="col-lg-4 col-sm-12 col-md-6">
                <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                  <textarea matInput required formControlName="billingAddress" maxlength="150"
                    placeholder="Enter your address"
                    [ngClass]="{
                    'is-invalid': (fp.billingAddress.invalid && fp.billingAddress.touched) && submittedParty }"></textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submittedParty || fp.billingAddress.touched)">
                  <mat-error
                    *ngIf="(submittedParty || fp.billingAddress.touched) && fp.billingAddress.errors?.required">
                    Billing Address Is Required.
                  </mat-error>
                </mat-error>
              </div>

              <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                <label class="form-label">Shipping Address</label>
                <mat-form-field class="example-full-width" appearance="outline">
                  <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="form-check form-check-inline mb-2">
              <label class="form-check-label form-label fs--1" for="inlineCheckbox1"><span style="font-size: 14px;">Add
                  Shipping Address</span></label>
              <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                style="border:1px solid rgb(201, 199, 199) !important;"
                (change)="showShippingAddressinModal($event.target.checked)" />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
        <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add
        </button>
      </div>
    </div>
  </ng-template>

</div>
