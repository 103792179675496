import { Component, OnInit } from '@angular/core';

// import '../theme/public/assets/js/theme.js';
import { Router, NavigationEnd, NavigationStart, UrlTree } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DynamicScriptLoaderService } from './_helpers/DynamicScriptLoaderService';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { DeviceDetectorService } from "ngx-device-detector";
import { NotificationService } from './core/services/notification.service';
import { CommonService } from './core/services/common.service';
import { ToastNotificationService } from './core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';
import { AuthService } from './core/services/auth.service';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `nnnnnnnnnnnnn`,
  templateUrl: './app.component.html',

  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public title = 'Iceipts Application';
  public message: any = null;
  public topPosToStartShowing = 100;
  public deviceInfo;
  public currentPageNo: number = 0;
  public pageSize: number = 15;

  private excludedRoutes = ['/auth/role-selection', '/customer-display'];
  
  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private authService: AuthService
  ) {
    this.epicFunction();
    // this.initializeApp();
  }

  initializeApp() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentPath = event.urlAfterRedirects;

        // Store the current path in localStorage, excluding customer-display
        if (currentPath !== '/customer-display') {
          localStorage.setItem('currentRoute', currentPath);
        }

        console.log(currentPath)
        // Handle logic for customer display route or other routes
        // this.handleRouteLogic(currentPath);
      }
    });
  }

  ngBeViewInit() {
    // require('../theme/public/assets/js/theme.js');
  }
  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('themejs', 'bootstapminjs', 'a', 'b', 'c', 'd', 'e', 'g', 'h', 'i', 'j', 'k').then(data => {
      // Script Loaded Successfull
    }).catch(error => { });
  }

  loadScripts2() {
    const dynamicScripts = [
      '../theme/public/assets/js/theme.js',
      //'../theme/public/vendors/bootstrap/bootstrap.min.js' ,
      //  "../theme/public/vendors/popper/popper.min.js",
      //  "../theme/public/vendors/anchorjs/anchor.min.js",
      //  "../theme/public/vendors/is/is.min.js",
      //  "../theme/public/vendors/echarts/echarts.min.js",
      //  "../theme/public/vendors/fontawesome/all.min.js",
      //  "../theme/public/vendors/lodash/lodash.min.js",
      //  "https://polyfill.io/v3/polyfill.min.js?features=window.scroll",
      //  "../theme/public/vendors/list.js/list.min.js",
      //  "../theme/public/vendors/choices/choices.min.js",
      //  "../theme/public/assets/js/flatpickr.js",
      //  "../theme/public/vendors/list.js/list.min.js",

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  async ngOnInit() {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // new code
    //     // this.loadScripts2()
    //     // this.loadScripts2()
    //   }
    // });
    this.requestPermission();
    this.listen();
    if (this.currentUser) {
      this.getAllNotificationByUserId();
    }

    //scroll view to tp on route change
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.loadScripts2()
        localStorage.setItem('currentRoute', evt.urlAfterRedirects);
      }else  if (event instanceof NavigationStart) {
        const urlTree: UrlTree = this.router.parseUrl(event.url);
        const currentRoute = event.url;
        const queryParams = urlTree.queryParams;

        // Check if the route or query parameters should be excluded
        if (this.isExcludedRoute(currentRoute, queryParams)) {
          return;
        }

        // const currentRoute = event.url;
        // console.log("currentRoutecurrentRoute",currentRoute)
        // Check if the route is excluded from the authentication check
        if (this.excludedRoutes.includes(currentRoute)) {
          return;
        }

        // Handle authentication check
        if (this.authService.isLoggedIn()) {
          const savedRoute = localStorage.getItem('currentRoute');
          if (savedRoute) {
            this.router.navigateByUrl(savedRoute);
          } else {
            let currentUser = this.authService.getCurrentUser();
            let userType = currentUser.userType;

            if (userType.toLowerCase() === 'client') {
              if (currentUser.flag) {
                this.router.navigate(["/pages/dashboard"]);
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Login Successfully!",
                });
              } else {
                this.router.navigate(["/pages/profile-settings"]);
              }
            } else if (userType.toLowerCase() === 'warehouse') {
              if (currentUser.flag) {
                this.router.navigate(["/pages/warehouse-landing/dashboard"]);
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Login Successfully!",
                });
              } else {
                this.router.navigate(["/pages/warehouse-landing/warehouse-profile-setting/warehouse-profile-setting"]);
              }
            } else if (userType.toLowerCase() === 'shop') {
              if (currentUser.flag) {
                this.router.navigate(["/pages/shop-landing/dashboard"]);
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Login Successfully!",
                });
              } else {
                this.router.navigate(["/pages/shop-landing/shop-profile-setting"]);
              }
            } else if (userType === 'SA') {
              this.router.navigate(['super-admin/']);
            } else if (userType === 'EMPLOYEE') {
              this.router.navigate(['/pages/dashboard']);
              // this.router.navigate(['/pages/employee-work/Profile-employee/emp-profile'])
            }
          }
          return
        } else {
          this.router.navigateByUrl("/auth/role-selection");
          return
        }
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      document.body.scrollTop = 0;


      
    });

    // const customerDisplayRoute = localStorage.getItem('customerDisplayRoute');
    //   const isCustomerDisplay = localStorage.getItem('isCustomerDisplay');
    //   // const url = new URL(window.location.href);
    //   // const path = url.pathname;

    //   console.log(isCustomerDisplay)
    //   if (savedRoute && customerDisplayRoute && !customerDisplayRoute) {
    //     console.log("in normal current route setting||||||||||||||||||||||||")
    //     this.router.navigateByUrl(savedRoute);
    //     localStorage.setItem('isCustomerDisplay', 'false')
    //   } else if(customerDisplayRoute === '/customer-display' && customerDisplayRoute) {
    //     console.log("in customer route setting>>>>>>>>>>>>>>>>>>>>>>>>")
    //     this.router.navigateByUrl('/customer-display');
    //     // localStorage.removeItem('customerDisplayRoute');
    //     localStorage.setItem('isCustomerDisplay', 'true')
    //   } else {

   
    
    // this.router.events.subscribe((event) => {
    
    // });


    // if (this.authService.isLoggedIn()) {
    //   const savedRoute = localStorage.getItem('currentRoute');
    //   if (savedRoute) {
    //     this.router.navigateByUrl(savedRoute);
    //   } else {
    //     let currentUser = this.authService.getCurrentUser()
    //     let userType = currentUser.userType
    //     if (userType.toLowerCase() === 'client') {
    //       if (currentUser.flag) {
    //         this.router.navigate(["/pages/dashboard"]);
    //         this.toastService.toastMsg({
    //           title: "Success",
    //           content: "Login Successfully!",
    //         });
    //       } else {
    //         this.router.navigate(["/pages/profile-settings"]);
    //       }
    //     } else if (userType.toLowerCase() === 'warehouse') {
    //       if (currentUser.flag) {
    //         this.router.navigate(["/pages/warehouse-landing/dashboard"]);
    //         this.toastService.toastMsg({
    //           title: "Success",
    //           content: "Login Successfully!",
    //         });
    //       } else {
    //         this.router.navigate(["/pages/warehouse-landing/warehouse-profile-setting/warehouse-profile-setting"]);
    //       }
    //     } else if (userType.toLowerCase() === 'shop') {
    //       if (currentUser.flag) {
    //         this.router.navigate(["/pages/shop-landing/dashboard"]);
    //         this.toastService.toastMsg({
    //           title: "Success",
    //           content: "Login Successfully!",
    //         });
    //       } else {
    //         this.router.navigate(["/pages/shop-landing/shop-profile-setting"]);
    //       }
    //     } else if (userType === 'SA') {
    //       this.router.navigate(['super-admin/'])
    //     } else if (userType === 'EMPLOYEE') {
    //       this.router.navigate(['/pages/dashboard'])
    //       // this.router.navigate(['/pages/employee-work/Profile-employee/emp-profile'])
    //     }
    //   }

    //   // this.router.navigate(["/pages/dashboard"]);
    // } else {
    //   this.router.navigateByUrl("/auth/role-selection");
    // }

  }

  private isExcludedRoute(route: string, queryParams: any): boolean {
    // Check if the route is in the excludedRoutes list or contains the email query parameter
    return this.excludedRoutes.includes(route) || queryParams.email;
  }
  
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            this.postFcm(currentToken)
          } else {

          }
        }).catch((err) => {
        });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message = payload;
    });
  }
  postFcm(token) {
    let data = {
      "fcm_key": token,
      "device_id": this.currentUser.id,
      "device_type": this.deviceInfo.deviceType,
      "userId": this.currentUser.id ? this.currentUser.id : "",
    }
    this.notificationService.postFcm(data).subscribe((res) => {
    })
  }
  /**
   * get notification by userId
   */
  getAllNotificationByUserId() {
    this.notificationService.getAllNotification({}, this.currentUser.id, this.pageSize, (this.currentPageNo + 1)).then((res) => {
      if (res) {
        this.commonService.sendData(res.data);
      }
    }
      , (err) => {
        if (err.error.expose) {
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      })
  }

  // init(editor: any): void {
  //   editor.ui
  //     .getEditableElement()
  //     .parentElement.insertBefore(
  //       editor.ui.view.toolbar.element,
  //       editor.ui.getEditableElement()
  //     );
  // }

}
