<div class="widget">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Credit Vendor Name</mat-card-title>

      <h5 class="receivable-Payment">Receivables</h5>
    </mat-card-header>

    <mat-card-content>
      <div class="row" *ngFor="let item of creditorsPartyList | slice : 0 : 5">
        <div class="col-sm-6 col-md-6 p-2 ps-4 text-left">
          <h3 class="card-title partyName mb-0">
            {{ item.partyName }}
          </h3>
        </div>
        <div class="col-sm-6 col-md-6 p-2 pe-3 text-right">
          <span class="green-digit"
            >AED&nbsp;{{ item.totalCredit | number : "1.2-2" }}</span
          >
        </div>
      </div>
      <div
        *ngIf="creditorsPartyList.length === 0"
        class="creditorsParty-notFound"
      >
        <img
          class="image-responsive"
          src="../../../assets/img/icons/spot-illustrations/notfound1.png"
          alt="notfound1"
          width="200"
          height="150"
        />
      </div>
    </mat-card-content>
  </mat-card>
</div>
