import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { PagesRoutingModule } from './pages-routing.module';
import { ProfileComponent } from './Profile/profile/profile.component';
import { ProfileSettingsComponent } from './Profile/profile-settings/profile-settings.component';
import { CustomerListComponent } from './Customer/customer-list/customer-list.component';
import { CustomerDetailsComponent } from './Customer/customer-details/customer-details.component';
import { ProductListComponent } from './Product/product-list/product-list.component';
import { ProductGridComponent } from './Product/product-grid/product-grid.component';
import { ChatsComponent } from './chats/chats.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProductAddComponent } from './Product/product-add/product-add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddCustomRolesComponent } from './add-custom-roles/add-custom-roles.component';
import { AddRoleBasedUsersComponent } from './add-role-based-users/add-role-based-users.component';
import { HttpClientModule } from '@angular/common/http';
import { EmployeeRolesComponent } from './Employees/employee-roles/employee-roles.component';
import { RolesListComponent } from './roles-list/roles-list.component';
import { EditPermissionsComponent } from './edit-permissions/edit-permissions.component';
import { HasPermissionDirective } from '../directive/has-permission.directive';
import { PagesComponent } from './pages.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PartyStatementComponent } from './party-statement/party-statement.component';
import { EstimateComponent } from './Invoice/estimate/estimate.component';
import { SubscriptionComponent } from '../auth/subscription/subscription.component';
import { PaymentSubscriptionComponent } from '../auth/payment-subscription/payment-subscription.component';
import { PartyWiseProfitLossComponent } from './party-wise-profit-loss/party-wise-profit-loss.component';
import { AllPartiesComponent } from './all-parties/all-parties.component';
import { PartyReportByItemComponent } from './party-report-by-item/party-report-by-item.component';
import { SalePurchaseByPartyComponent } from './sale-purchase-by-party/sale-purchase-by-party.component';
import { SalePurchaseByPartyGroupComponent } from './sale-purchase-by-party-group/sale-purchase-by-party-group.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SaleSummaryComponent } from './sale-summary/sale-summary.component';
import { GstReportComponent } from './gst-report/gst-report.component';
import { GstReport2Component } from './gst-report2/gst-report2.component';
import { GstReport3BComponent } from './gst-report3-b/gst-report3-b.component';
import { GstReport9Component } from './gst-report9/gst-report9.component';
import { GstTaxReportComponent } from './gst-tax-report/gst-tax-report.component';
import { GstTaxRateReportComponent } from './gst-tax-rate-report/gst-tax-rate-report.component';
import { GstFormNoComponent } from './gst-form-no/gst-form-no.component';
import { TcsReceivableComponent } from './tcs-receivable/tcs-receivable.component';
import { CustomerDashboardComponent } from './Customer/customer-dashboard/customer-dashboard.component';
import { CustomerProfileComponent } from './Customer/customer-profile/customer-profile.component';
import { CustomerProfileSettingComponent } from './Customer/customer-profile-setting/customer-profile-setting.component';
import { ItemDetailComponent } from './item-detail/item-detail.component';
import { ItemReportByPartyComponent } from './item-report-by-party/item-report-by-party.component';
import { ItemSerialReportComponent } from './item-serial-report/item-serial-report.component';
import { ItemWiseDiscountComponent } from './item-wise-discount/item-wise-discount.component';
import { ItemWiseProfitAndLossComponent } from './item-wise-profit-and-loss/item-wise-profit-and-loss.component';
import { LowStockSummaryComponent } from './low-stock-summary/low-stock-summary.component';
import { ProductUpdateComponent } from './Product/product-update/product-update.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ProductServiceListComponent } from './Product/product-service-list/product-service-list.component';
import { ProductServiceUpdateListComponent } from './Product/product-service-update-list/product-service-update-list.component';
import { StockDetailComponent } from './stock-detail/stock-detail.component';
import { StockSummaryComponent } from './stock-summary/stock-summary.component';
import { PaymentInComponent } from './payment-in/payment-in.component';
import { PaymentOutComponent } from './payment-out/payment-out.component';
import { TextMaskModule } from 'angular2-text-mask';
import { RemoteCustomerComponent } from './RemoteShopping/RemoteCustomer/remote-customer/remote-customer.component';
import { LayoutCustomerComponent } from './layout-customer/layout-customer.component';
import { ViewProductComponent } from './RemoteShopping/RemoteCustomer/view-product/view-product.component';
import { RemoteCartComponent } from './RemoteShopping/RemoteCustomer/remote-cart/remote-cart.component';
import { InvoiceNumberComponent } from './invoice-number/invoice-number.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HeaderModule } from '../widegts-library/header/header.module';
import { SliderModule } from '../widegts-library/slider/slider.module';
import { ImageModule } from '../widegts-library/image/image.module';
import { FeatureProductModule } from '../widegts-library/feature-product/feature-product.module';
import { FooterModule } from '../widegts-library/footer/footer.module';
import { GallerySliderModule } from '../widegts-library/gallery/gallery.module';
import { SectionModule } from '../widegts-library/section/section.module';
import { TestimonialModule } from '../widegts-library/testimonial/testimonial.module';
import { ScheduleComponent } from './schedule/schedule.component';
import { UpiPaymentComponent } from './Invoice/upi-payment/upi-payment.component';
import { CustomerAppointmentComponent } from './Customer/customer-appointment/customer-appointment.component';
import { BookAppointmentComponent } from './Customer/book-appointment/book-appointment.component';
import { BookAppointmentTimeComponent } from './Customer/book-appointment-time/book-appointment-time.component';
import { AdminComponent } from './schedule/compnnent/admin/admin.component';
import { DashboardComponent } from './schedule/compnnent/dashboard/dashboard.component';
import { ClientDashboardComponent } from './dashboard/dashboard.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PracticeAvailabiltyComponent } from './schedule/compnnent/admin/component/practice-availabilty/practice-availabilty.component';
import { AppointmentTypeComponent } from './schedule/compnnent/admin/component/appointment-type/appointment-type.component';
import { ManageCalenderComponent } from './schedule/compnnent/admin/component/manage-calender/manage-calender.component';
import { AutomationComponent } from './schedule/compnnent/admin/component/automation/automation.component';
import { AppointementTypeFComponent } from './schedule/compnnent/admin/component/appointement-type-f/appointement-type-f.component';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
]);
import { MaterialExampleModule } from '../material.module';
import { WidgetsLayoutComponent } from './widgets-layout/widgets-layout.component';
import { CheckoutComponent } from './RemoteShopping/RemoteCustomer/checkout/checkout.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GstReport4Component } from './gst-report4/gst-report4.component';
import { GstReport5Component } from './gst-report5/gst-report5.component';
import { GstReport8Component } from './gst-report8/gst-report8.component';
import { GstReport2AComponent } from './gst-report2-a/gst-report2-a.component';
import { CashInHandComponent } from './cash-in-hand/cash-in-hand.component';
import { RemoteRetailerComponent } from './RemoteShopping/RemoteRetailer/remote-retailer/remote-retailer.component';
import { ShopPageComponent } from './shop/shop-page/shop-page.component';
import { ShopEditPageComponent } from './shop/component/shop-edit-page/shop-edit-page.component';
import { ShopappointmentComponent } from './shop/component/shopappointment/shopappointment.component';
import { ShopScheduleComponent } from './shop/component/shop-schedule/shop-schedule.component';
import { ShopEmployeeComponent } from './shop/component/shop-employee/shop-employee.component';
import { ShopSettingComponent } from './shop/component/shop-setting/shop-setting.component';
import { ShopappointFormComponent } from './shop/component/shopappoint-form/shopappoint-form.component';
import { ShopSFormComponent } from './shop/component/shop-sform/shop-sform.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ScheduleShopFormPageComponent } from './shop/component/schedule-shop-form-page/schedule-shop-form-page.component';
import { BookAppointmentShopComponent } from './Customer/book-appointment-shop/book-appointment-shop.component';
import { ReceipFormatComponent } from './popup/receip-format/receip-format.component';
import { GeneratedOrderReceiptsComponent } from './RemoteShopping/RemoteRetailer/generated-order-receipts/generated-order-receipts.component';
import { OrderReceiptsComponent } from './RemoteShopping/RemoteCustomer/order-receipts/order-receipts.component';
import { BiddingComponent } from './RemoteShopping/RemoteCustomer/bidding/bidding.component';
import { ViewProductListComponent } from './RemoteShopping/RemoteCustomer/view-product-list/view-product-list.component';
import { BiddingDetailsComponent } from './RemoteShopping/RemoteCustomer/bidding-details/bidding-details.component';
import { PopupComponent } from './popup/popup.component';
import { WarningComponent } from './popup/warning/warning.component';
import { ItemBatchReportComponent } from './item-batch-report/item-batch-report.component';
import { SellerBidComponent } from './RemoteShopping/RemoteRetailer/seller-bid/seller-bid.component';
import { PlaceOrderComponent } from './RemoteShopping/RemoteCustomer/place-order/place-order.component';
import { PayMentInfoComponent } from './RemoteShopping/RemoteCustomer/pay-ment-info/pay-ment-info.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MatInputModule } from '@angular/material/input';
import { ContactListTicketComponent } from './contact-list-ticket/contact-list-ticket.component';
import { AmountPipePipe } from './pipes/amount-pipe.pipe';
import { OnlyNumberDirective } from '../directive/only-number.directive';
import { OnlyCharactersDirective } from '../directive/only-characters.directive';
import { OnlyEmailDirective } from '../directive/only-email.directive';
import { ProductDetailsComponent } from './RemoteShopping/RemoteCustomer/product-details/product-details.component';
import { EmployeeDetailsComponent } from './Employee/employee-details/employee-details.component';
import { UpdateshopappointmentComponent } from './shop/component/updateshopappointment/updateshopappointment.component';
import { MatSelectModule } from '@angular/material/select';
//import { ExpenseNewComponent } from './expense/expense-new/expense-new.component';
// { ExpenseListComponent } from './expense/expense-list/expense-list.component';
//import { ExpenseRecureListComponent } from './expense/expense-recure-list/expense-recure-list.component';
// import { ExpneseComponent } from './expnese/expnese.component';
import { EditBusinessPartnerModalComponent } from './Profile/profile-settings/edit-business-partner-modal/edit-business-partner-modal.component';
import { AddNewTradeComponent } from './Profile/add-new-trade/add-new-trade.component';
import { LayoutEmployeeComponent } from './layout-employee/layout-employee.component';
import { EmployeedashboardComponent } from './employeedashboard/employeedashboard.component';
import { PayrollComponent } from './payroll/payroll.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ApplyCouponComponent } from './Invoice/sales-invoice/apply-coupon/apply-coupon.component';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
// import { ApplyCouponComponent } from './invoice/sales-invoice/apply-coupon/apply-coupon.component';
import { ChartModule } from 'angular-highcharts';
import { DailyReportPageComponent } from './daily-report-page/daily-report-page.component';
import { EmployeeComponent } from './Employee/employee.component';
import { CloseRegisterComponent } from './close-register/close-register.component';
import { TestComponent } from './test/test.component';
import { SwitchUserComponent } from './switch-user/switch-user.component';

@NgModule({
  declarations: [
    PagesComponent,
    LayoutComponent,
    ProfileComponent,
    ProfileSettingsComponent,
    CustomerListComponent,
    CustomerDetailsComponent,
    ProductGridComponent,
    ChatsComponent,
    ProductAddComponent,
    NotificationsComponent,
    AddCustomRolesComponent,
    RolesListComponent,
    EditPermissionsComponent,
    // HasPermissionDirective,
    OnlyNumberDirective,
    OnlyCharactersDirective,
    OnlyEmailDirective,
    PartyStatementComponent,
    EmployeeComponent,
    PartyWiseProfitLossComponent,
    AllPartiesComponent,
    PartyReportByItemComponent,
    SalePurchaseByPartyComponent,
    SalePurchaseByPartyGroupComponent,
    SaleSummaryComponent,
    GstReportComponent,
    GstReport2Component,
    GstReport3BComponent,
    GstReport9Component,
    GstTaxReportComponent,
    GstTaxRateReportComponent,
    GstFormNoComponent,
    TcsReceivableComponent,
    CustomerDashboardComponent,
    CustomerProfileComponent,
    CustomerProfileSettingComponent,
    ItemDetailComponent,
    ItemReportByPartyComponent,
    ItemSerialReportComponent,
    ItemWiseDiscountComponent,
    ItemWiseProfitAndLossComponent,
    LowStockSummaryComponent,
    ProductUpdateComponent,
    ProductServiceListComponent,
    ProductServiceUpdateListComponent,
    StockDetailComponent,
    StockSummaryComponent,
    RemoteCustomerComponent,
    LayoutCustomerComponent,
    ViewProductComponent,
    RemoteCartComponent,
    InvoiceNumberComponent,
    WidgetsLayoutComponent,
    ScheduleComponent,
    UpiPaymentComponent,
    CustomerAppointmentComponent,
    BookAppointmentComponent,
    BookAppointmentTimeComponent,
    AdminComponent,
    DashboardComponent,
    PracticeAvailabiltyComponent,
    AppointmentTypeComponent,
    ManageCalenderComponent,
    AutomationComponent,
    AppointementTypeFComponent,
    GstReport4Component,
    GstReport5Component,
    GstReport8Component,
    GstReport2AComponent,
    CheckoutComponent,
    CashInHandComponent,
    RemoteRetailerComponent,
    ShopPageComponent,
    ShopEditPageComponent,
    ShopappointmentComponent,
    ShopScheduleComponent,
    ShopEmployeeComponent,
    ShopSettingComponent,
    ShopappointFormComponent,
    ShopSFormComponent,
    ScheduleShopFormPageComponent,
    BookAppointmentShopComponent,
    ReceipFormatComponent,
    GeneratedOrderReceiptsComponent,
    OrderReceiptsComponent,
    BiddingComponent,
    ViewProductListComponent,
    BiddingDetailsComponent,
    PopupComponent,
    WarningComponent,
    ItemBatchReportComponent,
    SellerBidComponent,
    PlaceOrderComponent,
    PayMentInfoComponent,
    ContactUsComponent,
    ClientDashboardComponent,
    ContactListTicketComponent,
    AmountPipePipe,
    ProductDetailsComponent,
    UpdateshopappointmentComponent,
    //ExpenseNewComponent,
    //ExpenseListComponent,
    // ExpenseRecureListComponent,
    // ExpneseComponent
    // AmountFormatPipe,
    EditBusinessPartnerModalComponent,
    AddNewTradeComponent,
    LayoutEmployeeComponent,
    EmployeedashboardComponent,
    ApplyCouponComponent,
    DailyReportPageComponent,
    TestComponent,
    CloseRegisterComponent,
    SwitchUserComponent,
  ],
  // providers: [{provide: 'Shared', useValue: 'Shared'}],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    BsDatepickerModule.forRoot(),
    AutocompleteLibModule,
    TextMaskModule,
    FullCalendarModule,
    MatSlideToggleModule,
    DragDropModule,
    HeaderModule,
    SliderModule,
    ImageModule,
    FeatureProductModule,
    GallerySliderModule,
    SectionModule,
    TestimonialModule,
    FooterModule,
    MaterialExampleModule,
    MatInputModule,
    NgbModule,
    NgxSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    SharedModule,
    DashboardModule,
    ChartModule
    // ProductModule
  ],
  exports: [
    LayoutComponent,
    HasPermissionDirective, // This can now be moved to SharedModule
    OnlyNumberDirective,
    OnlyCharactersDirective,
    OnlyEmailDirective,
    SharedModule

    // CommonModule,
    // FormsModule,
    // ReactiveFormsModule,
    // MatInputModule,
    // MatButtonModule,
    // MatIconModule,
    // MatNativeDateModule,
    // MatFormFieldModule,
    // MatDatepickerModule,
    // MatMomentDateModule,
    // DragDropModule,
    // NgbModule,
    // BsDatepickerModule,
    // ChartModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CurrencyPipe, TitleCasePipe, NgbActiveModal],
})
export class PagesModule {}
