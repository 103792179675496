<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
      <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
        Item Details
      </div>
      <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
        <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>
        <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
        </button>
      </div>
  </div>
  <hr class="mt-1" />
  <div class="fs--1">
    <div class="row mb-2">
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "dateRangePrintHide">
        <label class="form-label">Date</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
            bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
            [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
          <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
        </mat-form-field>
      </div>
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mr-0" id = "searchBarHide">
        <label class="form-label">Select Product Name</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" placeholder="Product Name" (input)="searchProduct()" aria-label="Select Party Name"
            matInput [formControl]="myControl" [matAutocomplete]="auto">
          <mat-icon class="m-2" matSuffix>search</mat-icon>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.itemName"
              (onSelectionChange)="selectedProduct(option.itemName)">
              {{ option.itemName | titlecase }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive scrollbar"  id="print">
      <table class="table table-bordered table-striped fs--1 mb-3" id="excel-table">
        <thead class="" style="background-color: #F0F0F0;">
          <tr class="text-900">
            <th class="text-nowrap text-center">Sales Quantity</th>
            <th class="text-nowrap text-center">Purchase Quantity</th>
            <th class="text-nowrap text-center">Adjustment Quantity</th>
            <th class="text-nowrap text-center">Closing Quantity</th>
          </tr>
        </thead>
        <tbody  *ngIf="itemDataDetailsApidata">
          <tr class="btn-reveal-trigger text-left">
          <td class="text-center">{{saleQuantity}}</td>
          <td class="text-center">{{purchaseQuantity}}</td>
          <td class="text-center">{{returnQuantity}}</td>
          <td class="text-center">{{closingQunatity}}</td>   
          </tr>
        </tbody>
        <tbody *ngIf="!itemDataDetailsApidata">
          <tr class="text-center">
            <td colspan="10">
              <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"  width="200" height="150">            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>






  