<div class="modal-header pt-0" id="Add-Party-modal">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:11px;" class="modal-title mb-0">Add {{ isShown ?  "Customer" :"Vendor" }}
    </h1>
    <div class="addProductButtons">
        <button style="margin-right: 10px;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
            <ng-container *ngIf="isVendor; else customer">
                <button type="submit" class="btn btn-save" [disabled]="isSaveButtonDisabled" *appHasPermission="['create']; name 'vendor'"
                    (click)="postPartyDetails()">Save</button>
            </ng-container>
            <ng-template #customer>
                <button type="submit" class="btn btn-save" [disabled]="isSaveButtonDisabled" *appHasPermission="['read']; name 'customer'"
                    (click)="postPartyDetails()">Save</button>
            </ng-template>
    </div>
</div>
<div class="modal-body">
    <div class="container-fluid px-2">
        <div class="toggle-container container-fluid d-flex justify-content-center" style="align-items: flex-start;">
            <div class="lable-container container d-flex justify-content-end"
                style="font-size: 14px;align-items: flex-start;" [class.bold]="!isOn">Vendor</div>
            <input class="input-container container" type="checkbox" [(ngModel)]="isOn" [checked]="isShown" id="switch"
                (click)="toggleShow($event.target.checked)" />
            <label for="switch" class="small-toggle container"></label>
            <div class="lable-container container d-flex justify-content-start"
                style="font-size: 14px;align-items: flex-start;" [class.bold]="isOn">Customer</div>
        </div>
        <form [formGroup]="PartyForm" (ngSubmit)="postPartyDetails()" *ngIf="isShown">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Customer Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Customer Name" ngxOnlyString
                                formControlName="partyName" maxlength="50"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Customer Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" required matInput placeholder="E-Mail" formControlName="email" maxlength="50"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern">
                            Enter a valid E-Mail.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid E-Mail.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput placeholder="Mobile #" formControlName="mobile"
                                minlength="10" maxLength="10" ngxOnlyNumbers autocomplete="off" required
                                [ngClass]="{'is-invalid':f.mobile.invalid && f.mobile.touched && submitted}" />
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Mobile Number should be 10 digits.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Mobile Number should be 10 digits.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6" *appHasPermission="['read']; name 'vendorgroup'">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="partyGrpArr" multiple
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" (keypress)="numberOnly($event)"
                                formControlName="openingBalance" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <label for="asOfDate" class="form-label">As Of Date</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY" [(ngModel)]="todaysDate"
                            style="text-transform: uppercase" id="As On Date" [outsideClick]="true"
                            bsDatepicker [maxDate]="today" [bsConfig]="{
                                            dateInputFormat: 'DD MMM YYYY',
                                            containerClass: 'theme-dark-blue',
                                            showWeekNumbers: false,
                                            displayOneMonthRange: true
                                        }" autocomplete="off" (click)="openDatepicker()" />
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label class="form-label mb-2">Payment Terms</label>
                <div class="col-sm-12 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style="font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style="font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label for="billingAddress" class="form-label">Billing Address <span
                                class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput id="billingAddress" formControlName="billingAddress" required rows="2" maxlength="150"
                                placeholder="Billing Address" [ngClass]="{
                                                'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted
                                            }" autocomplete="off"></textarea>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                            Enter a valid Billing Address.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="country"
                                placeholder="Country" autocomplete="off" panelClass="custom-mat-select-panel">
                                <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{
                                    country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.country.touched) &&f.country.errors?.required">
                            Enter a valid Country.
                        </mat-error>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="mb-3 example-full-width" appearance="outline">
                            <input matInput type="text" id="pincode" placeholder="Pincode"formControlName="pincode"
                                autocomplete="off" (keypress)="numberOnly($event)" maxlength="5" minlength="5" >
                        </mat-form-field>

                    </div>
                </div>
            </div>

            <div class="row">
                <!-- <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City <span class="error-asterisk">*</span></label>
                        <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                            <input matInput type="text" id="city" formControlName="city" placeholder="City" (keypress)="acceptString($event)"
                                autocomplete="off"
                                [ngClass]="{'is-invalid': f.city.invalid && f.city.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || f.city.touched) &&f.city.errors?.required">
                            Enter a valid City.
                        </mat-error>
                    </div>
                </div> -->
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City <span class="error-asterisk">*</span></label>
                        <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                            <mat-select required formControlName="city" name="pintxt" id="pintxt" placeholder="city"
                                [ngClass]="{
                                'is-invalid': (f.city.invalid && f.city.touched) && submitted }">
                            <mat-option *ngFor="let city of city" [value]="city">{{ city }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.city.touched) &&f.city.errors?.required">
                            Enter a valid City.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row" role="tabpanel" aria-labelledby="form-wizard-progress-tab3" id="form-wizard-progress-tab3">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" placeholder="Enter Credit Period Days"
                            [(ngModel)]="creditPeriodValue" (keydown)="preventLetterE($event)" formControlName="creditPeriod" min="0" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span class="pe-2" style="font-size: 12px;" matPrefix>AED </span>
                                <input matInput class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" maxlength="10"  (keypress)="numberOnly($event)"  autocomplete="off"/>
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number"
                                formControlName="noOfCredit" min="0" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" autocomplete="off" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required number.
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="relationSince"
                                placeholder="Relationship Since">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance (In Km)</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="number" (keypress)="numberOnly($event)" matInput placeholder="Distance In Km"
                                formControlName="distance" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transportation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Ex.Truck - MH01OO1234" formControlName="transporation"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2" style="vertical-align: middle;">Department</th>
                                        <th class="p-2" style="vertical-align: middle;">Contact</th>
                                        <th class="p-2" style="vertical-align: middle;">E-Mail</th>
                                        <th class="p-2" style="vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails"
                                        *ngFor="let group of additionalDetailsControls.controls; let i = index">
                                        <tr [formGroupName]="i">
                                            <td style="vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="10" maxLength="10"
                                                    ngxOnlyNumbers formControlName="additionalMobile" />
                                                    <div *ngIf="(submitted || group.get('additionalMobile').touched) &&  group.get('additionalMobile').errors?.minlength">
                                                        <small class="text-danger" >Mobile Number should be 10 digits.
                                                        </small>
                                                      </div>
                                                      <div *ngIf="(submitted || group.get('additionalMobile').touched) &&  group.get('additionalMobile').errors?.maxlength">
                                                        <small class="text-danger" >Mobile Number should be 10 digits.
                                                        </small>
                                                      </div>
                                                      <div *ngIf="(submitted || group.get('additionalMobile').touched) &&  group.get('additionalMobile').errors?.pattern">
                                                        <small class="text-danger" >  Invalid mobile format.</small>
                                                      </div>
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()" />
                                                    <div *ngIf="(submitted || group.get('additionalContact').touched) &&  group.get('additionalContact').errors?.pattern">
                                                        <small class="text-danger" >Enter a valid E-Mail.</small>
                                                      </div>
                                             
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <a class="btn" (click)="deleteaddAddtionalDetails(i)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                    <!-- Delete -->
                                                </a>
                                            </td>
                                        </tr>
                                   <!-- Error messages for additionalMobile -->
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No {{isVendor?"Vendor" : "Customer"}} Details Added. Please Add
                                                {{isVendor?"Vendor" : "Customer"}}
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        {{isVendor?"Vendor" : "Customer"}}
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>

        <form [formGroup]="PartyForm" *ngIf="!isShown">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Vendor Name" ngxOnlyString autocomplete="off"
                                formControlName="partyName" maxlength="50"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Vendor Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" formControlName="email" maxlength="100"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}"
                                autocomplete="off" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern">
                            Enter a valid E-Mail.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid E-Mail.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="mobileNo" class="form-label">Mobile #<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput placeholder="Mobile #" formControlName="mobile"
                                autocomplete="off" minlength="10" maxLength="10" ngxOnlyNumbers
                                [ngClass]="{'is-invalid':f.mobile.invalid && f.mobile.touched && submitted}" />
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Mobile Number Must be 10 digits.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Mobile Number Must be 10 digits.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6" *appHasPermission="['read']; name 'vendorgroup'">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="partyGrpArr" multiple [(value)]="selectedClient"
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label w-100">{{ lang.GSTIN }}<span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="outline" style="min-width: calc(85% + 13px)!important;">
                            <input matNativeControl formControlName="gstIn" maxlength="15" minlength="15"
                                autocomplete="off" (keypress)="numberOnly($event)" maxlength="15"
                                (keyup)="validateTRN($event.target.value)" placeholder="{{ lang.GSTIN }}"
                                style="text-transform: uppercase"
                                [ngClass]="{'is-invalid': f.gstIn.invalid && f.gstIn.touched && submitted}" required />
                        </mat-form-field>
                        <mat-icon *ngIf="PartyForm.controls.gstIn.valid"
                            [ngClass]="{ 'gst-status-icon': true, 'active': !isDisabledGST }">
                            {{ isDisabledGST ? '✗' : '✓' }}
                        </mat-icon>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstIn.touched) && f.gstIn.errors?.required">
                            {{lang.GSTIN }} required with exactly 15 characters.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="f.gstIn.errors?.minlength">
                            Enter a valid {{lang.GSTIN }} with exactly 15 characters.
                        </mat-error>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" autocomplete="off"
                                (keypress)="numberOnly($event)" formControlName="openingBalance" maxlength="10"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="asOfDate" class="form-label">As Of Date</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY"
                                style="text-transform:uppercase!important;" [outsideClick]="true" bsDatepicker
                                [maxDate]="today"
                                [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }"
                                (click)="openDatepicker()">
                            <span>
                                <mat-icon class="iconcalender">date_range</mat-icon>
                            </span>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <label class="form-label">Payment Terms</label>
                <div class="col-sm-6 col-lg-6 col-md-6  mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style="font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6  mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style="font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label for="billingAddress" class="form-label">Billing Address<span
                                class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput id="billingAddress" formControlName="billingAddress" required rows="2" maxlength="150"
                                placeholder="Billing Address" [ngClass]="{
                                        'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted
                                    }" autocomplete="off"></textarea>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                            Enter a valid Billing Address.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country<span class="error-asterisk">*</span></label>
                        <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                            <mat-select id="country" matNativeControl disableOptionCentering  formControlName="country" placeholder="Country" autocomplete="off">
                                <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{
                                    country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.country.touched) &&f.country.errors?.required">
                            Enter a valid Country.
                        </mat-error>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="pincode" formControlName="pincode"  maxLength="5" minlength="5"
                                autocomplete="off" placeholder="Pincode"  (keypress)="numberOnly($event)"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="city" formControlName="city"
                                autocomplete="off" placeholder="City" maxlength="20" (keypress)="acceptString($event)" />
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || f.city.touched) &&f.city.errors?.required">
                            Enter a valid City.
                        </mat-error>
                    </div>
                </div> -->
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select required formControlName="city" name="pintxt" id="pintxt" placeholder="city"
                                [ngClass]="{
                                'is-invalid': (f.city.invalid && f.city.touched) && submitted }">
                            <mat-option *ngFor="let city of city" [value]="city">{{ city }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.city.touched) &&f.city.errors?.required">
                            Enter a valid city.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" min="0" placeholder="Enter Credit Period Days" class=""
                                formControlName="creditPeriod" autocomplete="off"  (keypress)="numberOnly($event)" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span class="pe-2" style="font-size: 12px;" matPrefix>AED</span>
                                <input matInput  class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" (keypress)="numberOnly($event)"  maxlength="10" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number" min="0"
                                formControlName="noOfCredit" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" autocomplete="off" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required number.
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="relationSince" placeholder="Relationship Since">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Distance" type="number" (keypress)="numberOnly($event)"
                                formControlName="distance" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transportation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Ex.Truck - MH01OO1234" formControlName="transporation"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2" style="vertical-align: middle;">Department</th>
                                        <th class="p-2" style="vertical-align: middle;">Contact</th>
                                        <th class="p-2" style="vertical-align: middle;">E-Mail</th>
                                        <th class="p-2" style="vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails" *ngFor="
                let group of additionalDetailsControls.controls;
                let i = index
              ">
                                        <tr [formGroupName]="i">

                                            <td style="vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="10" maxLength="10"
                                                    ngxOnlyNumbers formControlName="additionalMobile" />
                                                    <div *ngIf="(submitted || group.get('additionalMobile').touched) &&  group.get('additionalMobile').errors?.minlength">
                                                        <small class="text-danger" >Mobile Number should be 10 digits.
                                                        </small>
                                                      </div>
                                                      <div *ngIf="(submitted || group.get('additionalMobile').touched) &&  group.get('additionalMobile').errors?.maxlength">
                                                        <small class="text-danger" >Mobile Number should be 10 digits.
                                                        </small>
                                                      </div>
                                                      <div *ngIf="(submitted || group.get('additionalMobile').touched) &&  group.get('additionalMobile').errors?.pattern">
                                                        <small class="text-danger" >  Invalid mobile format.</small>
                                                      </div>

                                            </td>
                                            <td style="vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()" />
                                                    <div *ngIf="(submitted || group.get('additionalContact').touched) &&  group.get('additionalContact').errors?.pattern">
                                                        <small class="text-danger" >Enter a valid E-Mail.</small>
                                                      </div>
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <a class="btn" (click)="deleteaddAddtionalDetails(i)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No Vendor Details Added. Please Add Vendor
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        Vendor
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
