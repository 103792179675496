<div class="col-12 pe-lg-2">
    <div class="card mb-3">
        <div class="card-header">
            <h4 class="mb-0" style="font-weight:500;font-size:18px!important;">Customer Profile</h4>
        </div>
        <div class="card-body bg-light">
            <form [formGroup]="customerProfileForm">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Name</mat-label>
                            <input type="text" matInput required formControlName="firstName" maxLength="50"
                                placeholder="First Name" [ngClass]="{
                                    'is-invalid': (f.firstName.invalid && f.firstName.touched) && submitted }"
                                required />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.required">
                            First Name is Required.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.pattern">
                            First Name is Required.
                        </mat-error>

                    </div>

                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Last Name</mat-label>
                            <input type="text" matInput required formControlName="lastName" maxLength="50"
                                placeholder="Last Name" [ngClass]="{
                                    'is-invalid': (f.lastName.invalid && f.lastName.touched) && submitted }"
                                required />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.lastName.touched) && f.lastName.errors?.required">
                            Last Name is Required.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.lastName.touched) && f.lastName.errors?.pattern">
                            Last Name is Required.
                        </mat-error>


                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>E-Mail</mat-label>
                            <input type="email" matInput formControlName="email" placeholder="Email"
                                [ngClass]="{'is-invalid': f.email.invalid && submitted}" value="">
                            <div *ngIf="f.email.invalid && submitted" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">email is required!</div>
                                <div *ngIf="f.email.errors.pattern">
                                    Email must be a valid email address
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <mat-form-field appearance="outline" style="width: 85%;">
                            <mat-label>Mobile Number</mat-label>
                            <input type="text" matInput required formControlName="mobile" minlength="10" maxlength="10"
                                placeholder="Mobile Number" [ngClass]="{
                                    'is-invalid': (f.mobile.invalid && f.mobile.touched) && submitted }" value="">
                        </mat-form-field>
                        <span class="mt-3 ml-1">
                            <a class="mobVerify" aria-hidden="true" (click)="updateMobile()" title="Verify Mobile"
                                data-bs-toggle="modal" data-bs-target="#error-modal">
                                <!-- <i class="fas mobVerify fa-mobile-alt"></i> -->
                                <i class="fa fa-check text-success iconRight" aria-hidden="true"></i>
                            </a>
                        </span>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Mobile Number is required.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.minlength">
                            Mobile Number must be 10-digits.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.maxlength">
                            Mobile Number must be 10-digits.
                        </mat-error>

                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">
            <h4 class="" style="font-weight:500;font-size:18px!important;">Address Profile</h4>
        </div>
        <div class="card-body bg-light">
            <form [formGroup]="customerProfileForm" class="row">
                <div class=" col-sm-6 mb-2 mt-2" style="font-weight:500;font-size:18px!important; ">
                    <a>Edit Your Address </a>
                </div>
                <div class="col-sm-6"></div>
                <!-- Pincode -->
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Pincode</mat-label>
                        <input type="text" matInput required maxlength="5" minlength="5" name="pincodetxt"
                            id="pincodetxt" formControlName="pincode" placeholder="Pincode" [ngClass]="{
                                'is-invalid': (f.pincode.invalid && f.pincode.touched) && submitted }"
                            (change)="getPincodeDetails($event.target.value)">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.required">
                        Pincode is Required.
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.pattern">
                        Pincode should be only digits.
                    </mat-error>


                </div>

                <!-- State -->

                <div class="col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label" for="city">City <span class="error-asterisk">*</span></label>
              <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                  <mat-select required formControlName="city" name="pintxt" id="pintxt" placeholder="City"
                      [ngClass]="{
                      'is-invalid': (f.city.invalid && f.city.touched) && submitted }">
                  <mat-option *ngFor="let city of city" [value]="city">{{ city }}</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-error class="text-danger"
                  *ngIf="(submitted || f.city.touched) &&f.city.errors?.required">
                  Enter a valid City.
              </mat-error>






                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>State</mat-label>
                        <input matNativeControl type="text" ngxOnlyString required required formControlName="state"
                            placeholder="State" [ngClass]="{
                                'is-invalid': (f.state.invalid && f.state.touched) && submitted }" required>
                    </mat-form-field>

                    <mat-error class="text-danger" *ngIf="(submitted || f.state.touched) && f.state.errors?.required">
                        State is Required.
                    </mat-error> -->
                </div>

                <!-- District -->
                <!-- <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>District</mat-label>
                        <input matNativeControl matInput required type="text" formControlName="district"
                            placeholder="District" [ngClass]="{
                            'is-invalid': (f.district.invalid && f.district.touched) && submitted }" required>
                    </mat-form-field>

                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.district.touched) && f.district.errors?.required">
                        District is Required!
                    </mat-error>
                </div> -->

                <!-- Town/City -->
                <!-- <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Town/City</mat-label>
                        <input matNativeControl matInput required type="text" formControlName="city"
                            placeholder="Town/City" [ngClass]="{
                            'is-invalid': (f.city.invalid && f.city.touched) && submitted }" required>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.city.touched) && f.city.errors?.required">
                        Town/City is Required.
                    </mat-error>

                </div> -->
                <!-- House No, Apartment, Building, Company -->
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>House No, Apartment, Building, Company</mat-label>
                        <input matNativeControl matInput required type="text" formControlName="homeno"
                            placeholder="House No" [ngClass]="{
                            'is-invalid': (f.homeno.invalid && f.homeno.touched) && submitted }" required>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.homeno.touched) && f.homeno.errors?.required">
                        House No is Required.
                    </mat-error>
                </div>

                <!-- Area/Sector/Village -->
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Area, Street, Sector, Village</mat-label>
                        <input matNativeControl matInput required type="text" formControlName="address"
                            placeholder="Area" [ngClass]="{
                            'is-invalid': (f.address.invalid && f.address.touched) && submitted }" required>
                    </mat-form-field>


                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.address.touched) && f.address.errors?.required">
                        Area, Street, Sector, Village is Required!
                    </mat-error>
                </div>

                <!-- Country -->
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Country</mat-label>
                        <input matNativeControl matInput required type="text" formControlName="country"
                            placeholder="Country" [ngClass]="{
                            'is-invalid': (f.country.invalid && f.country.touched) && submitted }" required>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.country.touched) && f.country.errors?.required">
                        Country is Required.
                    </mat-error>
                </div>
                <!-- Landmark -->
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Landmark</mat-label>
                        <input matNativeControl matInput type="text" formControlName="landmark" placeholder="Landmark"
                            [ngClass]="{
                            'is-invalid': (f.landmark.invalid && f.landmark.touched) && submitted }" required>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.landmark.touched) && f.landmark.errors?.required">
                        Landmark is Required.
                    </mat-error>
                </div>
                <div class="col-12 d-flex justify-content-end">
                    <div><button class="btn btn-outline-primary margin-right me-1 mb-1 mr-10" type="button"
                            (click)="updateCustomerProfile()">Update User Profile</button></div>

                    <button class="btn btn-outline-primary margin-right btn-sm me-1 mb-1 ml-2" type="button">
                        Cancel
                    </button>

                </div>


            </form>


        </div>
    </div>

    <div class="card mb-3">
        <form [formGroup]="addressForm">
            <div class="card-header">
                <h4 class="mb-0" style="font-weight:500;font-size:18px!important;">Add multiple Addresses</h4>
            </div>
            <div class="card-body bg-light">
                <div formArrayName="addresses" *ngFor="let item of addressControls.controls; let i = index">
                    <div [formGroupName]="i" class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Pincode</mat-label>
                                <input type="text" matInput required maxlength="5" minlength="5" name="pincodetxt"
                                    id="pincodetxt" formControlName="pincode" placeholder="Pincode" [ngClass]="{
                                'is-invalid': (addressControls.controls[i].get('pincode').invalid && addressControls.controls[i].get('pincode').touched) && submitted }" required
                                    (change)="getPincodeDetailsForAdditionalAddress($event.target.value,i)">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || addressControls.controls[i].get('pincode').touched) && addressControls.controls[i].get('pincode').errors?.required">
                                Pincode is Required.
                            </mat-error>
                            <mat-error class="text-danger"
                                *ngIf="(submitted ||addressControls.controls[i].get('pincode').touched) && addressControls.controls[i].get('pincode').errors?.pattern">
                                Pincode should be only digits.
                            </mat-error>
                        </div>

                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <label class="form-label" for="city">City <span class="error-asterisk">*</span></label>
                            <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                                <mat-select required formControlName="city" name="pintxt" id="pintxt" placeholder="City"
                                    [ngClass]="{
                                    'is-invalid': (f.city.invalid && f.city.touched) && submitted }">
                                <mat-option *ngFor="let city of city" [value]="city">{{ city }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.city.touched) &&f.city.errors?.required">
                                Enter a valid City.
                            </mat-error>



                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>State</mat-label>
                                <input matNativeControl type="text" ngxOnlyString required required
                                    formControlName="state" placeholder="State" [ngClass]="{
                                'is-invalid': (addressControls.controls[i].get('state').invalid && addressControls.controls[i].get('state').touched) && submitted }" required>
                            </mat-form-field>

                            <mat-error class="text-danger"
                                *ngIf="(submitted || addressControls.controls[i].get('state').touched) && addressControls.controls[i].get('state').errors?.required">
                                State is Required.
                            </mat-error> -->

                        </div>

                        <!-- <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>District</mat-label>
                                <input matNativeControl matInput required type="text" formControlName="district"
                                    placeholder="District" [ngClass]="{
                            'is-invalid': (addressControls.controls[i].get('district').invalid && addressControls.controls[i].get('district').touched) && submitted }" required>
                            </mat-form-field>

                            <mat-error class="text-danger"
                                *ngIf="(submitted || addressControls.controls[i].get('district').touched) && addressControls.controls[i].get('district').errors?.required">
                                District is Required.
                            </mat-error>
                        </div> -->

                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Country</mat-label>
                                <input matNativeControl matInput required type="text" formControlName="country"
                                    placeholder="Country" [ngClass]="{
                            'is-invalid': (addressControls.controls[i].get('country').invalid && addressControls.controls[i].get('country').touched) && submitted }" required>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || addressControls.controls[i].get('country').touched) && addressControls.controls[i].get('country').errors?.required">
                                Country is Required.
                            </mat-error>

                        </div>

                        <!-- <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Town/City</mat-label>
                                <input matNativeControl matInput required type="text" formControlName="city"
                                    placeholder="Town/City" [ngClass]="{
                            'is-invalid': (addressControls.controls[i].get('city').invalid && addressControls.controls[i].get('city').touched) && submitted }" required>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || addressControls.controls[i].get('city').touched) && addressControls.controls[i].get('city').errors?.required">
                                Town/City is Required.
                            </mat-error>
                        </div> -->

                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>House No, Apartment, Building, Company</mat-label>
                                <input matNativeControl matInput required type="text" formControlName="homeno"
                                    placeholder="House No" [ngClass]="{
                            'is-invalid': (addressControls.controls[i].get('homeno').invalid && addressControls.controls[i].get('homeno').touched) && submitted }" required>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || addressControls.controls[i].get('homeno').touched) && addressControls.controls[i].get('homeno').errors?.required">
                                House No is Required.
                            </mat-error>
                        </div>

                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Area, Street, Sector, Village</mat-label>
                                <input matNativeControl matInput required type="text" formControlName="address"
                                    placeholder="Area" [ngClass]="{
                            'is-invalid': (addressControls.controls[i].get('address').invalid && addressControls.controls[i].get('address').touched) && submitted }" required>
                            </mat-form-field>


                            <mat-error class="text-danger"
                                *ngIf="(submitted || addressControls.controls[i].get('address').touched) && addressControls.controls[i].get('address').errors?.required">
                                Area, Street, Sector, Village is Required!
                            </mat-error>
                        </div>

                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Landmark</mat-label>
                                <input matNativeControl matInput type="text" formControlName="landmark"
                                    placeholder="Landmark" [ngClass]="{
                            'is-invalid': (addressControls.controls[i].get('landmark').invalid && addressControls.controls[i].get('landmark').touched) && submitted }" required>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || addressControls.controls[i].get('landmark').touched) && addressControls.controls[i].get('landmark').errors?.required">
                                Landmark is Required.
                            </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-9 col-3 ml-10">
                            <div class="d-flex justify-content-end">
                                <div>
                                    <a class="btn btn-outline-primary margin-right mb-1" *ngIf="item.value.id"
                                        style="margin-right: 10px" (click)="deleteAddress(item)">Cancel
                                    </a>
                                    <span *ngIf="item.value.id">
                                        <a class="btn btn-outline-primary margin-right mb-1"
                                            (click)="updateMultipleAddress(item)">
                                            Update
                                        </a>
                                    </span>
                                </div>
                                <div>
                                    <a class="btn btn-outline-primary margin-right" *ngIf="!item.value.id"
                                        (click)="removeAddress(i)" style="margin-right: 10px">
                                        -
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end">
                    <a class="btn btn-outline-primary me-2" (click)="addAddress()">
                        +
                    </a>

                    <a class="btn btn-outline-primary " (click)="addMultipleAddress()">
                        Add
                    </a>
                </div>
            </div>

        </form>
    </div>
</div>

<!-- Mobile number updation modal -->
<div class="modal fade" id="error-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 class="mb-1" id="modalExampleDemoLabel">Enter Mobile OTP </h4>
                </div>
                <div class="p-4 pb-0">
                    <div class="mb-3">
                        <div class=" col-lg-12 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Mobile OTP</mat-label>
                                <input matNativeControl type="text" matInput [(ngModel)]="mobileOtp" maxLength="15"
                                    placeholder="Enter OTP" required>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
                <a class="btn btn-outline-primary" (click)="updateMobileNumber()" data-bs-dismiss="modal">OK
                </a>
            </div>
        </div>
    </div>
</div>
