<div class="container-fluid my-2">
  <div clas="row mb-2" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
    <div>
      <img src="../../assets/img/dashboard-party-profiles/aayu-fashion-logo.png"
        class="img-responsive" style="width: 200px;height: 200px;">
    </div>
    <div style="font-size: 32px;font-weight: bold;color: #FC7643;margin: 20px 5px;">Aayu Fashion</div>
    <div style="font-size: 16px;font-weight: 500;color: purple;margin-bottom: 20px;">www.aayufashion.com</div>
  </div>
  <div class="row mb-2">
    <div class="col-md-12 col-lg-8">
      <div class="card" style="padding: 10px 10px 30px 10px;">
        <table class="table">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Subtotal</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody *ngFor="let product of productData">
            <tr>
              <td>{{ product?.itemName }}</td>
              <td>AED {{ product?.basePrice }}</td>
              <td>{{ product?.quantity }}</td>
              <td> AED {{ product?.subTotal }}</td>
              <!-- <td>{{ product?.discount }}</td> -->
              <td> AED {{ product?.total }}</td>
            </tr>
            <tr *ngIf="product?.isOffer">
              <td colspan="3" class="text-success" style="text-align: right;">
                {{ product.offerData?.schemaName}}
              </td>
              <td colspan="2" class="text-success" style="text-align: right;">
                AED&nbsp; - {{ (product?.total - product.offerData?.totalDiscountedPrice) | number:'1.2-2' }}
              </td>
            </tr>
            <tr *ngIf="product.isFreeProduct">
              <td colspan="2" class="text-success" style="text-align: right;">
                {{ product?.schemaName | uppercase }}
              </td>
              <td colspan="1" class="text-success" style="text-align: center;">
                {{ product?.freeProductQuantity}}
              </td>
              <td colspan="2" class="text-success" style="text-align:left;">
                AED&nbsp; - {{ product?.freeProductTotalPrice | number:'1.2-2' }}
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>
    <div class="col-md-12 col-lg-4">
      <div class="card mb-2">
        <div class="card-body" style="background-color: rgb(237, 207, 153);">
          <div>Sub Total</div>
          <div>{{ (subtotal || 0) | number:'1.2-2' }} AED</div>
        </div>
        <div class="card-body" *ngIf="isDiscTotal" style="background-color: rgb(237, 207, 153);">
          <div>Discounted Total</div>
          <div>{{ (discountedTotal || 0) | number:'1.2-2' }} AED</div>
        </div>
        <div class="dropdown-divider p-0 m-0"></div>
        <div class="card-body">
          <div>Tax</div>
          <div>{{ (tax || 0) | number:'1.2-2' }} AED</div>
        </div>
        <div class="card-body" *ngIf="isDiscTax">
          <div>Discounted Tax</div>
          <div>{{ (discountedTax || 0) | number:'1.2-2' }} AED</div>
        </div>
        <div class="dropdown-divider p-0 m-0"></div>
      </div>
      <div class="card">
        <div class="card-body" style="display: flex;flex-direction: column;">
          <div style="display: flex;">
            <div style="display: flex;flex-direction: column;flex-basis: 50%;">
              <div style="font-weight: 500;font-size: 16px;">Total</div>
              <div style="align-self: flex-end;padding: 10px 20px;font-size: 18px;color: lightgreen;">{{ (finalTotal || 0) | number:'1.2-2' }} AED</div>
            </div>
            <div style="display: flex;flex-direction: column;flex-basis: 50%;">
              <div style="font-weight: 500;font-size: 16px;">Amount Due</div>
              <div style="align-self: flex-end;padding: 10px 20px;font-size: 18px;color: orange;">{{ (amountDue || 0) | number:'1.2-2' }} AED</div>
            </div>
          </div>
          <div class="dropdown-divider"></div>
          <div style="display:flex;flex-direction: column;"> 
            <div style="padding-bottom: 20px;">Payment Mode</div>
            <div style="display: flex; gap: 2rem;">
              <button class="btn paymentBtn" 
                      [ngClass]="{'selected-mode': paymentMode === 'CASH'}">
                Cash
              </button>
              <button class="btn paymentBtn" 
                      [ngClass]="{'selected-mode': paymentMode === 'GIFTCARD'}">
                Gift Card
              </button>
              <button class="btn paymentBtn" 
                      [ngClass]="{'selected-mode': paymentMode === 'CREDITCARD'}">
                Credit Card
              </button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
