<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
    <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
      All Vendors
    </div>
    <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
      <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>
      <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
      </button>
    </div>
  </div>
  <hr class="mt-1" />
  <div class="fs--1 ">
    <div class="row ">
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3" id="dateRangePrintHide">
        <label for="Date Range" class="form-label">Date</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input  class="uppercase-date-range" matInput placeholder="Date" id="Date" placeholder="Date Range" id="Date Range" type="text" [(ngModel)]="dateRange"
            [outsideClick]="true" ngModel bsDaterangepicker
            (bluer)="getDate()" [maxDate]="today" (ngModelChange)="changeDate($event)"
            [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
          <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
        </mat-form-field>
      </div>
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3" id="searchBarHide">
        <label class="form-label">Select Vendor Name</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput
            (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" [ngClass]="{
          'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="searchPartyByName1(option)">
              {{option.partyName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="table-responsive scrollbar mt-4 fs--1">
        <table class="table bg-white table-bordered table-stripped mb-3" id="excel-table">
          <thead class="" style="background-color: #F0F0F0;">
            <tr class="text-900">
              <th scope="col" class="text-center text-nowrap p-1" style="width: 6%;">#
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                    class="text-1400  fs-1"></i></button>
              </th>
              <th scope="col" class="text-center text-nowrap p-1" style="width: 6%;">Vendor Name
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                    class="text-1400  fs-1"></i></button>
              </th>
              <th scope="col" class="text-center text-nowrap p-1" style="width: 6%;">Mobile Number
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                    class="text-1400  fs-1"></i></button>
              </th>
              <th scope="col" class="text-center text-nowrap p-1" style="width: 6%;">Address
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                    class="text-1400  fs-1"></i></button>
              </th>
              <th scope="col" class="text-center text-nowrap p-1">TRN
                <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                    class="text-1400  fs-1 bi-filter-right"></i></button>
                <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                  <li>
                    <select class="form-select form-select-sm" aria-label="Default select example"
                      (change)="onSelectgst($event.target.value)">

                      <!-- <option selected="">Contains</option>
                    <option value="1">Exact match</option> -->
                      <option value="Equal">Equal to</option>
                    </select>
                  </li>
                  <li>+
                    <div class="col-sm" style="margin-top: 10px;">
                      <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalgst" />
                    </div>
                  </li>
                  <div class="dropdown-divider"></div>
                  <div class="text-center">
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                    </div>
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                        (click)="Applygst()">APPLY</button>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col" class="text-center text-nowrap p-1">Receivable Balance
                <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                    class="text-1400  fs-1 bi-filter-right"></i></button>
                <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                  <li>
                    <select class="form-select form-select-sm" aria-label="Default select example"
                      (change)="onSelectReceivable($event.target.value)">
                      <option value="Equal">Equal to</option>
                      <option value="Greater">Greater than</option>
                      <option value="Less">Less than</option>
                    </select>
                  </li>
                  <li>
                    <div class="col-sm" style="margin-top: 10px;">
                      <input class="form-control fs--1" id="inputPassword3" type="input"
                        [(ngModel)]="totalReceivable" />
                    </div>
                  </li>
                  <div class="dropdown-divider"></div>
                  <div class="text-center">
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                    </div>
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                        (click)="ApplyReceivable()">APPLY</button>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col" class="text-center text-nowrap p-1">Payable Balance
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                    class="text-1400  fs-1 bi-filter-right"></i></button>
                <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                  <li>
                    <select class="form-select form-select-sm" aria-label="Default select example"
                      (change)="onSelectPayable($event.target.value)">
                      <option value="Equal">Equal to</option>
                      <option value="Greater">Greater than</option>
                      <option value="Less">Less than</option>
                    </select>
                  </li>
                  <li>
                    <div class="col-sm" style="margin-top: 10px;">
                      <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalPayable" />
                    </div>
                  </li>
                  <div class="dropdown-divider"></div>
                  <div class="text-center">
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                    </div>
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                        (click)="ApplyPayable()">APPLY</button>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of partyList; let i = index">
              <td>{{ i + 1 }} </td>
              <td>{{item.partyName}}</td>
              <td>{{ item.mobile }}</td>
              <td>{{ item.billingAddress }}</td>
              <!-- <td>{{item.email}}</td> -->
              <td class="text-center">{{item.gstIn}}</td>
              <td class="text-center">{{item.totalDebt}}</td>
              <td class="text-center">{{item.totalCredit}}</td>

            </tr>
          </tbody>

          <tbody *ngIf="partyList == 0">
            <tr class="text-center">
              <td colspan="10">
              
                <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"
                  width="200" height="150">
              
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td class="nowrap" colspan="1">
                <strong class=" text-dark">
                  Total
                </strong>
              </td>
              <!-- <td class = "nowrap" colspan="4"></td> -->
              <td class="nowrap" colspan="1">
                <strong class=" text-dark">
                  <a class="text-success">
                    {{itemPartyList.TotalSaleAmount ? itemPartyList.TotalSaleAmount :"-" }}
                  </a>
                </strong>
              </td>
              <td class="nowrap" colspan="1">
                <strong class="fw-bold text-dark">
                  <a class="text-success">
                    {{itemPartyList2.TotalPurchasAmount ? itemPartyList2.TotalPurchasAmount :"-"}}
                  </a>
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
        <div id="headingPrintHide1">
          <mat-paginator class="float-right mt-2" *ngIf="partyList" [pageSize]="partyPageSize" showFirstLastButtons
            [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
            (page)="handlePage($event)" aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>