<div class="modal-header pt-0" id="Add-Party-modal">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 18px;" class="modal-title mb-0">Purchase Invoice</h1>

    <div class="addProductButtons">

        <button style="margin-right: 10px;" type="button" class="btn btn-cancel"
          (click)="CancelPurchase()">Cancel</button>
        <button style="margin-right: 10px;" type="button" class="btn btn-save" *appHasPermission="['create']; name 'purchaseinvoicelist'"
          (click)="setSaveAsDraft()" [hidden]="draftId">
          Save as Draft
        </button>
        <button type="submit" class="btn btn-save" (click)="postPurchaseInvoice()" *appHasPermission="['create']; name 'purchaseinvoicelist'">Save</button>

    </div>

</div>
<div class="modal-body">
    <div class="px-3">
        <!-- UAE -->
        <form [formGroup]="PurchaseInvoiceForm">
            <div class="row mb-2">
                <div class="col-sm-12 col-md-6 col-lg-4 ">
                    <label class="form-label">Transaction Type<span class="error-asterisk">*</span></label>
                    <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                        <mat-select disableOptionCentering aria-label="Default select example" formControlName="credit"
                            (selectionChange)="getCredit($event)" placeholder="Transaction Type" [ngClass]="{
                              'is-invalid': (f.credit.invalid && f.credit.touched) && submitted }">
                            <mat-option value='false'>Cash</mat-option>
                            <mat-option value='true'>Credit</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.credit.touched) && f.credit.errors?.required">
                        Transaction Type is Required.
                    </mat-error>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>

                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput
                            formControlName="partyName" (keyup)="searchPartyByName($event.target.value)"
                            [matAutocomplete]="auto" [ngClass]="{
                              'is-invalid': (f.partyName.invalid  && f.partyName.touched) && submitted }">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                                (onSelectionChange)="setSelectedPartyDeatails(option)">
                                {{option.partyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.partyName.touched) && f.partyName.errors?.required">
                        Vendor Name is Required.
                    </mat-error>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Purchase Document #<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" matInput placeholder="Purchase Document #" formControlName="invoiceNo"
                            [disabled]="showInvoiceGenerateData" autocomplete="off" maxlength="20" [ngClass]="{
                          'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }">

                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched) && f.invoiceNo.errors?.required">
                            Purchase Document # is Required.
                    </mat-error>

                </div>
                <!-- Purchase Invoice Date -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label" for="Purchase Invoice Date">Purchase Invoice Date</label>
                    <mat-form-field class="example-full-width" style="font-size: 14px" appearance="outline">
                        <input matInput required placeholder="Purchase Invoice Date." formControlName="invoiceDate"
                            [(ngModel)]="todaysDate" style="text-transform:uppercase!important;" [maxDate]="today"
                            id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                          showWeekNumbers:false}" [ngClass]="{
                          'is-invalid': (f.invoiceDate.invalid && f.invoiceDate.touched) && submitted }"
                            (click)="openDatepicker()">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
                        Purchase Invoice Date is Required.
                    </mat-error>
                </div>
            </div>

            <div class="row mb-2">
                <!-- Bill No. -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Invoice #<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" placeholder="Invoice #" matInput formControlName="billNo"
                            autocomplete="off" [disabled]="showInvoiceGenerateData" [ngClass]="{
                          'is-invalid': (f.billNo.invalid && f.billNo.touched) && submitted }">

                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.billNo.touched) && f.billNo.errors?.required">
                            Invoice # is Required.
                    </mat-error>
                </div>

                <div [ngSwitch]="currentUser.userType"  class=" col-xs-12 col-sm-12 col-md-6 col-lg-4" *appHasPermission="['delete', 'modify','create', 'read']; name 'warehouse'">
                    <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
                      <label class="form-label">Warehouse</label>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                            <mat-option value="clear">Clear Selection</mat-option>
                            <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                            {{warehouse.firstName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
                      <label class="form-label">Warehouse</label>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                            <mat-option value="clear">Clear Selection</mat-option>
                            <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                            {{warehouse.firstName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <!-- Billing Address -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Billed From<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput rows="5" formControlName="billingAddress" maxlength="150" required
                            placeholder="Enter Billing Address" autocomplete="off"
                            [ngClass]="{
                          'is-invalid': (f.billingAddress.invalid || f.billingAddress.touched) && submitted }"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                        Billing Address is Required.
                    </mat-error>
                </div>

                <!-- Shipping Address -->
                <div *ngIf="isShippingAddress" class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Shipped From</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput rows="5" formControlName="shippingAddress"
                            placeholder="Enter Shipping Address" maxlength="100P"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row ">
                <div class="col-md-4 d-flex" style="font-size: 12px;">
                    <div class="form-check">
                        <input class="form-check-input" id="inlineCheckbox1" type="checkbox"
                            [disabled]="showInvoiceGenerateData" (change)="showShippingAddress($event.target.checked)"
                            [checked]="isShippingAddress" />
                    </div>
                    <label style="margin-left:1px!important;font-size: 14px;" class="text-nowrap ">Shipped from
                        Address</label>
                </div>
            </div>
            <div class="table-responsive my-custom-scrollbar">
                <table class="table table-bordered">
                    <thead class="inner-model-table-heading">
                        <tr class="font_size font-weight-bold">
                            <th style="vertical-align: middle;" scope="col">#</th>
                            <th scope="col" style="width: 10%;vertical-align: middle;">
                                SKU<span class="text-danger">*</span>
                            </th>
                            <th style="vertical-align: middle;" class="text-nowrap">SKU Description</th>
                            <th style="vertical-align: middle;">Unit</th>

                            <th style="vertical-align: middle;">Quantity</th>

                            <th style="vertical-align: middle;" scope="col" colspan="1" class="text-nowrap">
                                {{ lang.Purchase_Price_With_GST}}<span class="text-danger">*</span>
                            </th>
                            <th style="width:10%;vertical-align: middle;" scope="col" colspan="1" class="text-nowrap">
                                {{lang.GST}}<span class="text-danger">*</span>
                            </th>

                            <th style="vertical-align: middle;" class="text-nowrap" scope="col ">
                                {{lang.Total_In_Rs}}<span class="text-danger text-nowrap">*</span>
                            </th>
                            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                                Action<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td class="s23323">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                                        matInput [formControl]="myControl1" [matAutocomplete]="auto1">
                                    <mat-icon class="m-2" matSuffix>search</mat-icon>
                                    <mat-autocomplete #auto1="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredOptions3 | async"
                                            [value]="option.itemName"
                                            (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                                            <span style="font-size: 12px;"> {{ option.itemName | titlecase }}/<b>{{
                                                    option.barcode}}</b></span>

                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </td>
                            <td>
                                <input type="text" matInput placeholder="SKU Description" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Unit" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Unit Price" />
                            </td>
                            <td>
                                <input type="text" matInput required placeholder=" %" />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Amount" style="max-width: 150px;" required />
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let item of productData; let i=index">
                            <td style="vertical-align: middle;">{{ i + 1 }}</td>
                            <td>{{ item.barcode }}</td>
                            <td>{{ item.itemName | titlecase }}</td>
                            <td class="text-center">
                              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
                            </td>
                            <td style="display: flex; align-items: center;">
                              <a class="border-white btn" (click)="changeQuantity('-', i)">-</a>
                              <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                                     (change)="changeQuantity('qty', i, $event.target.value)"
                                     style="width: 5rem; text-align: center;" required />
                              <a class="border-white btn" (click)="changeQuantity('+', i)">+</a>
                            </td>
                            <td colspan="1">
                              <input type="text" matInput (keypress)="numberOnly($event)"  (change)="setPurchasePrice($event.target.value, i)"  [value]="item?.purchasePrice">
                            </td>
                            <td colspan="1">
                              <input type="text" matInput  (change)="setGST($event.target.value, i)" [value]="item.gstRate">
                            </td>
                            <td style="max-width: 150px; display: flex; align-items: center;">
                              <p class="mb-0">AED&nbsp;</p>
                              <p class="mb-0">{{ item.total | number:'1.2-2' }}</p>
                            </td>
                            <td>
                              <button type="button" class="text-center btnb" style="width: 3rem!important;" (click)="changeQuantity('rm', i)">
                                <span class="bi-trash icon-color fs-1"  style="color:red"  data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"></span>
                              </button>
                            </td>
                          </tr>
                    </tbody>
                </table>
            </div>

            <div class="row d-flex justify-content-between">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-2">

                    <label class="form-label" for="customFile">Upload Bill</label>
                        <div class="mt-2">
                            <label>
                                <span *ngIf="this.billUpload ==='' "
                                    class="uploadbill">
                                    Upload
                                </span>
                                <input #fileInput style="display: none;" type="file" name="file"
                                    (change)="handleInputChangePan($event)" *ngIf="this.billUpload ==='' ">
                            </label>
                            <div>
                                <span style="position: relative;top: -10px;margin-left: 10px;" *ngIf="this.billUpload !=='' ">
                                    <img style="cursor: pointer;" *ngIf="this.fileType == 'image'" [src]="this.billUpload" width="30"
                                        height="30" (click)="openImgPdfpan()">
                                    <img style="cursor: pointer;" *ngIf="this.fileType == 'pdf'" [src]="'assets/img/icons/spot-illustrations/pdfLogo.jpg'" width="30"
                                        height="30" (click)="openImgPdfpan()">
                                        <span style="margin-left: 10px; margin-right: 10px;">{{ getLastWordAfterHyphenPan(this.uploadBill) }}
                                        </span><span><button class="close" style="color: red;border: none;font-size: 16px;
                                            background-color: white;" type="button" (click)="deletePan($event)">&#x2715;</button></span>
                                </span>
                            </div>
                        </div>
                </div>

                <div class="col-sm-12 col-md-4 col-lg-4 table-bordered my-custom-scrollbar">

                    <table class="table table-bordered p-5 my-custom-scrollbar ">
                        <tr>
                            <td class="thWidth p-2" style="text-align:left!important">Subtotal</td>
                            <td class="p-2" style="width: 50px;" style="text-align:right!important">
                                AED&nbsp;{{ subTotal | number:'1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align:left!important">
                                {{lang.CGST}}
                            </td>
                            <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;"
                                style="text-align:right!important">
                                AED&nbsp;{{ gstRate | number:'1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" style="text-align:left!important">Total</td>
                            <td class="p-2" style="width: 50px;" style="text-align:right!important">
                                AED&nbsp;{{ total | number:'1.2-2'}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </form>
    </div>
</div>
