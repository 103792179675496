import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  TemplateRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  Type,
  Renderer2,
  NgZone,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { Chart, registerables } from 'chart.js';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);
// import { NgxCsvParser } from 'ngx-csv-parser';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ProductService } from 'src/app/core/services/product.service';
import { SundryCreditorService } from 'src/app/core/services/sundry-creditor.service';
import { PartyService } from 'src/app/core/services/party.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PriceRangeDialogComponent } from './widgets/price-range-dialog/price-range-dialog.component';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { CreateDashboardComponent } from './pages/create-dashboard/create-dashboard.component';

import { Widget } from 'src/app/_models/widgets';
import { componentTypeMapping } from '../shared/shared.module';
import { PurchaseInvoiceComponent } from '../Invoice/purchase-invoice/purchase-invoice.component';
import { SalesInvoiceComponent } from '../Invoice/sales-invoice/sales-invoice.component';
import { ProfileService } from 'src/app/core/services/profile.service';

componentTypeMapping
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    // monthYearLabel: 'MMM YYYY',
    // dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    // monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ClientDashboardComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;  
  @ViewChild('dfMessenger', { static: false }) dfMessengerElement: ElementRef | undefined;
  @ViewChild('purchaseChart') purchaseChart;
  @ViewChild('widgetModalContent') widgetModalContent!: TemplateRef<any>;
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public userId: string = '';
  isLoading: boolean = false;
  public showProfile: boolean;
  public todaysDate = this.commonService.getTodaysDate();
  public totalSales: any;
  public totalPurchase: any;
  public permissionData: any;
  public canvas: any;
  public puchaseChart: any = []; /*
  public salesChart: any = []; */
  // public stockDetailsChart: any = [];
  // public netProfitChart: any = [];
  public salesPurchaseChart: any = [];
  public salesMonthlyChart: any = [];
  public puchaseMonthChart: any = [];
  public puchaseYearChart: any = [];
  public salesYearChart: any = [];
  public weekNames: any;
  public weekDates: any;
  public weekTotalPurchaseAmt: any;
  public weekTotalSaleAmt: any;
  public weekNamesSales: any;
  public weekPurchaseDateValue: Date;
  public weekSalesDateValue: Date;
  public monthSalesDateValue: Date;
  public monthPurchaseDateValue: Date;
  public yearSalesDateValue: Date;
  public yearPurchaseDateValue: Date;
  public totalBillAmount: any;
  public monthDay: any;
  public purchaseMonthDay: any;
  public purchaseMonthTotalBillAmount: any;
  public yearlySalesDate: any;
  public yearlySalestotalBillAmount: any;
  public yearlyPurchaseDate: any;
  public yearlyPurchasetotalBillAmount: any;
  public isWeek: boolean = true;
  public isMonth: boolean = false;
  public isYear: boolean = false;
  public salesType: string = 'Weekly';
  public purchaseType: string = 'Weekly';
  public maxDate = new Date();
  public newSaleDate;
  public newPurchaseDate;
  public defaultPurchaseSelect = 'Weekly';
  public defaultSaleSelect = 'Weekly';
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  lowStockProductList: any[] = [];
  pendingPartyList: any[] = [];
  creditorsPartyList: any[] = [];
  recentPartyList: any[] = [];
  newPartiesList: any[];
  recentSaleList: any[];
  productList: any[];
  updatedProductList: any[] = [];
  // netProfitCurrentYearList: any[];
  // netProfitLastYearList : any[];
  // currentProfitDataList : any[]
  // lastProfitDataList :any[]
  // salesData: any[];
  // purchaseData: any[];
  todaySales: number;
  percentageCount: any;
  netSales: number;
  // grossProfit: number;
  // grossLoss: number;
  // totalProfit: number;
  // totalLoss: number;
  netProfit: number;
  todaysProductSold: any;
  todaysProductSoldPercent: any;
  newPartiesJoinedCount: any;
  newPartiesPercent: any;
  @Input('appFormatNumberWithCommas') value: number;
  selectedWidget: any;
  selectedFilter: string;
  selectedSort: string;
  filteredProductList: any[] = [];
  sortedProductList: any[];
  isSortSelected = false;
  isFilterSelected = false;
  minPrice: number;
  maxPrice: number;
  emptyListMessage: string;
  topProductSoldList: any[];
  widgets = [];
  dashboardName: any;
  dashboardNameList: any[] = [];
  selectedWidgetList: any[] = [];
  selectedDashboard: string = '0'; // Initialize with "0" for the Default Dashboard
  selectedDashboardName:string;
  res: any;
  addWidget: boolean = false;
  selectedDashboardId:string="";
  userDashboardId:string="";
  userDashboardName:string="";
  defaultDashboardList: any;

  //This list is going to load in main dashboard page if no dashboard is selected by user
  // defaultDashboardList = [
  //   {
  //     id: 1,
  //     name: "Today's Sale",
  //     checked: false,
  //     component: 'TotalSalesComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 20,
  //     position: 1
  //   },
  //   {
  //     id: 2,
  //     name: 'Product Sold',
  //     checked: false,
  //     component: 'ProductsSoldComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 20,
  //     position: 2
  //   },
  //   {
  //     id: 3,
  //     name: 'New Customers',
  //     checked: false,
  //     component: 'NewCustomersComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 20,
  //     position: 3
  //   },
  //   {
  //     id: 4,
  //     name: 'Net Profit and Net Sales',
  //     checked: false,
  //     component: 'TotalNetProfitComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 20,
  //     position: 4
  //   },
  //   {
  //     id: 5,
  //     name: 'Total Sales',
  //     checked: false,
  //     component: 'WeeklySalesComponent',
  //     isDeleted: true,
  //     isConfigure: true,
  //     widgetWidth: 33,
  //     position: 5
  //   },
  //   {
  //     id: 6,
  //     name: 'Total Purchase',
  //     checked: false,
  //     component: 'TotalPurchaseComponent',
  //     isDeleted: true,
  //     isConfigure: true,
  //     widgetWidth: 33,
  //     position: 6
  //   },
  //   {
  //     id: 7,
  //     name: 'Stock Details',
  //     checked: false,
  //     component: 'StockDetailsComponent',
  //     isDeleted: true,
  //     isConfigure: true,
  //     widgetWidth: 33,
  //     position: 7
  //   },
  //   {
  //     id: 8,
  //     name: 'Sale vs Purchase',
  //     checked: false,
  //     component: 'SalesPurchaseChartComponent',
  //     isDeleted: true,
  //     isConfigure: true,
  //     widgetWidth: 50,
  //     position: 8
  //   },
  //   {
  //     id: 9,
  //     name: 'Net Profit: Past Year Vs Current Year',
  //     checked: false,
  //     component: 'NetProfitChartComponent',
  //     isDeleted: true,
  //     isConfigure: true,
  //     widgetWidth: 50,
  //     position: 9
  //   },
  //   {
  //     id: 10,
  //     name: 'Recent Contacts',
  //     checked: false,
  //     component: 'RecentContactsComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 50,
  //     position: 10
  //   },
  //   {
  //     id: 11,
  //     name: 'Low Stocks Products',
  //     checked: false,
  //     component: 'LowStocksProductComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 50,
  //     position: 11
  //   },
  //   {
  //     id: 12,
  //     name: 'Pending Payment',
  //     checked: false,
  //     component: 'PendingPaymentComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 50,
  //     position: 12
  //   },
  //   {
  //     id: 13,
  //     name: 'Credit Vendor name',
  //     checked: false,
  //     component: 'CreditVendorComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 50,
  //     position: 13
  //   },
  //   {
  //     id: 14,
  //     name: 'Best Selling products',
  //     checked: false,
  //     component: 'BestSellingProductsComponent',
  //     isDeleted: true,
  //     isConfigure: false,
  //     widgetWidth: 100,
  //     position: 14
  //   },
  // ];

  constructor(
    public rolePermission: RolesPermissionsService,
    private toastService: ToastNotificationService,
    public authorizationService: AuthorizationService,
    private profileService: ProfileService,
    public dashboardService: DashboardService,
    private sundryservice: SundryCreditorService,
    public commonService: CommonService,
    public router: Router,
    private el: ElementRef,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private ngZone: NgZone
  ) {
    Chart.register(...registerables);
  }

  async ngOnInit(): Promise<void> {
    await this.getDashboardWidgetList()
    // await this.getMyDashboards();
    // this.ngZone.run(async () => {
    //   // Code here will execute inside Angular's zone
    //   console.log('This is inside Angular\'s zone.');
    //   await this.getDashboardWidgetList()
    //   await this.getMyDashboards();
    // });
    
    // setTimeout(() => {
    
    // }, 1000);
    //this.selectCurrentDashboard();
    // Subscribe to the observable to receive updates
    // this.weekPurchaseDateValue = new Date();
    // this.weekSalesDateValue = new Date();
    // this.monthSalesDateValue = new Date();
    // this.monthPurchaseDateValue = new Date();
    // this.yearSalesDateValue = new Date();
    // this.yearPurchaseDateValue = new Date();
    // this.getAppPendingPayments();
    // this.getTotalSales();
    // this.getLowStockSummary();
    // this.getAllCreditors();
    // this.getAllParties()
    // this.getRecentSale();
  }

  ngAfterViewInit() {
    console.log(this.dfMessengerElement)
    if (this.dfMessengerElement) {
      const dfMessenger = this.dfMessengerElement.nativeElement;
      console.log(dfMessenger)
      // Ensure df-messenger is available
      dfMessenger.addEventListener('df-message-sent', (event: any) => {
        console.log('Message sent event triggered', event);

        const userMessage = event.detail.message.queryText;
        console.log('User Message:', userMessage);

        if (this.currentUser && this.currentUser.id && this.currentUser.accessToken) {
          console.log(`Calling API for User ID: ${this.currentUser.id} with token: ${this.currentUser.accessToken}`);

          // Call the API
          // fetch(`https://uaestaging.iceipts.com/api/inventory/web/botLogin/${this.currentUser.id}?userToken=${this.currentUser.accessToken}`, {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          //   body: JSON.stringify({
          //     message: userMessage, // Send user input message in the body
          //   }),
          // })
          this.profileService.botLogin({}, this.currentUser.id, this.currentUser.accessToken)
          .then((res: any) => {
              if(res.success){
                console.log(res.data)
              }
            }
          )
          // .then(data => {
          //   console.log('API Success:', data); // Check API response
          // })
          .catch((error) => {
            console.error('API Error:', error); // Log errors if any
          });
        } else {
          console.error('User information is missing.');
        }
      });
    } else {
      console.error('dfMessengerElement is undefined');
    }
  }

  //To get the list of dashboards for user which will be shown in the dropdown
  async getMyDashboards() {
    this.dashboardService.getMyDashboards({}, this.currentUser.id)
      .then((res: any) => { // Specify the type for res
        if (res.success) {
          this.dashboardNameList = res.data;
          const primaryItem = res.data.find(item => item.primary);
          const userDashboardId = primaryItem ? primaryItem.dashboardnew.id : '0';
          this.selectedDashboard = userDashboardId;
          
          this.ngZone.run(() => {
            // Code here will execute inside Angular's zone
            console.log('This is inside Angular\'s zone.');
            if(userDashboardId !== '0'){
              this.getDashboardById(userDashboardId)
              // this.selectCurrentDashboard(userDashboardId);
            } else {
              this.selectedWidgetList = this.defaultDashboardList;
            }
          });
    
          
       
        }
      }).catch(error => {
        console.error("An error occurred while fetching dashboard name list:", error);
      });
  }

  selectCurrentDashboard(userDashboardId) {
    this.dashboardService.getDashboardById({}, this.currentUser.id, userDashboardId, true)
        .then((res: any) => { // Specify the type for res
          if (res.success) {
            // localStorage.setItem("dashboardWidgetList", JSON.stringify(res.data));
            const data = res.data.dashboardlists.map(item => item.dashboardsetting);
            if (data.length !== 0) {
              this.selectedWidgetList = data;
              this.addWidget = false;
            } else {
              this.selectedWidgetList = [];
              this.addWidget = true;
            }
          }
        }).catch(error => {
          console.error("An error occurred while fetching widget list for specific dashboard:", error);
        });
    // this.route.queryParams.subscribe(params => {
    //   if (params['id'] !== ('' || undefined)) {
    //     this.selectedDashboard = params['id'];
    //     this.selectedDashboardName = params['name'];
    //   } else {
    //     this.selectedDashboard = this.userDashboardId;
    //   }
    //   const dashboardData = localStorage.getItem(this.selectedDashboardName);
    //   if (dashboardData) {
    //     this.selectedWidgetList = dashboardData ? JSON.parse(dashboardData) : [];
    //   } else {
    //     this.selectedWidgetList = this.defaultDashboardList;
    //   }
    //   /*Iterate through your widgetList and update the component property with the corresponding component type
    //   this.selectedWidgetList.forEach(widget => {
    //     widget.component = componentTypeMapping[widget.component];
    //   });*/
    // });
  }

  async getDashboardWidgetList() {
    this.isLoading = true
    this.dashboardService.getDashboardWidgetList({}, this.currentUser.id).then((res:any) => {
      if(res.success){
        this.defaultDashboardList = res.data;
        // this.selectedWidgetList = this.defaultDashboardList;
        this.getMyDashboards()
        this.isLoading = false
      }
    })
    .catch(error => {
      this.isLoading = false
      console.error("An error occurred while fetching dashboard widget list:", error);
    })
  }

  getDashboardById(selectedDashboardId?){
    this.isLoading = true
    this.dashboardService.getDashboardById({}, this.currentUser.id, selectedDashboardId, true)
        .then((res: any) => { // Specify the type for res
          if (res.success && res.data !=true) {
            // localStorage.setItem("dashboardWidgetList", JSON.stringify(res.data));
            const data = res.data.dashboardlists.map(item => {
              return {
              ...item.dashboardsetting,
              chartType: item.chartType,
              position: item.listposition // Assuming you want to replace `dashboardsetting.position` with `listposition`
            }});
            data.sort((a, b) => a.position - b.position);

            if (data.length !== '0') {
              this.selectedWidgetList = data;
              this.addWidget = false;
            } else {
              this.selectedWidgetList = [];
              this.addWidget = true;
            }

            this.isLoading = false
          }
        }).catch(error => {
          
          this.isLoading = false
          console.error("An error occurred while fetching widget list for specific dashboard:", error);
        });
  }

  onChangeSelection(event: any) {
    
    this.isLoading = true
    const selectedDashboardId = event.value;
    
    if (this.selectedDashboard !== '0') {
      this.getDashboardById(selectedDashboardId)
    } else {
        this.getDashboardById(null)
      this.selectedWidgetList = this.defaultDashboardList;
    }
    this.isLoading = false
  }

  customizeDashboard(selectedDashboard) {
    localStorage.setItem("selectedDashboardId", selectedDashboard);
    this.router.navigate(['/update-dashboard/']);
  }

  formatNumberWithCommas(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  openWidgetModal(widget) {
    this.modalService.open(this.widgetModalContent, { size: 'md' });
  }

  isWidgetChecked(widgetId: number): boolean {
    const widget = this.widgets.find((widget) => widget.id === widgetId);
    return widget?.checked || false;
  }

  // getTotalSales() {
  //   this.dashboardService
  //     .getTotalSales({}, this.currentUser.id, 'SALES')
  //     .then((res) => {
  //       if (res.data) {
  //         this.todaySales = res.data.todaySales;
  //         this.percentageCount = res.data.percentageChange;
  //       }
  //     });
  // }

  // getLowStockSummary() {
  //   this.productService
  //     .getLowStockWarning(
  //       {},
  //       this.currentUser.id,
  //       this.partyPageSize,
  //       this.currentPageNo + 1
  //     )
  //     .then((res) => {
  //       if (res.success) {
  //         this.lowStockProductList = res.data.pageData;
  //       }
  //     });
  // }

  openDialog(): void {
    const dialogRef: MatDialogRef<CreateDashboardComponent> = this.dialog.open(
      CreateDashboardComponent,
      {
        width: '700px', // Adjust the width as per your requirement
        height: '100vh',
        data: {},
        panelClass: '',
        disableClose: true,
        position: {
          right: '0',
        },
      }
    );
    localStorage.removeItem('dashboardWidgetList');
    dialogRef.afterClosed().subscribe((result) => { });
  }

  openModal(item: string) {
    let componentName;
    switch (item) {      

      case 'sale-invoice':
        componentName = SalesInvoiceComponent;
      break;
      // case 'payment-in':
      //   componentName = PaymentInComponent;
      // break;
      // case 'Estimation-Invoice':
      //   componentName = EstimateComponent;
      // break;
      // case 'sale-order':
      //   componentName = SaleOrderComponent;
      // break;
      // case 'sale-return':
      //   componentName = SalesReturnComponent;
      // break;
        case 'purchase-invoice':
      componentName = PurchaseInvoiceComponent;
      break;
      // case 'payment-out':
      //   componentName = PaymentOutComponent;
      // break;
      // case 'purchase-order':
      //   componentName = PurchaseOrderComponent;
      // break;
      // case 'purchase-return':
      //   componentName = PuchaseReturnComponent;
      // break;
    }
    const dialogRef: MatDialogRef<Component> = this.dialog.open(componentName, {
      height: '100vh',
      width: 'auto',
      data: {},
      disableClose: true,
      position: {
        right: '0',
      },
    });
  }
  // getAppPendingPayments() {
  //   this.sundryservice.sundryCredit(
  //       {},
  //       this.currentUser.id,
  //       'RECEIVED',
  //       this.partyPageSize,
  //       this.currentPageNo + 1
  //     )
  //     .then((res) => {
  //       if (res.success) {
  //         this.pendingPartyList = res.data.pageData;
  //       }
  //     });
  // }

  // getAllCreditors() {
  //   this.sundryservice
  //     .sundryCredit(
  //       {},
  //       this.currentUser.id,
  //       'PAID',
  //       this.partyPageSize,
  //       this.currentPageNo + 1
  //     )
  //     .then((res) => {
  //       if (res.success) {
  //         this.creditorsPartyList = res.data.pageData;
  //       }
  //     });
  // }

  // getAllParties() {
  //   this.partyService
  //     .getAllPartiesDetails(
  //       {},
  //       this.currentUser.id,
  //       this.partyPageSize,
  //       this.currentPageNo + 1
  //     )
  //     .then(
  //       (res) => {
  //         // // console.log(res.data);
  //         // this.recentPartyList = res.data.pageData;
  //         // console.log(this.recentPartyList);
  //       },
  //       (err) => {
  //         if (err.error.status == 404) {
  //           this.recentPartyList = [];
  //         }
  //       }
  //     );
  // }

  // getRecentSale() {
  //   this.dashboardService.getRecentSale({}, this.currentUser.id).then(
  //     (res: any) => {
  //       if(res.success){
  //         this.recentPartyList = res.data;
  //       }
  //     },
  //     (err) => {
  //       if (err.error.status == 404) {
  //         // this.newPartiesList = [];
  //       }
  //     }
  //   );
  // }

  // exportData(format: string) {
  //   if (
  //     (this.todaysProductSold === 0 &&
  //       this.todaysProductSoldPercent === 0 &&
  //       this.newPartiesJoinedCount === 0 &&
  //       this.newPartiesPercent === 0) ||
  //     ((this.todaySales === undefined ||
  //       this.todaySales === null ||
  //       this.todaySales === 0) &&
  //       (this.percentageCount === undefined ||
  //         this.percentageCount === 0 ||
  //         this.percentageCount === null))
  //   ) {
  //     // this.toastService.openErrorSnackBar('No data available for export');
  //     this.toastService.toastMsg({
  //       title: 'Error',
  //       content: 'No Data Available For Export',
  //     });
  //     return;
  //   }
  //   if (format === 'csv') {
  //     this.exportAsCSV();
  //   } else if (format === 'excel') {
  //     this.exportAsExcel();
  //   } else if (format === 'pdf') {
  //     this.exportAsPDF();
  //   }
  // }

  // exportAsCSV() {
  //   const csvContent = this.generateCSVContent(); // Function to generate CSV content
  //   const blob = new Blob([csvContent], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'exported_data.csv';
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // }

  // generateCSVContent() {
  //   let csvContent = '';

  //   // Column headers
  //   const columnHeader = ['Metric', 'Value', 'Percentage Change'];
  //   csvContent += columnHeader.join(',') + '\n';

  //   // Data rows
  //   const dataRows = [
  //     ['Total Sales', this.todaySales, this.percentageCount],
  //     ['Product Sold', this.todaysProductSold, this.todaysProductSoldPercent],
  //     ['New Customers', this.newPartiesJoinedCount, this.newPartiesPercent],
  //   ];

  //   // Combine headers and data, skipping the first row
  //   dataRows.forEach((row) => {
  //     csvContent += row.join(',') + '\n';
  //   });

  //   return csvContent;
  // }
  // exportAsExcel() {
  //   const excelData = this.generateExcelData(); // Function to generate Excel data

  //   // Create a worksheet using json_to_sheet
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData, {
  //     skipHeader: true,
  //   });

  //   // Create a workbook
  //   const workbook: XLSX.WorkBook = {
  //     Sheets: { data: worksheet },
  //     SheetNames: ['data'],
  //   };

  //   // Convert the workbook to an array buffer
  //   const excelBuffer: any = XLSX.write(workbook, {
  //     bookType: 'xlsx',
  //     type: 'array',
  //   });

  //   // Save the Excel file
  //   this.saveAsExcelFile(excelBuffer, 'exported_data.xlsx');
  // }
  // generateExcelData() {
  //   const excelData = [];

  //   // Column headers
  //   const columnHeader = ['Metric', 'Value', 'Percentage Change'];
  //   excelData.push(columnHeader);

  //   // Data rows
  //   const dataRows = [
  //     ['Total Sales', this.todaySales, this.percentageCount],
  //     ['Product Sold', this.todaysProductSold, this.todaysProductSoldPercent],
  //     ['New Customers', this.newPartiesJoinedCount, this.newPartiesPercent],
  //   ];

  //   excelData.push(...dataRows);
  //   // dataRows.forEach(row => {
  //   //   excelData.push(row);
  //   // });

  //   return excelData;
  // }

  // exportAsPDF() {
  //   const pdfContent = this.generatePDFContent();
  //   pdfMake.createPdf(pdfContent).download('exported_data.pdf');
  // }

  // generatePDFContent() {
  //   pdfMake.vfs = pdfFonts.pdfMake.vfs;

  //   const content = [];

  //   // Column headers
  //   const columnHeader = [
  //     { text: 'Metric', style: 'tableHeader', bold: true },
  //     { text: 'Value', style: 'tableHeader', bold: true },
  //     { text: 'Percentage Change', style: 'tableHeader', bold: true },
  //   ];
  //   content.push(columnHeader);

  //   // Data rows
  //   const dataRows = [
  //     ['Total Sales', this.todaySales, this.percentageCount],
  //     ['Product Sold', this.todaysProductSold, this.todaysProductSoldPercent],
  //     ['New Customers', this.newPartiesJoinedCount, this.newPartiesPercent],
  //   ];

  //   const tableBody = dataRows.map((row) => {
  //     return row.map((cell) => {
  //       return { text: cell, style: 'tableCell' };
  //     });
  //   });

  //   content.push(...tableBody);

  //   return {
  //     content: [
  //       {
  //         table: {
  //           widths: ['*', '*', '*'],
  //           body: content,
  //         },
  //         layout: 'lightHorizontalLines',
  //       },
  //     ],
  //     styles: {
  //       tableHeader: {
  //         bold: true,
  //         fontSize: 12,
  //         fillColor: '#CCCCCC',
  //         margin: [0, 5, 0, 5],
  //       },
  //       tableCell: {
  //         fontSize: 10,
  //         margin: [0, 5, 0, 5],
  //       },
  //     },
  //   };
  // }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
  //   const url = window.URL.createObjectURL(data);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = fileName;
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // }

}
