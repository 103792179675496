<div class="modal-header pt-0">
  <h1 style="color: #eb8b3f; font-weight: bolder; margin-left: 5px" class="modal-title mb-0">
    Add Product
  </h1>

  <div class="addProductButtons">
    <button style="margin-right: 10px" type="button" class="btn btn-cancel" (click)="clearInput()">
      Cancel
    </button>
    <button type="submit" class="btn btn-save" (click)="addGoods()"
      *appHasPermission="['create'];name: 'product'">
      Save
    </button>
  </div>
</div>

<div class="modal-body">
  <form [formGroup]="ProductForm">
    <div class="row mb-3">
      <!-- Item Name -->
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
        <label class="form-label">Item Name<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matNativeControl required matInput placeholder="Item Name" formControlName="itemName" maxlength="50"
            [ngClass]="{
              'is-invalid':
                f.itemName.invalid && f.itemName.touched && submitted
            }" autocomplete="off" />
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.itemName.touched) && f.itemName.errors?.required">
          Item Name is required.
        </mat-error>
      </div>

      <!-- item Code -->
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Item Code<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matNativeControl required matInput placeholder="Item Code" formControlName="itemCode" [ngClass]="{
              'is-invalid':
                f.itemCode.invalid && f.itemCode.touched && submitted
            }" minlength="4" maxlength="8" autocomplete="off" />
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="
            (submitted || f.itemCode.touched) && f.itemCode.errors?.pattern
          ">
          Item Code must be alphanumeric value, Special character not Allowed.
        </mat-error>
        <mat-error class="text-danger" *ngIf="(submitted || f.itemCode.touched) && f.itemCode.errors?.required">
          Item Code is required.
        </mat-error>
        <mat-error *ngIf="f.itemCode.errors?.minlength">
          Item Code must be at least 4 characters.
        </mat-error>
        <mat-error *ngIf="f.itemCode.errors?.maxlength">
          Item Code must be at least 8 characters.
        </mat-error>
      </div>
    </div>

    <div class="row mb-3">
      <!-- Generate Barcode -->
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
        <label class="form-label">Barcode/SKU<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matNativeControl required matInput placeholder="Barcode/SKU" type="text"
            formControlName="generateBarcode" [ngClass]="{
              'is-invalid':
                f.generateBarcode.invalid &&
                f.generateBarcode.touched &&
                submitted
            }" minlength="13" maxlength="13" (keypress)="numberOnly($event)" autocomplete="off" />
        </mat-form-field>
        <a style="cursor: pointer; color: blue; margin-left: 5px" (click)="autoGenerate()">Auto Generate</a>
        <mat-error class="text-danger" *ngIf="
            (submitted || f.generateBarcode.touched) &&
            f.generateBarcode.errors?.pattern
          ">
          Barcode/SKU is required.
        </mat-error>
        <mat-error class="text-danger" *ngIf="
            (submitted || f.generateBarcode.touched) &&
            f.generateBarcode.errors?.required
          ">
          Barcode/SKU is required.
        </mat-error>
        <mat-error *ngIf="f.generateBarcode.errors?.maxlength">
          Barcode/SKU must be 13 digit.
        </mat-error>
        <mat-error *ngIf="f.generateBarcode.errors?.minlength">
          Barcode/SKU must be 13 digit.
        </mat-error>
      </div>
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Brand</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select matNativeControl disableOptionCentering formControlName="brand" placeholder="Brand">
            <mat-option *ngFor="let item of ProductBrand" [value]="item.id" [matTooltip]="item.name">{{ item.name
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-3">
      <!-- Category -->
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Category<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select matNativeControl required disableOptionCentering formControlName="category" placeholder="Category"
            (selectionChange)="getCategoryType($event)">
            <mat-option *ngFor="let item of productCategory" [value]="item.id">{{ item?.categoryName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.category.touched) && f.category.errors?.required">
          Category is required.
        </mat-error>
      </div>

      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Sub Category</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select matNativeControl disableOptionCentering formControlName="subCategoryId"
            placeholder="Sub Category">
            <mat-option *ngFor="let item of activeSubCategoryList" [value]="item.id" [matTooltip]="item.name">{{
              item.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-3">
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Gender</label>
        <mat-select class="form-control" formControlName="gender" placeholder="Gender"
          (selectionChange)="onGenderChange($event)">
          <mat-option value=null>Select Gender</mat-option>
          <mat-option value="men">Men</mat-option>
          <mat-option value="women">Women</mat-option>
          <mat-option value="boy">Boys</mat-option>
          <mat-option value="girl">Girls</mat-option>
          <mat-option value="unisex">Unisex</mat-option>
        </mat-select>
      </div>

      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Section</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matNativeControl matInput placeholder="Section" formControlName="section" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-3">
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Color</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matNativeControl matInput placeholder="Color" formControlName="color" autocomplete="off" />
        </mat-form-field>
      </div>
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Age Group</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput placeholder="Age Group" formControlName="ageGroup" maxlength="3" ngxOnlyNumbers
            autocomplete="off" />
        </mat-form-field>
        <mat-error *ngIf="f.ageGroup.errors?.min && f.ageGroup.touched">Age should be at least {{
          f.ageGroup.errors.min.min }}.</mat-error>
        <mat-error *ngIf="f.ageGroup.errors?.max && f.ageGroup.touched">Age should not exceed {{
          f.ageGroup.errors.max.max }}.</mat-error>
      </div>
    </div>

    <div class="row mb-3" *appHasPermission="['read', 'modify']; name: 'warehouse'">
      <!-- User New Product -->
      <!-- <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Product Type<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl required disableOptionCentering formControlName="used"
                        placeholder="Product Type">
                        <mat-option value=false>New</mat-option>
                        <mat-option value=true>Used</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.used.touched)">
                    <mat-error *ngIf="f.used.errors?.required">
                        Product Type is required!
                    </mat-error>
                </mat-error>
            </div> -->
      <div *ngIf="currentUser.userType !== 'WAREHOUSE'" style="margin-bottom: -13px; font-size: 12px"
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Warehouse</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse"
            (selectionChange)="onWarehouseSelectionChange($event)">
            <mat-option value="clear">Clear Selection</mat-option>
            <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
              {{ warehouse.firstName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <label class="form-label">{{ lang.SalePriceInc }}<span class="error-asterisk">*</span></label>
      <mat-form-field class="example-full-width" appearance="outline">
        <input matNativeControl required matInput placeholder="{{ lang.SalePriceInc }}" formControlName="price"
          [ngClass]="{
            'is-invalid': f.price.invalid && f.price.touched && submitted
          }" (keypress)="numberOnly($event)" autocomplete="off" />
      </mat-form-field>
      <mat-error class="text-danger" *ngIf="(submitted || f.price.touched) && f.price.errors?.required">
        <!-- <mat-error *ngIf="f.price.errors?.required"> -->
        {{ lang.SalePriceInc }} is required.
        <!-- </mat-error> -->
      </mat-error>
    </div>

    <!-- Gst Rate -->
    <div style="margin-bottom: -13px; margin-top: 10px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-2">
      <label class="form-label">{{ lang.GSTRate }}<span class="error-asterisk">*</span></label>
      <mat-form-field class="example-full-width" appearance="outline">
        <input matNativeControl value="5" required matInput placeholder="{{ lang.GSTRate }}" type="number"
          formControlName="gstRate" [ngClass]="{
            'is-invalid': f.gstRate.invalid && f.gstRate.touched && submitted
          }" (keypress)="numberDecimalOnly($event)" autocomplete="off" />
      </mat-form-field>
      <mat-error class="text-danger" *ngIf="submitted || f.gstRate.touched">
        <mat-error *ngIf="f.gstRate.errors?.required">
          {{ lang.GSTRate }} is required.
        </mat-error>
        <mat-error *ngIf="f.gstRate.errors?.pattern">
          {{ lang.GSTRate }} Only number allowed.
        </mat-error>
      </mat-error>
    </div>
    <div class="row mb-2">
      <!-- Item Description -->
      <div class="col-sm-12 col-md-12 col-lg-12">
        <label class="form-label">Item Description</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput rows="3" placeholder="Item Description" formControlName="description"
            autocomplete="off" maxlength="500"></textarea>
        </mat-form-field>
      </div>
    </div>

    <!-- Additional Cess -->
    <div class="row mb-3">
      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
        <label class="form-label">Support Type</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select formControlName="supportType" matNativeControl disableOptionCentering
            placeholder="Select Support Type">
            <mat-option value="GUARANTEE">Guarantee</mat-option>
            <mat-option value="WARRANTY">Warranty</mat-option>
            <mat-option value="NONE">None </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6" *ngIf="
          this.ProductForm.get('supportType').value === 'GUARANTEE' ||
          this.ProductForm.get('supportType').value === 'WARRANTY'
        ">
        <!-- <label class="form-label">Duration</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input formControlName="supportDuration" matNativeControl matInput
            style="width: 50%; border-right: 1px solid gray" placeholder="Duration" min="0" autocomplete="off "
            type="number" appearance="outline" />
          <span class="m-2 p-0">
            <mat-select (selectionChange)="selectWarrantyType($event)" matNativeControl disableOptionCentering [formControl]="warrantyTypeControl"
               style="
                width: 50%;
                float: right;
                position: absolute;
                margin-right: 10px;
              ">
              <mat-option value="Days">Days</mat-option>
              <mat-option value="Months">Months</mat-option>
              <mat-option value="Year">Year </mat-option>
            </mat-select>
          </span>
        </mat-form-field> -->
        <label class="form-label">Duration</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <div class="duration-container">
            <input formControlName="supportDuration" matNativeControl matInput placeholder="Duration" min="0"
              autocomplete="off" type="number" class="duration-input" />
            <mat-select (selectionChange)="selectWarrantyType($event)" disableOptionCentering
              [formControl]="warrantyTypeControl" class="duration-select">
              <mat-option value="Days">Days</mat-option>
              <mat-option value="Months">Months</mat-option>
              <mat-option value="Year">Year</mat-option>
            </mat-select>
          </div>
        </mat-form-field>

      </div>
    </div>

    <div class="row enableToggles mt-4 mb-3" style="padding-left: 50px">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch flex d-flex flex-row"
        style="justify-content: flex-start; padding-left: 0px">
        <input class="form-check-input" formControlName="secondaryUnitFlag" type="checkbox" id="flexSwitchCheckDefault"
          (click)="showSecondaryUnitField($event.target.checked)" style="float: inherit; margin-right: 10px" />
        <label class="form-label" style="font-size: 14px">Enable Secondary Unit</label>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch flex d-flex flex-row"
        style="justify-content: flex-start; padding-left: 27px">
        <input class="form-check-input mr-1 lowStckWarning" type="checkbox" formControlName="lowStockWarning"
          id="flexSwitchCheckDefault" (click)="getEnableStockValue($event.target.checked)"
          style="float: inherit; margin-right: 10px" />
        <label class="form-label" style="font-size: 14px">Enable Low Stock Warning
        </label>
      </div>
    </div>

    <div class="row mb-3">
      <!-- Primary Unit -->
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
        <label class="form-label">Primary Unit<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
          <mat-select placeholder="Primary Unit" formControlName="unit" matNativeControl disableOptionCentering
            (selectionChange)="getPrimaryUnit($event)" [ngClass]="{
              'is-invalid': f.unit.invalid && f.unit.touched && submitted
            }" required>
            <mat-option *ngFor="let unit of Units" [value]="unit">
              {{ unit.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.unit.touched) && f.unit.errors?.required">
          Primary Unit is required.
        </mat-error>
      </div>

      <!-- Secondary Unit -->
      <div *ngIf="isDisabled" style="margin-bottom: -13px; font-size: 12px"
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Secondary Unit<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
          <mat-select placeholder="Secondary Unit" formControlName="secondaryUnit" matNativeControl
            disableOptionCentering (selectionChange)="getSecondaryUnit($event)">
            <mat-option *ngFor="let secondaryunit of SecondaryUnit" [value]="secondaryunit">
              {{ secondaryunit }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger"
          *ngIf="(submitted || f.secondaryUnit.touched) && f.secondaryUnit.errors?.required">
          Secondary Unit is required.
        </mat-error>
      </div>

      <!-- Low Stock Units -->
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1" *ngIf="this.isStockToggle">
        <label class="form-label">Low Stock Units<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matNativeControl matInput placeholder="Low Stock Units" formControlName="lowStockUnit"
            (keypress)="numberOnly($event)" autocomplete="off" />
        </mat-form-field>
        <mat-error class="text-danger"
          *ngIf="(submitted || f.lowStockUnit.touched) && f.lowStockUnit.errors?.required">Low Stock Unit is
          required.</mat-error>
      </div>

      <!-- Conversion Rate -->
      <div *ngIf="isDisabled" style="font-size: 12px" class="col-xs-12 conversionWidth col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Conversion Rate<span class="error-asterisk">*</span></label>
        <div class="input-group my-2" style="height: 38px !important">
          <span class="input-group-text ms-1" style="
              font-size: 14px !important;
              padding-bottom: 3.75px !important;
            ">1 {{ primaryUnitShort }} =</span>
          <input class="form-control" min="0" formControlName="conversionRate" (keypress)="numberOnly($event)"
            autocomplete="off" style="font-size: 14px !important" />
          <span class="input-group-text me-2" style="
              font-size: 14px !important;
              padding-bottom: 3.75px !important;
            ">{{ secondarySelectUnit }}</span>
        </div>
        <mat-error class="text-danger"
          *ngIf="(submitted || f.conversionRate.touched) && f.conversionRate.errors?.required">Conversion Rate is
          required.</mat-error>
      </div>
    </div>

    <div class="row mb-3">
      <!-- Opening Stock -->
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
        <label class="form-label">Opening Stock<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="number" min="0" matNativeControl required matInput placeholder="Openeing Stock"
            formControlName="openingStock" (keypress)="numberOnly($event)" autocomplete="off" />
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.openingStock.touched) && f.openingStock.errors?.required">
          <!-- <mat-error *ngIf="f.openingStock.errors?.required"> -->
          Opening Stock is required.
          <!-- </mat-error> -->
        </mat-error>
      </div>
      <!-- As on Date -->
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">As on Date<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput ngModel bsDatepicker [(ngModel)]="todaysDate" [maxDate]="today"
            style="text-transform: uppercase !important" id="datePicker" bsDatepicker [bsConfig]="{
              containerClass: 'theme-dark-blue',
              dateInputFormat: 'DD MMM YYYY',
              showWeekNumbers: false
            }" formControlName="asOfDate" (click)="openDatepicker()" />
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-3" *ngIf="catId === 2 || catId === 4 || catId === 8 || catId === 9 || catId === 10 || catId === 11 || catId === 14 || catId === 15">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Expiry Date</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput ngModel bsDatepicker [(ngModel)]="todaysDate2" [minDate]="todaysDate2"
            style="text-transform: uppercase !important" id="datePicker" bsDatepicker [bsConfig]="{
              containerClass: 'theme-dark-blue',
              dateInputFormat: 'DD MMM YYYY',
              showWeekNumbers: false
            }" formControlName="expiry" (click)="openDatepickerexpiry()" />
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
      </div>
    </div>

    <div class="row mt-3" *ngIf="customFieldsList && customFieldsList.length > 0">
      <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
        <p class="d-md-inline-block fs--1 ml-5" style="font-weight: 500; font-size: 20px !important; margin-left: 5px">
          Custom Fields
        </p>
      </div>
    </div>

    <!-- Dynamic custom fields -->
    <!-- <div class="row mt-3" *ngIf="customFieldsList && customFieldsList.length > 0">
            <ng-container *ngFor="let field of customFieldsList">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                    <label class="form-label">{{ field.fieldName }}</label>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <input matInput [formControlName]="field.fieldName" type="text" placeholder="{{field.fieldName}}">
                    </mat-form-field>
                </div>
            </ng-container>
        </div> -->

    <div class="row mt-3" *ngIf="customFieldsList && customFieldsList.length > 0">
      <ng-container *ngFor="let field of customFieldsList">
        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
          <label class="form-label">{{ field.fieldName }}</label>
          <mat-form-field appearance="outline" class="example-full-width">
            <ng-container *ngIf="field.type === 'TEXT'; else dropdownField">
              <input matInput [formControlName]="field.fieldName" type="text" placeholder="{{ field.fieldName }}" />
            </ng-container>
            <ng-template #dropdownField>
              <mat-select [formGroup]="ProductForm.get(field.fieldName)" placeholder="{{ field.fieldName }}">
                <mat-option *ngFor="let option of getDropdownOptions(field)" [value]="option?.optionId"
                  (click)="onOptionClick(field, option)">
                  {{ option?.optionName }}
                </mat-option>
              </mat-select>
            </ng-template>
          </mat-form-field>
        </div>
      </ng-container>
    </div>


    <div class="row mt-3">
      <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
        <p class="d-md-inline-block fs--1 ml-5" style="font-weight: 500; font-size: 20px !important; margin-left: 5px">
          Add Product Image
        </p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row mb-sm-1" style="justify-content: center">
        <button *ngIf="imageSrcSeven" class="delete-button" type="button"
          style="position: relative; top: -16px; left: 205px" (click)="cancelCoverImage()">
          <i class="bi bi-x bi-sm" style="color: red"></i>
        </button>
        <label class="uploader2" style="width: 200px !important; height: 200px !important" ondragover="return false;"
          [class.loaded]="loaded" [style.outlineColor]="dragging ? activeColor : baseColor"
          (dragenter)="handleCoverDragEnter()" (dragleave)="handleCoverDragLeave()" (drop)="handleCoverDrop($event)">
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
            ">
            <i class="bi bi-plus-lg fs-3" *ngIf="!imageSrcSeven" [style.color]="
                dragging
                  ? imageSrcSeven.length > 0
                    ? overlayColor
                    : activeColor
                  : imageSrcSeven.length > 0
                  ? overlayColor
                  : baseColor
              ">
            </i>
            <span *ngIf="!imageSrcSeven" class="mt-2" style="font-size: 12px; font-weight: 500; line-height: 21">Add
              Product Cover Image</span>
            <span *ngIf="!imageSrcSeven" class="mt-2 text-muted"
              style="text-align: center; font-size: 12px; line-height: 21">Drop an Image or
              <span style="color: #1bb2f2">Browsing images</span> it from your
              computer</span>
          </div>
          <img [src]="imageSrcSeven" width="200" height="200" (load)="handleCoverImageLoad()"
            [class.loaded]="imageLoaded" />
          <input type="file" name="file" accept="image/*" (change)="coverImgChange($event)" />
        </label>
      </div>

      <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center">
        <label class="uploader2" style="width: 200px !important; height: 200px !important">
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            ">
            <i class="bi bi-plus-lg fs-3"></i>
            <span class="mt-2" style="font-size: 12px; font-weight: 500; line-height: 21">Add Product Image</span>
            <span class="mt-2 text-muted" style="text-align: center; font-size: 12px; line-height: 21">Drop an Image or
              <span style="color: #1bb2f2">Browsing images</span> it from your
              computer</span>
          </div>
          <input type="file" name="file" accept="image/*" style="display: none" [(ngModel)]="currentInput"
            [ngModelOptions]="{ standalone: true }" multiple (change)="onFileSelected($event)" />
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row"
        *ngFor="let preview of previews; let i = index" style="justify-content: center">
        <button class="delete-button" type="button" (click)="cancelmultiImage(i)">
          <i class="bi bi-x bi-sm" style="color: red"></i>
        </button>
        <img [src]="preview" class="preview" height="200" width="200px" />
      </div>
    </div>
  </form>
</div>