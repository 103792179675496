<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
            Item Wise Profit and Loss
        </div>
        <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
          <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>
    
          <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
          </button>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1">
        <div class="row mb-2">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "dateRangePrintHide">
                <label class="form-label">Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                        [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
                </mat-form-field>
            </div>
        </div>
        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-3"  id="excel-table">
                <thead class="" style="background-color: #F0F0F0;">
                    <tr class="text-900">
                        <th class="text-nowrap text-center">#</th>
                        <th class="text-nowrap text-center" scope="col">Item Name</th>
                        <th class="text-nowrap text-center" scope="col">Sales</th>
                        <th class="text-nowrap text-center" scope="col">Cr. Note / Sales Return</th>
                        <th class="text-nowrap text-center" scope="col">Purchase</th>
                        <th class="text-nowrap text-center" scope="col">Dr. Note / Purchase Return</th>
                        <th class="text-nowrap text-center" scope="col">Opening Stock</th>   
                        <th class="text-nowrap text-center" scope="col">Tax Receivable</th>
                        <th class="text-nowrap text-center" scope="col">Tax Payable</th>
                        <th class="text-nowrap text-center" scope="col">Net Profit/Loss</th>
                    </tr>
                </thead>
                <tbody class="list">    
                    <tr *ngFor="let item of itemProfitLossList ;let i = index">
                        <td class="text-center">{{i + 1}}</td>
                        <td class="text-center">{{item.itemName}}</td>
                        <td class="text-center">{{item.totalsale}}</td>
                        <td class="text-center">{{item.totalsalereturn}}</td>
                        <td class="text-center">{{item.totalpurchase}}</td>
                        <td class="text-center">{{item.totalpurchasereturn}}</td>
                        <td class="text-center">{{item.openingStock}}</td>             
                        <td class="text-center">AED{{item.totalsalesGst | currency:'1.2-2' }}</td>
                        <td class="text-center">{{item.totalpurchasereturn}}</td>
                        <td class="text-center">AED{{item.profitLoss | currency:'1.2-2'}}</td>
                    </tr>
                </tbody>s
                <tbody *ngIf="itemProfitLossList == 0">
                    <tr class="text-center">
                        <td colspan="10">
                            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"  width="200" height="150">                        </td>
                    </tr>
                </tbody>

            </table>
        </div>     
</div>